import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
//relative path imports
import CommonSelect from '../../utils/CommonSelect';
import AssesmentFooter from '../../utils/AssesmentFooter';
import { CommonSquareChipsWrapper } from './AssessmentStep4';
import { categoryList } from '../../product_engine/CreateProduct';
import { StyledButton } from '../../utils/LCAStep';
import CommonInput from '../../utils/CommonInput';
import { lca_weight_field_validation_regex } from '../../../data';

const weightUnit = ['g', 'kg', 'oz'];

const AssessmentStep1 = ({ handleSubmit, productInfo }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const { id } = useParams();
  const [composition, setComposition] = useState('');
  const [compositionPercentage, setCompositionPercentage] = useState();

  const [formData, setFormData] = useState({
    type: '',
    code: '',
    title: '',
    composition: [],
    weight: { value: '', unit: weightUnit[1] },
  });


  useEffect(() => {
    if (productInfo) {
      const compositionData = productInfo?.composition?.[currentLanguage || 'en' || 'it'] || productInfo?.composition || [];
      setFormData({
        type: productInfo?.category || productInfo?.type || '',
        title: productInfo?.title?.value || productInfo?.title?.fr || productInfo?.title || productInfo?.title?.it || '',
        composition:
          compositionData?.length > 0
            ? compositionData?.map((item) => ({
                ...item,
                label: `${item.name}-${item.percentage} %`,
              }))
            : [],
        weight: {
          value: String(productInfo?.weight?.value) || '',
          unit: productInfo?.weight?.unit || weightUnit[0],
        },
        code: productInfo?.qrId || productInfo?.code ||  '',
      });
    }
  }, [currentLanguage, productInfo]);

  const onNext = (isDraft = false) => {
    if (formData.type) {
      const data = formData.composition.map((item) => ({ name: item.name, percentage: item.percentage }));
      handleSubmit({ garment: { ...formData, composition: data } }, isDraft);
    } else {
      setSnackBar({ open: true, message: 'Please select required field.' });
    }
  };

  const deleteProductComposition = (index) => {
    if (index >= 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        composition: prevFormData.composition.filter((_, idx) => idx !== index),
      }));
    }
  };

  const addProductCompostition = () => {
    if (composition && compositionPercentage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        composition: [...prevFormData.composition, { label: `${composition}-${compositionPercentage} %`, name: composition, percentage: compositionPercentage }],
      }));
      setComposition('');
      setCompositionPercentage('');
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const isDisabled = !formData.type || !formData.code || !formData.title || formData.composition?.length === 0 || !formData.weight.value;

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <Box my={3}>
            <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
              {t('garment')}
            </Typography>

            <CommonSelect
              label={
                <span style={{ cursor: 'pointer' }}>
                  {t('type_of_garment')} <span style={{ color: 'red' }}>*</span>
                  <Tooltip title={'Please indicate the type of garment that you want to analyze'} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </span>
              }
              options={categoryList}
              value={formData.type}
              handleChange={(e) => setFormData({ ...formData, type: e.target.value })}
            />
            {/* {CommonInfoWrapper(`Please indicate the type of garment that you want to analyze`)} */}

            <CommonInput
              label={
                <span>
                  {t('garment_code')}
                  <span style={{ color: 'red' }}>*</span>
                  <Tooltip title={'Please indicate a SKU to indentify the garment you want to analyze. This name will be reproted in the results'} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </span>
              }
              value={formData?.code}
              handleChange={(e) => {
                const value = e.target.value;
                if (value.length <= 25) setFormData({ ...formData, code: value });
              }}
              type="text"
              isSpacing
              variant="standard"
              autoComplete={false}
              disabled={true}
            />
            {/* {CommonInfoWrapper(`Please indicate a SKU to indentify the garment you want to analyze. This name will be reproted in the results`)} */}

            <CommonInput
              label={
                <span>
                  {t('garment_title')}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              }
              value={formData.title}
              handleChange={(e) => {
                const value = e.target.value;
                if (value.length <= 25) {
                  setFormData({ ...formData, title: value });
                }
              }}
              type="text"
              isSpacing
              variant="standard"
              autoComplete={false}
            />

            <Typography sx={{ marginTop: '20px', color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>
              {t('product_composition')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            {CommonSquareChipsWrapper(true, formData.composition, deleteProductComposition)}

            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box mt={4} minWidth="44%">
                <CommonInput
                  label={<span>{t('composition')}</span>}
                  value={composition}
                  handleChange={(e) => setComposition(e.target.value)}
                  type="text"
                  isSpacing
                  variant="standard"
                  autoComplete={false}
                />
              </Box>

              <Box mt={4} minWidth="44%">
                <CommonInput
                  label={<span>{t('percentage')}</span>}
                  value={compositionPercentage}
                  handleChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[+-]/g, '');
                    const regex = /^(?!0\.00$)(?!0\.0+$)([1-9][0-9]{0,2}|0)(\.[0-9])?$/;
                    if (value === '0.00' || value === '0.0000') {
                      setCompositionPercentage('');
                    } else if (regex.test(value) || value === '') {
                      const floatValue = parseFloat(value);
                      if (value === '' || (!isNaN(floatValue) && floatValue <= 100)) {
                        setCompositionPercentage(floatValue);
                      }
                    }
                  }}
                  type="number"
                  isSpacing
                  variant="standard"
                  autoComplete={false}
                />
              </Box>

              <Box mt={4} display="flex" alignItems="center" justifyContent="end" onClick={() => addProductCompostition()}>
                <StyledButton variant="contained" isBgColor="#0E1090">
                  {t('ADD')}
                </StyledButton>
              </Box>
            </Box>

            <Grid container>
              <Grid item xs={10}>
                <CommonInput
                  label={
                    <span>
                      {t('weight')}
                      <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'What is the weight of the garment?'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('enter_detail_here')}
                  value={formData.weight.value}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  // handleChange={(e) => {
                  //   let value = e.target.value;
                  //   value = value.replace(/[+-]/g, '');
                  //   const regex = /^(?!0\.00$)([1-9][0-9]{0,4}|0)(\.[0-9]{0,4})?$/;
                  //   if (regex.test(value) || value === '') {
                  //     const floatValue = parseFloat(value);
                  //     if (value === '' || (!isNaN(floatValue) && floatValue <= 999.99)) {
                  //       setFormData({ ...formData, weight: { ...formData.weight, value: e.target.value } });
                  //     }
                  //   }
                  // }}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || lca_weight_field_validation_regex.test(value)) {
                      // if (value !== '0') {
                        setFormData({ ...formData, weight: { ...formData.weight, value: String(e.target.value) } });
                      // }
                    }
                  }}
                />
                {/* {CommonInfoWrapper(`What is the weight of the garment?`)} */}
              </Grid>
              <Grid item xs={2}>
                <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    fullWidth
                    sx={{ border: 'none' }}
                    size="small"
                    value={formData.weight.unit}
                    onChange={(e) => setFormData({ ...formData, weight: { ...formData.weight, unit: e.target.value } })}
                    autoWidth
                  >
                    {weightUnit.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>

      <AssesmentFooter isDraftDisabled={isDisabled} isNextBtnDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentStep1;
