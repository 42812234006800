import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { CardMedia, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ViewDocument({ viewDoc, handleCloseDocument, docUrl }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Dialog open={viewDoc} onClose={handleCloseDocument}>
        <DialogTitle sx={{ color: '#000', fontWeight: 'bold' }}>{t('document')}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDocument}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <CardMedia component="img" image={docUrl} alt="Document" />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
