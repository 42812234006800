import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { CircularProgress, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Pagination from '@mui/material/Pagination';
//import icons
import { IoMdArrowBack } from 'react-icons/io';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DEFAULT_IMAGE from '../../images/default_product_image.png';
//relative path imports
import PATH from '../utils/path';
import CommonInput from '../utils/CommonInput';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import FilterProduct from '../product_engine/FilterProduct';

//create mui styled components
const StyledBackButton = styled(Button)(({ theme }) => ({
  bgcolor: 'none',
  color: '#000',
  textTransform: 'capitalize',
  px: theme.spacing(2),
  '&:hover': {
    bgcolor: 'none',
  },
}));

const StyledProductCard = styled(Paper)(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  cursor: 'pointer',
  outline: isSelected && `1px solid #0E1090`,
  minHeight: '100%',
}));

const StyledNextBtn = styled(Button)(({}) => ({
  backgroundColor: '#0E1090',
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: '#0E1090' },
}));

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  fontWeight: '700',
}));

const StartCalculation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [assessmentName, setAssessmentName] = useState('');
  const [filters, setFilters] = useState({
    hasCollection: '',
    isArchived: '',
    isQrId: '',
    query: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [isSelected, setIsSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [productCount, setProductCount] = useState();
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [assessmentNameError, setAssessmentNameError] = useState('');

  useEffect(() => {
    fetchProducts(currentPage, filters);
  }, [filters, currentPage]);

  const fetchProducts = async (pageNo, filterParams) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const queryParams = [];
      queryParams.push(`page=${pageNo}`);
      queryParams.push(`limit=10`);
      queryParams.push(`lca=false`);

      if (filterParams.hasCollection) queryParams.push(`collection=${filterParams.hasCollection}`);
      if (filterParams.isArchived) queryParams.push(`isArchived=${filterParams.isArchived === 'Yes'}`);
      if (filterParams.isQrId) queryParams.push(`qr=${filterParams.isQrId === 'Yes'}`);
      if (filterParams.query) queryParams.push(`query=${filterParams.query}`);

      const queryString = queryParams.join('&');
      const url = `${RestUrlsConstants.product}?${queryString}`;

      const response = await httpService.get(url, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });

      if (response?.data?.status) {
        setProducts(response?.data?.data?.products || []);
        setProductCount(response?.data?.data?.totalRecords || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleFilterProductCollection = (supplyChain, hasCollection, isArchived, isQrId) => {
    handleFilterChange('hasCollection', hasCollection);
    handleFilterChange('isArchived', isArchived);
    handleFilterChange('isQrId', isQrId);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setFilters((prev) => ({ ...prev, query: e.target.value }));
  };

  const createLCA = async () => {
    try {
      const response = await httpService.post(
        RestUrlsConstants.LCA,
        {
          name: assessmentName.trim(),
          productId: isSelected,
        },
        {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response?.data?.status) {
        localStorage.setItem('createLCAInfo', JSON.stringify(response?.data?.data));
        localStorage.removeItem('editDraftLcaInfo');
        navigate(`${PATH.STARTASSESSMENT}/${isSelected}`);
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const totalPages = Math.ceil(productCount / 10);

  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  return (
    <Box mx={2}>
      <StyledBackButton startIcon={<IoMdArrowBack />} onClick={() => navigate(`${PATH.LIFECYCLEASSESSMENT}`)}>
        {t('back')}
      </StyledBackButton>
      <Box mt={1}>
        <CommonInput
          label={
            <Typography fontWeight={'700'} pb={1}>
              {t('assessment_name_label')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          }
          placeholder={t('assessment_name_placeholder')}
          value={assessmentName}
          type="text"
          variant="standard"
          autoComplete={false}
          handleChange={(e) => {
            const value = e.target.value;
            const isValid = /^[a-zA-Z\s]{1,250}$/.test(value);
            if (isValid || value === '') {
              setAssessmentName(value);
              setAssessmentNameError('');
            } else {
              setAssessmentNameError('Assessment name must contain only alphabets and be less than 250 characters.');
            }
          }}
        />
        {assessmentNameError && (
          <Typography color="error" variant="caption">
            {assessmentNameError}
          </Typography>
        )}
      </Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <CardContent>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'}>
              <Typography fontWeight={'700'}>{t('select_products')} </Typography>
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '20px' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder={t('search')}
                  value={searchValue}
                  sx={{
                    borderRadius: '20px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                  }}
                  onChange={handleSearchChange}
                />
                <FilterProduct
                  currentPage={currentPage}
                  fetchProducts={fetchProducts}
                  handleFilterProductCollection={handleFilterProductCollection}
                  clearFilter={() =>
                    setFilters((prev) => ({
                      ...prev,
                      hasCollection: '',
                      isArchived: '',
                      isQrId: '',
                    }))
                  }
                  filters={filters}
                  searchFilter={searchValue}
                  isSupplyChain={false}
                  isLcaRequired={false}
                  isLcaFilter={true}
                />
              </Box>
            </Stack>

            <Box mt={4}>
              {loading ? (
                <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container columnSpacing={9} rowSpacing={4}>
                  {products && products.length > 0 ? (
                    products.map((ele, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
                        <StyledProductCard isSelected={isSelected === ele?.id} onClick={() => setIsSelected(ele?.id)}>
                          <img src={ele?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={140} height={140} style={{ objectFit: 'cover' }} />
                          {ele?.title && (
                            <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Typography mt={1} fontWeight={'700'}>
                                {ele?.title?.value}
                              </Typography>
                            </Box>
                          )}
                          <Stack mt={1}>
                            {ele?.weight?.value && (
                              <StyledProductDescText>
                                {t('weight')}&nbsp;:&nbsp;{ele?.weight?.value}&nbsp;{ele?.weight?.unit}
                              </StyledProductDescText>
                            )}
                            {ele?.category && (
                              <StyledProductDescText>
                                {t('category')}&nbsp;:&nbsp;{ele?.category}
                              </StyledProductDescText>
                            )}
                            {ele?.composition?.en?.[0]?.percentage && (
                              <StyledProductDescText>
                                {t('composition')}&nbsp;:&nbsp;{ele?.composition?.en[0]?.percentage}
                              </StyledProductDescText>
                            )}
                            {ele?.qrId && (
                              <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <StyledProductDescText>
                                  {t('sku')}&nbsp;:&nbsp;{ele?.qrId}
                                </StyledProductDescText>
                              </Box>
                            )}
                          </Stack>
                        </StyledProductCard>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="h6" textAlign="center">
                        No products found
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
          </CardContent>
          <Box my={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {productCount > 0 && (
              <Pagination
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: 'gray',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EC895A',
                    color: '#fff',
                  },
                }}
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Card>
      </Box>
      <Box mt={3}>
        <Card sx={{ px: isMobile ? 3 : 5, py: 4, borderRadius: '20px' }}>
          <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography fontWeight={'700'}>{t('select_product_and_continue')}</Typography>
              {isSelected && (
                <Typography color={'#808080'} fontSize={12} mt={1}>
                  {t('selected_product')}
                </Typography>
              )}
            </Box>
            <StyledNextBtn variant="contained" disabled={!assessmentName.trim() || isSelected === null} onClick={() => createLCA()}>
              {t('next')}
              <NavigateNextIcon />
            </StyledNextBtn>
          </Stack>
        </Card>
      </Box>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default StartCalculation;
