import { useState, useRef, useEffect } from 'react';
import useForgotPasswordHook from '../hooks/forgotPassword.hook';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import useCheckOnlineStatus from '../hooks/useOnlineCheckStatus';
import { Snackbar } from '@mui/material';

const ResetPassword = () => {
  const { showPassword, handleClickShowPassword, handleMouseDownPassword } = useForgotPasswordHook();
  const param = useParams();
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');
  const [message, setMessage] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  const newPassword = useRef(null);
  const confirmPassword = useRef(null);

  const resetUserPassword = async () => {
    if (!checkOnlineStatus()) return;
    try {
      const body = {
        password: newPassword.current.value,
      };
      const response = await httpService.put(RestUrlsConstants.userUrl + userID, body, { headers: { Authorization: param.token } });
      setMessage(response.data.message);
      if (response.status) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching form:', error);
    }
  };
  const handleResetPassword = () => {
    if (!checkOnlineStatus()) return;
    if (newPassword.current.value === confirmPassword.current.value) {
      resetUserPassword();
    } else {
      setSnackBar({ open: true, message: 'Passwords do not match' });
      console.error('Passwords do not match');
    }
  };
  const getUserID = async () => {
    try {
      const response = await httpService.get(RestUrlsConstants.authUrl, { headers: { Authorization: param.token } });
      setUserID(response?.data?.data?.id);
      setMessage(response.data.message);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (param.token) {
      getUserID();
    }
  }, [param.token]);

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <div className="w-100">
      <div className="m-top-6">
        <div className="w-100 center">
          <img src={require('../images/renoon_app_icon.png')} alt="panda" className="w-5 b-radius-20 m-50px"></img>
        </div>
        <div className="w-100 center">
          <div className="login-container">
            <p className="login-text">Set a new password</p>
            <form onSubmit={(e) => e.preventDefault()} className="p-hor-15">
              <FormControl variant="outlined" fullWidth margin="normal" required>
                <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                <OutlinedInput
                  inputRef={newPassword}
                  id="outlined-adornment-new-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                  inputProps={{ minLength: 8 }}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth margin="normal" required>
                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                <OutlinedInput
                  inputRef={confirmPassword}
                  id="outlined-adornment-confirm-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                  inputProps={{ minLength: 8 }}
                />
              </FormControl>

              <Button
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  background: '#101010',
                  fontWeight: '1000',
                  marginTop: '10px',
                }}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            </form>
            {/* <Snackbar
              open={snackBar.open}
              autoHideDuration={2000}
              message={snackBar.message}
              anchorOrigin={{
                vertical: snackBar.vertical,
                horizontal: snackBar.horizontal,
              }}
              onClose={handleClose}
              className="snackBarColor"
              key={snackBar.vertical + snackBar.horizontal}
            /> */}
            <Snackbar
              open={snackBar.open}
              autoHideDuration={2000}
              message={snackBar.message}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={snackbarClose}
              className="snackBarColor"
              key="snackbar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
