import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import icons
import { IoMdClose } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
//relative path imports
import useCheckOnlineStatus from '../../../hooks/useOnlineCheckStatus';
import { RestUrlsConstants } from '../../../constants/rest-urls.constants';
import httpService from '../../../service/http.service';
import AddResultCSVModal from './add_result_csv_modal';

const StyledButton = styled(Button)(({ theme, isBgColor, disabled }) => ({
  backgroundColor: disabled ? '#c0c0c0' : isBgColor || '#101010',
  padding: '12px 16px',
  borderRadius: theme.spacing(4),
  fontWeight: '700',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: disabled ? '#c0c0c0' : isBgColor || '#101010',
    boxShadow: 'none',
  },
}));

const LcaCSVUploadModal = ({ open, onClose, selectedLcaId }) => {
  const { t } = useTranslation();
  const [openAddResultModal, setOpenAddResultModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadFile(file);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = () => {
    setUploadFile(null);
  };

  const onClickUpdateResultBtn = async () => {
    if (uploadFile) {
      if (!checkOnlineStatus()) return;
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', uploadFile);
        const response = await httpService.patch(`${RestUrlsConstants.LCA}/${selectedLcaId}/upload`, formData, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data?.status) {
          setSnackBar({ open: true, message: response?.data?.message });
          setLoading(false);
          setTimeout(() => {
            setOpenAddResultModal(true);
          }, 700);
        }
      } catch (error) {
        setLoading(false);
        setSnackBar({ open: true, message: 'Failed to upload CSV.' });
      }
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: { p: 2, borderRadius: 2 },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={700} fontSize={24} id="add-results-dialog">
          {t('add_results')}
        </Typography>
        <IconButton onClick={onClose} sx={{ color: '#000' }}>
          <IoMdClose />
        </IconButton>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent="center" mt={2} spacing={2}>
        {!uploadFile ? (
          <>
            <Typography fontWeight={'700'}>{t('bulk_upload_results')}</Typography>
            <input type="file" accept=".xlsx" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            <StyledButton onClick={handleUploadClick} isBgColor="#000000" endIcon={<LuUpload />}>
              {t('upload_file')}
            </StyledButton>
          </>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontWeight={'700'}>{uploadFile.name}</Typography>
            <IconButton onClick={handleRemoveFile} sx={{ color: '#ff0000' }}>
              <IoMdClose />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Stack direction={'row'} alignItems={'center'} justifyContent="center" mt={2}>
        <StyledButton disabled={!uploadFile || loading} onClick={onClickUpdateResultBtn} isBgColor={loading ? '#d3d3d3' : '#0E1090'}>
          {loading ? <CircularProgress size={18} /> : t('update_result')}
        </StyledButton>
      </Stack>

      {openAddResultModal && <AddResultCSVModal selectedLcaId={selectedLcaId} open={openAddResultModal} onClose={() => setOpenAddResultModal(false)} />}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Dialog>
  );
};

export default LcaCSVUploadModal;
