import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
//import icons
import { LuArrowRightFromLine } from 'react-icons/lu';
import { FiUpload } from 'react-icons/fi';
import { LiaClipboardListSolid } from 'react-icons/lia';
import { FaEdit } from 'react-icons/fa';
//import images
import CO2_CUP from '../../images/co2-cup.png';
import BG_CO2 from '../../images/bg-co2.png';
//relative path imports
import PATH from '../utils/path';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import UploadCSVModal from './modal/upload_csv';
import { useSelector } from 'react-redux';

//relative static data
const group_button = [
  {
    btn_name: 'add_results',
    icon: <LuArrowRightFromLine />,
    bg_color: '#0E1090',
    color: '#ffffff',
    borderColor: '#0E1090',
    pathName: `${PATH.ADDRESULT}`,
  },
  {
    btn_name: 'start_calculation',
    icon: <LuArrowRightFromLine />,
    bg_color: '#0E1090',
    color: '#ffffff',
    borderColor: '#0E1090',
    pathName: `${PATH.STARTCALCULATION}`,
  },
  {
    btn_name: 'upload_file',
    icon: <FiUpload />,
    bg_color: '#101010',
    color: '#ffffff',
    borderColor: '#101010',
    pathName: '',
  },
  {
    btn_name: 'view_results',
    icon: <LiaClipboardListSolid />,
    bg_color: '#EFEFEF',
    color: '#000000',
    borderColor: '#000000',
    pathName: `${PATH.VIEWRESULT}`,
  },
];

const temp_key_matrix_data = [
  {
    id: 'totatProductsAssessed',
    matrix_number: '',
    matrix_heading: 'Number of products \n assessed',
    bg_color: '#51A2FF',
  },
  {
    id: 'totalSubmitted',
    matrix_number: '',
    matrix_heading: 'Waiting for \n results',
    bg_color: '#40DCBD',
  },
  {
    id: 'totalPending',
    matrix_number: '',
    matrix_heading: 'Draft Assessments',
    bg_color: '#101010',
  },
];

const elements_card = [
  {
    chip_name: 'Impact',
    kg_number: '6.6',
    unit: 'kg',
    title: 'Co2 Emitted',
  },
  {
    chip_name: 'Impact',
    kg_number: '6.6',
    unit: 'kg',
    title: 'Co2 Emitted',
  },
  {
    chip_name: 'Impact',
    kg_number: '6.6',
    unit: 'kg',
    title: 'Co2 Emitted',
  },
  {
    chip_name: 'Inventory',
    kg_number: '6.6',
    unit: 'kg',
    title: 'Co2 Emitted',
  },
  {
    chip_name: 'Inventory',
    kg_number: '6.6',
    unit: 'kg',
    title: 'Co2 Emitted',
  },
];

//create mui styled components
const StyledButton = styled(Button)(({ isBgColor, isColor, isBorderColor, theme }) => ({
  width: '100%',
  padding: '12px 16px',
  borderRadius: theme.spacing(1),
  textTransform: 'capitalize',
  backgroundColor: isBgColor || 'transparent',
  color: isColor,
  border: `1px solid ${isBorderColor}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: isBgColor,
    boxShadow: 'none',
  },
}));

const MatrixCard = styled(Paper)(({ isBgColor, theme }) => ({
  height: '160px',
  padding: '5px',
  width: '100%',
  backgroundColor: isBgColor,
  borderRadius: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

const EditDraftBtn = styled(Button)(({}) => ({
  color: '#101010',
  textTransform: 'capitalize',
  backgroundColor: '#ffffff',
  width: '100%',
  height: '40px',
  padding: '4px 16px',
  borderRadius: '100px',
  '&:hover': {
    backgroundColor: '#ffffff',
    boxShadow: 'none',
  },
}));

export const StyledList = styled(Paper)(({ isbgColor, theme }) => ({
  backgroundColor: isbgColor,
  marginTop: '20px',
  padding: '12px 18px',
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledText = styled(Typography)(({}) => ({
  fontSize: '12px',
  fontWeight: '700',
  color: '#ffffff',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
}));

const chip_indicator = {
  IMPACT: '#FF5722',
  INVENTORY: '#808080',
};

const AssessmentDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keyMatrixData, setKeyMatrixData] = useState(temp_key_matrix_data);
  const [overViewInfo, setOverViewInfo] = useState(null);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const user = useSelector((store) => store.user);

  const fetchInitData = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.LCAHOMEOVERVIEW}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response.data.data) {
        const updatedKeyMatrixData = temp_key_matrix_data.map((item) => {
          const value = response.data.data[item.id] ?? item.matrix_number;
          return {
            ...item,
            matrix_number: item.id === 'totatProductsAssessed' ? (Array.isArray(value) ? value.length : value) : value,
          };
        });

        setKeyMatrixData(updatedKeyMatrixData);
        setOverViewInfo(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  const ListView = ({ data }) => {
    return (
      <Box>
        <StyledList isbgColor={'#0E1090'}>
          <Box width={'35%'}>
            <StyledText>{t('lca_type')}</StyledText>
          </Box>
          <StyledText>{t('qty')}</StyledText>
          <StyledText>{t('unit')}</StyledText>
          <Box width={'15%'} display={'flex'} justifyContent={'center'}>
            <StyledText>{t('indicator')}</StyledText>
          </Box>
        </StyledList>
        <Box height={'300px'} overflow={'auto'}>
          {data?.map((ele, index) => {
            return (
              <Stack key={index}>
                <StyledList isbgColor={'#ffffff'}>
                  <Box width={'35%'} display={'flex'} gap={2} alignItems={'center'}>
                    <img src={BG_CO2} alt="co2" height={32} width={32} />
                    <Typography fontSize={12} textTransform={'uppercase'}>
                      {ele?.type}
                    </Typography>
                  </Box>
                  <Typography fontWeight={'700'}>{Number(ele?.quantity)?.toFixed(2)}</Typography>
                  <Typography fontSize={12} fontWeight={'700'}>
                    {ele?.unit}
                  </Typography>
                  <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                    <Chip label={ele?.indicator} size="small" sx={{ backgroundColor: chip_indicator[ele?.indicator], color: '#fff' }} />
                  </Box>
                </StyledList>
              </Stack>
            );
          })}
        </Box>
      </Box>
    );
  };

  const manageLCAButtons = (ele, cindex) => {
    const result = overViewInfo?.totatProductsAssessed + overViewInfo?.totalSubmitted + overViewInfo?.totalPending;

    if (user.userType === 'SYSTEM') {
      if (cindex === 0) {
        navigate(ele.pathName);
      } else if (cindex === 1) {
        if (result === overViewInfo?.maxLCAProducts) {
          setSnackBar({
            open: true,
            message: `Wow! You've reached the maximum number of products for LCA assessments! Your dedication to sustainability is truly inspiring. Contact us to get more products.`,
          });
        } else {
          navigate(ele.pathName);
        }
      } else if (cindex === 2) {
        setOpenCSVModal(true);
      } else if (cindex === 3) {
        navigate(ele.pathName);
      }
    } else {
      if (cindex === 0) {
        if (result === overViewInfo?.maxLCAProducts) {
          setSnackBar({
            open: true,
            message: `Wow! You've reached the maximum number of products for LCA assessments! Your dedication to sustainability is truly inspiring. Contact us to get more products.`,
          });
        } else {
          navigate(ele.pathName);
        }
      } else if (cindex === 1) {
        setOpenCSVModal(true);
      } else if (cindex === 2 || cindex === 3) {
        navigate(ele.pathName);
      }
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const onClickMatrixItem = (cindex) => {
    if (cindex === 0) {
      navigate(`${PATH.VIEWRESULT}?state=3`);
    } else if (cindex === 1) {
      navigate(`${PATH.VIEWRESULT}?state=2`);
    }
  };

  return (
    <Box mx={2}>
      <Grid container spacing={2}>
        {group_button
          .filter((button, index) => {
            if (button.btn_name === 'add_results' && user?.userType === 'SYSTEM') {
              return true;
            }
            return button.btn_name !== 'add_results';
          })
          .map((ele, index) => (
            <Grid item md={user?.userType === 'SYSTEM' ? 3 : 4} sm={6} xs={12} key={index}>
              <StyledButton onClick={() => manageLCAButtons(ele, index)} endIcon={ele.icon} variant="contained" isBgColor={ele.bg_color} isColor={ele.color} isBorderColor={ele.borderColor}>
                <Typography fontWeight="700">{t(ele.btn_name)}</Typography>
              </StyledButton>
            </Grid>
          ))}
      </Grid>
      <Box mt={5}>
        <Typography fontWeight={'700'}>{t('key_matrix')}</Typography>
        <Box mt={4}>
          <Grid container spacing={3}>
            {keyMatrixData?.map((ele, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <MatrixCard isBgColor={ele.bg_color}>
                    <Stack spacing={2}>
                      <Typography variant="h4" fontWeight={'700'} color={'#fff'}>
                        {ele.matrix_number}
                      </Typography>
                      <Typography
                        sx={{ whiteSpace: 'break-spaces', cursor: (index === 0 || index === 1) && 'pointer' }}
                        onClick={() => onClickMatrixItem(index)}
                        variant="subtitle2"
                        textTransform={'uppercase'}
                        color={'#fff'}
                      >
                        {ele.matrix_heading}
                      </Typography>
                      {index === keyMatrixData.length - 1 && (
                        <EditDraftBtn onClick={() => navigate(PATH.EDITDRAFT)} variant="contained">
                          <Typography pr={2} fontWeight={'700'}>
                            {t('edit_draft')}
                          </Typography>
                          <FaEdit style={{ fontSize: '16px' }} />
                        </EditDraftBtn>
                      )}
                    </Stack>
                  </MatrixCard>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box mt={5}>
        {overViewInfo?.totalResult?.slice(0, 5).length > 0 && (
          <>
            <Typography fontWeight={'700'}>{t('total_of_all_te_elements')}</Typography>
            <Box mt={4}>
              <Grid container spacing={2}>
                {overViewInfo?.totalResult?.slice(0, 5)?.map((ele, index) => {
                  return (
                    <Grid item key={index} xs={12} sm={6} md={4} xl={2.4} lg={4}>
                      <StyledPaper>
                        <Stack width={'100%'} alignItems={'flex-end'}>
                          <Chip label={ele.indicator} size="small" sx={{ backgroundColor: chip_indicator[ele.indicator], color: '#fff' }} />
                        </Stack>
                        <Stack width={'100%'} my={2} direction={'row'} spacing={4} justifyContent={'center'} alignItems={'center'}>
                          <Box>
                            <img src={CO2_CUP} alt="co2" height={58} width={58} />
                          </Box>
                          <Stack alignItems={'center'}>
                            <Typography variant="h5" fontWeight={'700'}>
                              {!isNaN(Number(ele?.quantity)) ? Number(ele?.quantity).toFixed(2) : parseFloat(ele?.quantity).toFixed(2)}
                            </Typography>
                            <Typography textTransform={'uppercase'} fontSize={12} fontWeight={'700'}>
                              {ele.unit}
                            </Typography>
                            <Typography textTransform={'uppercase'} variant="subtitle2" mt={1}>
                              {ele.type}
                            </Typography>
                          </Stack>
                        </Stack>
                      </StyledPaper>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Box mt={2}>
        {overViewInfo?.totalResult?.length > 5 && (
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <ListView data={overViewInfo?.totalResult?.slice(5)?.slice(0, Math.ceil((overViewInfo?.totalResult?.length - 5) / 2))} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ListView data={overViewInfo?.totalResult?.slice(5)?.slice(Math.ceil((overViewInfo?.totalResult?.length - 5) / 2))} />
            </Grid>
          </Grid>
        )}
      </Box>
      <UploadCSVModal open={openCSVModal} onClose={() => setOpenCSVModal(false)} />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentDashboard;
