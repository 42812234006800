import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  DialogActions,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Popover,
  Snackbar,
  Stack,
  styled,
  TextareaAutosize,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import CommonDialog from '../utils/CommonDialog';
import { CommonButton } from '../utils/CommonButton';
import React, { useEffect, useRef, useState } from 'react';
import CommonInput from '../utils/CommonInput';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CommonSelect from '../utils/CommonSelect';
import CommonOption from '../utils/commonOption';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { RxTarget } from 'react-icons/rx';
import EditIcon from '@mui/icons-material/Edit';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import CertificateLibrary from './CertificateLibrary';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import ArticleIcon from '@mui/icons-material/Article';
import { IoMdArrowBack } from 'react-icons/io';
import { deepOrange } from '@mui/material/colors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateClaim from './CreateClaim';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CategoryStatus, CertificateStatus } from '../../constants/common.constants';
import { useDispatch } from 'react-redux';
import Loader from '../utils/loader';
import ViewDocument from './viewDoc';
import { fetchGoalsCertificates } from '../../features/certificateSlice';
import MultiSelectOption from '../utils/MultiSelectOption';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

function ReviewClaim(props) {
  const { t } = useTranslation();
  const {
    setOpenQuestionForm,
    categoryFormName,
    questionForm,
    certificate,
    fetchClaim,
    data,
    setCategoryId,
    setCategoryFormName,
    claimOptions,
    categoryId,
    fetchClaimQuestionForm,
    setLoading,
    loading,
    updateClaim,
    setUpdateClaim,
    handleUpdateClaim,
    openUpdateClaimForm,
    setOpenUpdateClaimForm,
    categoryClaim,
    setCategoryClaim,
    selectCategoryId,
    setSelectCategoryId,
    claimDetail,
    claimId,
    setClaimId,
  } = props;

  const fileInputRef = useRef(null);
  const [viewDoc, setViewDoc] = React.useState(false);
  const [questionFormData, setQuestionFormData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateNewDialog, setOpenCreateNewDialog] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [errors, setErrors] = useState({});
  const [certificateID, setCertificateID] = useState(null);
  const [openDeleteCertificateDialog, setOpenDeleteCertificateDialog] = useState(false);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [value, setValue] = useState(1);
  const [modelFormData, setModelFormData] = useState({
    attachment: null,
    name: '',
    documentType: '',
    emittedBy: '',
    emissionDate: '',
    expirationDate: '',
    certificateOwner: '',
  });
  const [rejectReason, setRejectReason] = useState('');
  const [viewCategory, setViewCategory] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [claimFormData, setClaimFormData] = useState({
    claimName: '',
    label: '',
    claimStatus: '',
    reason: '',
    certificates: [],
    description: { en: '', fr: '', it: '', de: '', tr: '' },
    supplier: [],
    products: [],
    supplierProducts: [],
  });
  const [lang, setLang] = useState('en');
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [claimSupplier, setClaimSupplier] = useState([]);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [claimProduct, setClaimProduct] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      border: '1px solid #dadde9',
    },
  }));

  const dispatch = useDispatch();
  const handleCreateClaimform = (id, name) => {
    setOpenUpdateClaimForm(true);
    setSelectCategoryId(id);
    setCategoryName(name);
    dispatch(fetchGoalsCertificates());
    fetchClaimQuestionForm(categoryId);
    setClaimFormData({
      claimName: '',
      label: '',
      claimStatus: '',
      reason: '',
      certificates: [],
      description: { en: '', fr: '', it: '', de: '', tr: '' },
    });
    setSelectedCertificates([]);
    setCategoryClaim({});
    setClaimId('');
    setClaimProduct([]);
    setClaimSupplier([]);
    setSupplierProducts([]);
    setValue(1);
    // setUpdateClaim(false);
  };

  useEffect(() => {
    fetchClaimQuestionForm(categoryId);
  }, [categoryId]);
  useEffect(() => {
    fetchClaimQuestionForm(categoryId);
  }, [!openDialog]);

  const closeDialog = () => {
    setOpenDialog(false);
    setAnchorEl(false);
  };
  const closeCreateNewDialog = () => {
    setOpenCreateNewDialog(false);
    setAnchorEl(false);
    setImageUrl('');
    setErrors({});
    setModelFormData({});
    setCertificateID(null);
    setQuestionId('');
    setUpdateDocument(false);
  };
  const handleClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setQuestionId(id);
  };
  const closePopover = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const timeoutRef = useRef(null);
  const latestFormDataRef = useRef(questionFormData);
  const handleClose = () => {
    setOpenQuestionForm(false);
    setOpenUpdateClaimForm(false);
    setUpdateClaim(false);
    setCurrentPage(1);
  };

  const handleQuestionAnswer = async (queId, formData) => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.post(`${RestUrlsConstants.formQueUrl}/${queId}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        fetchClaimQuestionForm(categoryId);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleInput = (newValue, id) => {
    latestFormDataRef.current = {
      ...latestFormDataRef.current,
      [id]: newValue,
    };

    setQuestionFormData(latestFormDataRef.current);
    const formData = new FormData();
    formData.append('value', latestFormDataRef.current[id]);
    handleQuestionAnswer(id, formData);
  };
  const [extraNote, setExtraNote] = useState('');
  const handleTextArea = (newValue, id) => {
    latestFormDataRef.current = {
      ...latestFormDataRef.current,
      [id]: newValue,
    };

    setExtraNote(latestFormDataRef.current);
    const formData = new FormData();
    formData.append('extraNotesValue', latestFormDataRef.current[id]);
    handleQuestionAnswer(id, formData);
  };

  const handleInputChange = (event, name) => {
    const { value, type } = event.target;
    setErrors({
      ...errors,
      [name]: '',
    });
    if (type === 'file') {
      setModelFormData({
        ...modelFormData,
        [name]: event.target.files[0],
      });
    } else {
      setModelFormData({
        ...modelFormData,
        [name]: value,
      });
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const invalidFormats = ['.avi', '.mkv', '.flv', '.wmv', '.webm'];
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (invalidFormats.includes(fileExtension)) {
        setErrors({
          ...errors,
          attachment: 'Document format is invalid.',
        });
        setModelFormData((prevState) => ({
          ...prevState,
          attachment: null,
        }));
        setImageUrl(null);
        return;
      }
      setErrors({
        ...errors,
        attachment: '',
      });
      setModelFormData((prevState) => ({
        ...prevState,
        attachment: file,
      }));
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleCreate = async () => {
    const newErrors = {};
    if (!updateDocument) {
      if (!modelFormData.attachment) newErrors.attachment = t('attachment_is_required');
    }
    if (!modelFormData.name) newErrors.name = t('name_is_required');
    if (!modelFormData.documentType) newErrors.documentType = t('document_type_is_required');
    if (!modelFormData.emittedBy) newErrors.emittedBy = t('emitted_by_is_required');
    if (!modelFormData.certificateOwner) newErrors.certificateOwner = t('certificate_owner_is_required');

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoadingBtn(true);
      const formData = new FormData();
      if (modelFormData.attachment) {
        formData.append('documents', modelFormData.attachment);
      }
      if (!updateDocument) {
        Object.keys(modelFormData).forEach((key) => {
          const value = modelFormData[key];
          if (value !== null && typeof value === 'object' && key !== 'attachment') {
            Object.keys(value).forEach((subKey) => {
              formData.append(`documents[${key}][${subKey}]`, value[subKey]);
            });
          } else if (key !== 'attachment') {
            formData.append(`documents[${key}]`, value);
          }
        });
      } else {
        if (modelFormData.name) {
          formData.append('name', modelFormData.name);
        }
        if (modelFormData.documentType) {
          formData.append('documentType', modelFormData.documentType);
        }
        if (modelFormData.emittedBy) {
          formData.append('emittedBy', modelFormData.emittedBy);
        }
        if (modelFormData.emissionDate) {
          formData.append('emissionDate', modelFormData.emissionDate);
        }
        if (modelFormData.expirationDate) {
          formData.append('expirationDate', modelFormData.expirationDate);
        }
        if (modelFormData.certificateOwner) {
          formData.append('certificateOwner', modelFormData.certificateOwner);
        }
      }
      // if (updateDocument) {
      //   handleUpdateDocument(formData);
      // } else {
      //   handleQuestionAnswer(questionId, formData);
      // }
      // setOpenCreateNewDialog(false);
      // setModelFormData({});
      // setImageUrl('');
      // setCertificateID('');
      // setUpdateDocument(false);
      try {
        if (updateDocument) {
          await handleUpdateDocument(formData);
        } else {
          await handleQuestionAnswer(questionId, formData);
        }
        setOpenCreateNewDialog(false);
        setModelFormData({});
        setImageUrl('');
        setCertificateID('');
        setUpdateDocument(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBtn(false);
      }
    }
  };
  const handleUpdateDocument = async (formData) => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${certificateID}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        fetchClaimQuestionForm(categoryId);
        setCertificateID('');
        setUpdateDocument(false);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleSetGoals = (id, currentValue) => {
    const newGoalValue = !currentValue;
    const formData = {
      setAsFutureGoal: newGoalValue,
    };
    handleQuestionAnswer(id, formData);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const totalPages = Math.ceil(questionForm?.length / 10);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return extension === 'pdf' ||
        extension === 'doc' ||
        extension === 'docx' ||
        extension === 'csv' ||
        extension === 'ppt' ||
        extension === 'pptx' ||
        extension === 'txt' ||
        extension === 'xlsx' ||
        extension === 'xls' ||
        extension === 'mp4' ||
        extension === 'mov'
        ? extension
        : '';
    }
    return '';
  }

  const handleCertificateApproval = async (id) => {
    if (!checkOnlineStatus()) return;
    const payload = {
      verificationStatus: CertificateStatus.valid,
    };
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchClaimQuestionForm(categoryId);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const handleCertificateReject = async () => {
    if (!checkOnlineStatus()) return;
    const payload = {
      verificationStatus: CertificateStatus.notValid,
      reason: rejectReason,
    };
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${certificateID}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchClaimQuestionForm(categoryId);
        setRejectReason('');
        setOpenDeleteCertificateDialog(false);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  useEffect(() => {
    if (updateClaim && categoryClaim && categoryClaim.description) {
      setClaimFormData((prevData) => ({
        ...prevData,
        description: {
          ...prevData.description,
          [lang]: prevData.description ? prevData.description[lang] : categoryClaim.description[lang] || lang,
        },
      }));
    }
  }, [updateClaim, categoryClaim, lang]);

  useEffect(() => {
    if (updateClaim) {
      setClaimId(categoryClaim?._id);
      const certificates = categoryClaim?.certificates?.map((certificateId) => certificateId) || [];
      setSelectedCertificates(certificates);
      const certId = categoryClaim?.certificates?.map((certificateId) => certificateId?.id) || [];
      setClaimFormData((prevData) => ({
        ...prevData,
        claimName: categoryClaim?.claimName,
        label: categoryClaim?.label,
        claimStatus: categoryClaim?.claimStatus,
        reason: categoryClaim?.reason,
        certificates: certId,
        description: categoryClaim?.description,
      }));
      setClaimSupplier(categoryClaim?.suppliers?.map(({ _id, ...rest }) => rest));
      setClaimProduct(categoryClaim?.products?.map(({ _id, ...rest }) => rest));
      setSupplierProducts(categoryClaim?.supplierProducts?.map(({ _id, ...rest }) => rest));
    }
  }, [updateClaim, categoryClaim]);

  const handleDelete = (val) => {
    setOpenDeleteCertificateDialog(true);
    setCertificateID(val?.id);
    setQuestionId(val?.questionId);
  };

  const handleCloseDialog = async () => {
    setOpenDeleteCertificateDialog(false);
    setCertificateID(null);
    setQuestionId('');
    setRejectReason('');
  };

  const indexOfLastQuestion = currentPage * 10;
  const indexOfFirstQuestion = indexOfLastQuestion - 10;
  const currentQuestionForm = questionForm?.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const handleClickCategory = (index) => {
    setViewCategory((prev) => {
      const newViewCategory = [...prev];
      newViewCategory[index] = !newViewCategory[index];
      return newViewCategory;
    });
  };
  const handleQuestionFormOpen = (val) => {
    setLoading(true);
    setCategoryId(val?.id);
    setCategoryFormName(val?.name);
    setOpenUpdateClaimForm(false);
    setSelectCategoryId(val?.id);
    fetchClaimQuestionForm(val?.id);
  };

  const closeCreateClaimForm = () => {
    setClaimFormData({
      claimName: '',
      label: '',
      claimStatus: '',
      reason: '',
      certificates: [],
      description: { en: '', fr: '', it: '', de: '', tr: '' },
    });
    // setCategoryClaim({});
    setOpenUpdateClaimForm(false);
    setUpdateClaim(false);
  };
  const [docUrl, setDocUrl] = useState('');

  const handleOpenDocument = (url) => {
    setViewDoc(true);
    setDocUrl(url);
  };
  const handleCloseDocument = () => {
    setViewDoc(false);
    setDocUrl('');
  };

  
  return (
    <>
      <ViewDocument viewDoc={viewDoc} handleCloseDocument={handleCloseDocument} docUrl={docUrl} />
      <Grid container spacing={2}>
        {openUpdateClaimForm ? (
          <>
            <Grid item xs={12}>
              <Button
                startIcon={<IoMdArrowBack />}
                onClick={() => closeCreateClaimForm()}
                sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2, mb: 3 }}
              >
                {t('back')}
              </Button>
            </Grid>
            <CreateClaim
              setOpenClaimForm={setOpenUpdateClaimForm}
              selectCategoryId={selectCategoryId}
              setSelectCategoryId={setSelectCategoryId}
              fetchClaim={fetchClaim}
              certificate={certificate}
              categoryName={categoryName}
              claimOptions={claimOptions}
              categoryClaim={categoryClaim}
              claimFormData={claimFormData}
              setClaimFormData={setClaimFormData}
              setUpdateClaim={setUpdateClaim}
              updateClaim={updateClaim}
              lang={lang}
              setLang={setLang}
              claimId={claimId}
              selectedCertificates={selectedCertificates}
              setSelectedCertificates={setSelectedCertificates}
              claimSupplier={claimSupplier}
              setClaimSupplier={setClaimSupplier}
              claimProduct={claimProduct}
              setClaimProduct={setClaimProduct}
              claimDetail={claimDetail}
              value={value}
              setValue={setValue}
              supplierProducts={supplierProducts}
              setSupplierProducts={setSupplierProducts}
            />
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Button
                startIcon={<IoMdArrowBack />}
                onClick={() => handleClose()}
                sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2, mb: 3 }}
              >
                {t('back')}
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Card sx={{ boxShadow: 'none' }}>
                <CardContent>
                  <Typography sx={{ fontWeight: '600', fontSize: '16px', display: 'flex', gap: '10px', color: '#0e1090' }}>{categoryFormName}</Typography>
                  <Typography sx={{ fontSize: '14px', color: 'gray', mt: 1 }}>{t('note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting')}</Typography>
                  <Typography sx={{ fontSize: '14px', color: 'gray' }}>{t('enjoy_the_process')}</Typography>
                </CardContent>
              </Card>
              <Card sx={{ mt: 4 }}>
                <CardContent>
                  <form>
                    {loading ? (
                      <Loader />
                    ) : (
                      <Box>
                        {currentQuestionForm?.map((form, index) => {
                          const answer = form?.answer?.value;
                          return (
                            <Box key={index}>
                              {['text', 'TEXT', 'number'].includes(form?.question?.type) && (
                                <CommonInput
                                  label={form?.question?.title}
                                  value={questionFormData[form?.question?.id] || answer || ''}
                                  type={form?.question?.type}
                                  tooltip={form?.question?.tooltipText}
                                  variant="standard"
                                  autoComplete={false}
                                  handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                                />
                              )}

                              {form?.question?.type === 'dropdown' && (
                                <CommonSelect
                                  tooltip={form?.question?.tooltipText}
                                  label={form?.question?.title}
                                  options={form?.question?.validation?.validOptions}
                                  value={questionFormData[form?.question?.id] || answer || ''}
                                  handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                                />
                              )}
                              {form?.question?.type === 'options' &&
                                (form?.question?.validation?.isMultiOption ? (
                                  <MultiSelectOption
                                    tooltip={form?.question?.tooltipText}
                                    label={form?.question?.title}
                                    options={form?.question?.validation?.validOptions}
                                    value={questionFormData[form?.question?.id] || answer || ''}
                                    handleChange={(selectedValue) => handleInput(selectedValue, form?.question?.id)}
                                  />
                                ) : (
                                  <CommonOption
                                    tooltip={form?.question?.tooltipText}
                                    label={form?.question?.title}
                                    options={form?.question?.validation?.validOptions}
                                    value={questionFormData[form?.question?.id] || answer || ''}
                                    handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                                  />
                                ))}
                              {form?.question?.showSetAsFutureGoal && (
                                <Box>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      color: form?.answer?.setAsFutureGoal ? '#fff' : '#0e1090',
                                      fontWeight: '600',
                                      mt: 2,
                                      px: 3,
                                      background: form?.answer?.setAsFutureGoal ? '#0e1090' : '#eeeeee',
                                      borderRadius: '15px',
                                      border: '1px solid gray',
                                      ':hover': { background: form?.answer?.setAsFutureGoal ? '#0e1090' : '#fff' },
                                      display: 'flex',
                                      gap: '10px',
                                      fontSize: '12px',
                                    }}
                                    onClick={() => handleSetGoals(form?.question?.id, form?.answer?.setAsFutureGoal)}
                                  >
                                    <RxTarget style={{ fontSize: '18px' }} /> {form?.answer?.setAsFutureGoal ? t('you_have_set_this_as_a_goal') : t('set_as_future_goal')}
                                  </Button>
                                </Box>
                              )}
                              {form?.question?.extraNotes && (
                                <Box mt={2}>
                                  <Typography sx={{ color: 'gray', fontWeight: '600', fontSize: '14px' }}>{t('extra_notes')}</Typography>
                                  <TextareaAutosize
                                    id={`textarea-${form?.question?.id}`}
                                    aria-label="minimum height"
                                    minRows={1}
                                    placeholder={t('extra_notes') + '...'}
                                    style={{
                                      border: 'none',
                                      width: '100%',
                                      borderBottom: '1px solid gray',
                                    }}
                                    value={extraNote[form?.question?.id] || form?.answer?.extraNotesValue || ''}
                                    onChange={(e) => handleTextArea(e.target.value, form?.question?.id)}
                                  />
                                </Box>
                              )}
                              {form?.question?.fileUpload && (
                                <Box mt={3}>
                                  <Grid container>
                                    {form?.answer?.documents?.map((val, index) => (
                                      <>
                                        {(val?.urls[0]?.includes('.pdf') ||
                                          val?.urls[0]?.includes('.doc') ||
                                          val?.urls[0]?.includes('.docx') ||
                                          val?.urls[0]?.includes('.csv') ||
                                          val?.urls[0]?.includes('.txt') ||
                                          val?.urls[0]?.includes('.xlsx') ||
                                          val?.urls[0]?.includes('.xls') ||
                                          val?.urls[0]?.includes('.ppt') ||
                                          val?.urls[0]?.includes('.pptx') ||
                                          val?.urls[0]?.startsWith('https://static.renoon.com')) && (
                                          <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                            <Grid item xs={2} sx={{ px: 1, mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                              <Box
                                                sx={{
                                                  height: '120px',
                                                  width: '100px',
                                                  border: '1px solid gray',
                                                  bgcolor: '#fff',
                                                  borderRadius: '8px',
                                                  textAlign: 'justify',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  position: 'relative',
                                                }}
                                              >
                                                <Link className="certificateDownload" target="_blank" href={val?.urls} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                  <ArticleIcon style={{ fontSize: '50px', color: '#101010' }} />
                                                  <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(val?.urls[0])}</span>
                                                </Link>
                                                {val?.verificationStatus !== CertificateStatus.pending && (
                                                  <Chip
                                                    label={val?.verificationStatus === CertificateStatus.valid ? 'Valid' : 'Not Valid'}
                                                    color={val?.verificationStatus === CertificateStatus.valid ? 'success' : 'error'}
                                                    sx={{
                                                      height: '20px',
                                                      color: '#fff',
                                                      position: 'absolute',
                                                      bottom: '3px',
                                                      left: '50%',
                                                      transform: 'translateX(-50%)',
                                                      fontSize: '10px',
                                                    }}
                                                    icon={<CheckCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />}
                                                  />
                                                )}
                                              </Box>

                                              <Box>
                                                <Typography
                                                  sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                >
                                                  {val?.name}
                                                </Typography>
                                              </Box>
                                              {val?.verificationStatus === CertificateStatus.pending && (
                                                <Box sx={{ display: 'flex', gap: '10px', background: '#EFEFEF', padding: '4px', borderRadius: '30px' }}>
                                                  <CancelIcon onClick={() => handleDelete(val)} sx={{ color: '#f24822', fontSize: '20px', cursor: 'pointer' }} />
                                                  <CheckCircleIcon
                                                    onClick={() => {
                                                      handleCertificateApproval(val?.id);
                                                    }}
                                                    sx={{ color: '#14ae5c', fontSize: '20px', cursor: 'pointer' }}
                                                  />
                                                </Box>
                                              )}
                                            </Grid>
                                          </CustomTooltip>
                                        )}
                                        {(val?.urls[0]?.includes('.png') || val?.urls[0]?.includes('.jpeg') || val?.urls[0]?.includes('.jpg')) && (
                                          <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                            <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'nowrap', px: 1, mt: 1 }}>
                                              <Box sx={{ position: 'relative', cursor: 'pointer' }}>
                                                <img
                                                  onClick={() => handleOpenDocument(val?.urls)}
                                                  src={val?.urls}
                                                  alt=""
                                                  height={'120px'}
                                                  width={'100px'}
                                                  style={{ borderRadius: '8px', border: '1px solid gray' }}
                                                />
                                                {val?.verificationStatus !== CertificateStatus.pending && (
                                                  <Chip
                                                    label={val?.verificationStatus === CertificateStatus.valid ? 'Valid' : 'Not Valid'}
                                                    color={val?.verificationStatus === CertificateStatus.valid ? 'success' : 'error'}
                                                    sx={{
                                                      height: '20px',
                                                      color: '#fff',
                                                      position: 'absolute',
                                                      bottom: '3px',
                                                      left: '50%',
                                                      transform: 'translateX(-50%)',
                                                      fontSize: '10px',
                                                    }}
                                                    icon={<CheckCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />}
                                                  />
                                                )}
                                              </Box>
                                              <Box>
                                                <Typography
                                                  sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                >
                                                  {val?.name}
                                                </Typography>
                                              </Box>
                                              {val?.verificationStatus === CertificateStatus.pending && (
                                                <Box sx={{ display: 'flex', gap: '10px', background: '#EFEFEF', padding: '4px', borderRadius: '30px' }}>
                                                  <CancelIcon onClick={() => handleDelete(val)} sx={{ color: '#f24822', fontSize: '20px', cursor: 'pointer' }} />
                                                  <CheckCircleIcon
                                                    onClick={() => {
                                                      handleCertificateApproval(val?.id);
                                                    }}
                                                    sx={{ color: '#14ae5c', fontSize: '20px', cursor: 'pointer' }}
                                                  />
                                                </Box>
                                              )}
                                            </Grid>
                                          </CustomTooltip>
                                        )}

                                        {(val?.urls[0]?.includes('.mp4') || val?.urls[0]?.includes('.mov')) && (
                                          <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                            <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'nowrap', px: 1, mt: 1 }}>
                                              <Link className="certificateDownload" target="_blank" href={val?.urls} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Box sx={{ position: 'relative', cursor: 'pointer' }}>
                                                  <video src={val?.urls} alt="" height={'120px'} width={'100px'} style={{ borderRadius: '8px', border: '1px solid gray', objectFit: 'cover' }} muted />
                                                  <PlayCircleOutlineIcon
                                                    style={{
                                                      position: 'absolute',
                                                      top: '50%',
                                                      left: '50%',
                                                      transform: 'translate(-50%, -50%)',
                                                      fontSize: '30px',
                                                      color: 'white',
                                                      opacity: 0.8,
                                                    }}
                                                  />
                                                  {val?.verificationStatus !== CertificateStatus.pending && (
                                                    <Chip
                                                      label={val?.verificationStatus === CertificateStatus.valid ? 'Valid' : 'Not Valid'}
                                                      color={val?.verificationStatus === CertificateStatus.valid ? 'success' : 'error'}
                                                      sx={{
                                                        height: '20px',
                                                        color: '#fff',
                                                        position: 'absolute',
                                                        bottom: '3px',
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                        fontSize: '10px',
                                                      }}
                                                      icon={<CheckCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />}
                                                    />
                                                  )}
                                                </Box>
                                              </Link>
                                              <Box>
                                                <Typography
                                                  sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                >
                                                  {val?.name}
                                                </Typography>
                                              </Box>
                                              {val?.verificationStatus === CertificateStatus.pending && (
                                                <Box sx={{ display: 'flex', gap: '10px', background: '#EFEFEF', padding: '4px', borderRadius: '30px' }}>
                                                  <CancelIcon onClick={() => handleDelete(val)} sx={{ color: '#f24822', fontSize: '20px', cursor: 'pointer' }} />
                                                  <CheckCircleIcon
                                                    onClick={() => {
                                                      handleCertificateApproval(val?.id);
                                                    }}
                                                    sx={{ color: '#14ae5c', fontSize: '20px', cursor: 'pointer' }}
                                                  />
                                                </Box>
                                              )}
                                            </Grid>
                                          </CustomTooltip>
                                        )}
                                      </>
                                    ))}
                                  </Grid>
                                  {form?.question?.type === 'file' && <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{form?.question?.title}</InputLabel>}
                                  <Button
                                    sx={{
                                      marginTop: '20px',
                                      height: '150px',
                                      width: '100%',
                                      color: 'black',
                                      boxShadow: 'none',
                                      border: '2px dashed gray',
                                      background: `#eeeeee`,
                                      textTransform: 'none',
                                      fontWeight: 'bold',
                                      ':hover': { background: '#eeeeee', boxShadow: 'none' },
                                    }}
                                    variant="contained"
                                    onClick={(e) => handleClick(e, form?.question?.id)}
                                  >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                      <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                        <CloudUploadIcon sx={{ color: 'gray', fontSize: '40px' }} />
                                        {t('choose_file')}
                                        <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{t('file_types')} </span>
                                      </Box>
                                    </Typography>
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </form>

                  <DialogActions sx={{ mt: 2 }}>
                    <Box mt={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <span style={{ fontSize: '14px', color: 'gray' }}>
                          {t('page')} : {currentPage}/{totalPages}
                        </span>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Button
                            sx={{ background: '#101010', textTransform: 'capitalize', ':hover': { background: '#101012' } }}
                            size="small"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            variant="contained"
                            startIcon={<KeyboardArrowLeftIcon />}
                          >
                            {t('prev')}
                          </Button>
                          <Button
                            sx={{ background: '#101010', textTransform: 'capitalize', ':hover': { background: '#101012' } }}
                            size="small"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            variant="contained"
                            endIcon={<KeyboardArrowRightIcon />}
                          >
                            {t('next')}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </DialogActions>
                </CardContent>
              </Card>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Typography
                  onClick={() => {
                    setOpenDialog(true);
                    closePopover();
                    dispatch(fetchGoalsCertificates());
                  }}
                  sx={{ p: 1, cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                >
                  {t('pick_from_library')}
                </Typography>
                <Typography
                  onClick={() => {
                    setOpenCreateNewDialog(true);
                    closePopover();
                  }}
                  sx={{ p: 1, cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                >
                  {t('create_new')}
                </Typography>
              </Popover>
              <CertificateLibrary formId={categoryId} questionId={questionId} openDialog={openDialog} closeDialog={closeDialog} />
              <CommonDialog
                heading={
                  <span>
                    {t('certificate_details')} <IoIosInformationCircleOutline />
                  </span>
                }
                open={openCreateNewDialog}
                handleClose={closeCreateNewDialog}
              >
                <DialogContentText sx={{ width: '500px' }}>
                  <form>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('attachment') + '*'}</InputLabel>
                    <input ref={fileInputRef} required multiple type="file" hidden onChange={handleFileInput} />
                    <Button
                      sx={{
                        height: '200px',
                        width: '300px',
                        color: '#000',
                        background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#EFEFEF',
                        ':hover': { background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#ededed' },
                      }}
                      variant="contained"
                      onClick={() => fileInputRef.current.click()}
                    >
                      {!imageUrl && <EditIcon />}
                      {(imageUrl?.includes('.pdf') ||
                        imageUrl?.includes('.doc') ||
                        imageUrl?.includes('.docx') ||
                        imageUrl?.includes('.csv') ||
                        imageUrl?.includes('.txt') ||
                        imageUrl?.includes('.ppt') ||
                        imageUrl?.includes('.pptx') ||
                        imageUrl?.includes('.mp4') ||
                        imageUrl?.includes('.mov') ||
                        imageUrl?.includes('.xlsx')) &&
                        getFileExtension(imageUrl)}
                      {!updateDocument && <Typography sx={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{imageUrl}</Typography>}
                    </Button>
                    {errors.attachment && (
                      <Typography color="error" fontSize={'12px'}>
                        {errors.attachment}
                      </Typography>
                    )}
                    <CommonInput
                      label={t('insert_name') + '*'}
                      placeholder={t('insert_name')}
                      type="text"
                      variant="standard"
                      name="name"
                      value={modelFormData.name}
                      handleChange={(e) => handleInputChange(e, 'name')}
                    />
                    {errors.name && (
                      <Typography color="error" fontSize={'12px'}>
                        {errors.name}
                      </Typography>
                    )}
                    <CommonSelect
                      options={['Certification', 'Proof point']}
                      name="documentType"
                      label={t('select_type') + '*'}
                      value={modelFormData.documentType}
                      handleChange={(e) => handleInputChange(e, 'documentType')}
                    />
                    {errors.documentType && (
                      <Typography color="error" fontSize={'12px'}>
                        {errors.documentType}
                      </Typography>
                    )}

                    <CommonInput
                      required={true}
                      label={t('emitted_by') + '*'}
                      placeholder={t('emitted_by')}
                      type="text"
                      variant="standard"
                      name="emittedBy"
                      value={modelFormData.emittedBy}
                      handleChange={(e) => handleInputChange(e, 'emittedBy')}
                    />
                    {errors.emittedBy && (
                      <Typography color="error" fontSize={'12px'}>
                        {errors.emittedBy}
                      </Typography>
                    )}
                    <CommonInput
                      required={true}
                      label={t('insert_date_of_emission')}
                      type="date"
                      variant="standard"
                      name="emissionDate"
                      value={modelFormData.emissionDate}
                      handleChange={(e) => handleInputChange(e, 'emissionDate')}
                    />

                    <CommonInput
                      label={t('insert_expiration_date')}
                      type="date"
                      variant="standard"
                      name="expirationDate"
                      value={modelFormData.expirationDate}
                      handleChange={(e) => handleInputChange(e, 'expirationDate')}
                    />

                    <CommonSelect
                      options={['Supplier', 'Brand']}
                      name="certificateOwner"
                      label={t('select_certificate_owner') + '*'}
                      value={modelFormData.certificateOwner}
                      handleChange={(e) => handleInputChange(e, 'certificateOwner')}
                    />
                    {errors.certificateOwner && (
                      <Typography color="error" fontSize={'12px'}>
                        {errors.certificateOwner}
                      </Typography>
                    )}
                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                      <CommonButton
                        buttonName={
                          loadingBtn ? (
                            <>
                              <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                              {t('creating') + '...'}
                            </>
                          ) : (
                            t('create')
                          )
                        }
                        handleClick={handleCreate}
                      />
                    </Box>
                  </form>
                </DialogContentText>
              </CommonDialog>
              <CommonDialog heading={t('reject_document')} open={openDeleteCertificateDialog} handleClose={handleCloseDialog}>
                <DialogContentText sx={{ width: '500px' }}>
                  <CommonInput placeholder={t('reason_for_rejection')} variant="standard" value={rejectReason} handleChange={(e) => setRejectReason(e.target.value)} />
                  <DialogActions>
                    <Button
                      onClick={handleCertificateReject}
                      sx={{ fontWeight: 'bold', textTransform: 'none', color: '#fff', borderRadius: '30px', background: '#f24822', ':hover': { background: '#f24822' } }}
                    >
                      {t('reject')} <NavigateNextIcon />
                    </Button>
                  </DialogActions>
                </DialogContentText>
              </CommonDialog>
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography sx={{ fontWeight: '600', fontSize: '16px', display: 'flex', gap: '10px', color: '#000' }}>
                {t('categories_and_claims')}
                <Avatar sx={{ bgcolor: deepOrange[500], height: '22px', width: '22px', color: '#fff', fontSize: '10px' }}>
                  {data?.filter((category) => category?.status === CategoryStatus.submitted || category?.status === CategoryStatus.reviewed)?.length}
                </Avatar>
              </Typography>
              <Box>
                {data?.map((val, index) => (
                  <React.Fragment key={index}>
                    {(val?.status === CategoryStatus.submitted || val?.status === CategoryStatus.reviewed) && (
                      <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', my: 2 }}>
                          <AddBoxIcon
                            onClick={() => handleCreateClaimform(val?.id, val?.name)}
                            sx={{ fontSize: '40px', color: openUpdateClaimForm && selectCategoryId === val?.id ? '#0e1090' : 'gray', cursor: 'pointer' }}
                          />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px', borderBottom: '1px solid gray', width: '100%' }}>
                            <Typography
                              sx={{ color: categoryId === val?.id ? '#0e1090' : 'gray', fontWeight: '600', cursor: 'pointer' }}
                              onClick={() => {
                                handleQuestionFormOpen(val);
                              }}
                            >
                              {val?.name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="body2" sx={{ color: 'gray' }}>
                                {t('all')}
                              </Typography>
                              <IconButton size="small" onClick={() => handleClickCategory(index, val?.id, val?.id)}>
                                {viewCategory[index] ? <ArrowDropUpIcon sx={{ color: 'gray' }} /> : <ArrowDropDownIcon sx={{ color: 'gray' }} />}
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="example" mt={1} sx={{ display: viewCategory[index] ? 'block' : 'flex', flexWrap: 'nowrap', gap: '10px', overflow: 'auto' }}>
                          {val?.claims?.map((list, itemIndex) => (
                            <React.Fragment key={itemIndex}>
                              <Stack direction="row" sx={{ py: 1 }} spacing={1}>
                                <Chip
                                  onClick={() => {
                                    handleUpdateClaim(val?.id, list?._id);
                                    fetchClaimQuestionForm(val?.id);
                                    setValue(1);
                                  }}
                                  label={
                                    <div>
                                      {list?.claimName}
                                      <Chip label={list?.label} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                    </div>
                                  }
                                  sx={{
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    color: '#fff',
                                    bgcolor:
                                      list?.claimStatus === CategoryStatus.verified
                                        ? '#28a745'
                                        : list?.claimStatus === CategoryStatus.proven
                                        ? '#218838'
                                        : list?.claimStatus === CategoryStatus.declared
                                        ? '#1e7e34'
                                        : '#c82333',
                                    ':hover': {
                                      bgcolor:
                                        list?.claimStatus === CategoryStatus.verified
                                          ? '#28a745'
                                          : list?.claimStatus === CategoryStatus.proven
                                          ? '#218838'
                                          : list?.claimStatus === CategoryStatus.declared
                                          ? '#1e7e34'
                                          : '#c82333',
                                    },
                                  }}
                                />
                              </Stack>
                            </React.Fragment>
                          ))}
                        </Box>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
export default ReviewClaim;
