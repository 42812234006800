import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import img1 from '../../images/Rectangle 35.png';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import Loader from '../utils/loader';
import { useDispatch } from 'react-redux';
import FilterSupplierProduct from '../utils/FilterSupplierProduct';
import { decryptId } from '../utils/encryptDecrypt';
import { fetchSupplierProduct, fetchSupplierProductWithSearch } from '../../features/supplierProductSlice';
import { fetchChainById } from '../../features/ChainByIdSlice';
import { useParams } from 'react-router-dom';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

export default function SelectProduct(props) {
  const { openSelectProduct, setOpenSelectProduct, supplierProduct, supplierId, setSupplierId, selectedNodeId, loading, selectedSupplyChain, supplierChainID } = props;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const params = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const handleSearchChange = async (e) => {
    setSearchTerm(e.target.value);
    const term = e.target.value;
    if (term.trim() === '') {
      dispatch(fetchSupplierProduct(decryptId(supplierId)));
    } else {
      dispatch(fetchSupplierProductWithSearch({ supplierId, term }));
    }
  };

  const [supplyChainData, setSupplyChainData] = useState({
    products: [],
  });
  const toggleSupplierSelection = (selectedProduct) => {
    setSupplyChainData((prevData) => {
      const isSelected = prevData?.products?.some((product) => product?.supplierProductId === selectedProduct?.id);
      if (isSelected) {
        return {
          ...prevData,
          products: prevData?.products?.filter((product) => product?.supplierProductId !== selectedProduct?.id),
        };
      } else {
        return {
          ...prevData,
          products: [
            ...prevData?.products,
            {
              supplierProductId: selectedProduct?.id,
              titleVanityName: selectedProduct?.titleVanityName?.value === 'undefined' ? '' : selectedProduct?.titleVanityName?.value,
              title: selectedProduct?.title,
              stage: selectedProduct?.stage,
              stageVanityName: selectedProduct?.stageVanityName?.value === 'undefined' ? '' : selectedProduct?.stageVanityName?.value,
            },
          ],
        };
      }
    });
  };

  const isProductSelected = (productId) => {
    return supplyChainData?.products?.some((product) => product?.supplierProductId === productId);
  };

  function addProducts(selectedChin, selectedNodeId, supplierId, products) {
    if (!checkOnlineStatus()) return;
    if (String(selectedChin._id) === selectedNodeId) {
      if (selectedChin.products) {
        let newProducts = [...selectedChin.products, ...products];
        selectedChin.products = newProducts;
      } else {
        let newProducts = [...products];
        selectedChin.products = newProducts;
      }
      return selectedChin;
    } else if (selectedChin?.products?.length) {
      selectedChin.products.map((e) => {
        if (e.suppliers?.length) {
          e.suppliers.map((supp) => {
            return addProducts(supp, selectedNodeId, supplierId, supplyChainData?.products);
          });
        }
        return e;
      });
    }
  }
  const handleCreateSupplyChain = async () => {
    if (!checkOnlineStatus()) return;
    try {
      let newSelectedchain = JSON.parse(JSON.stringify(selectedSupplyChain));
      if (newSelectedchain?.suppliers?.length) {
        newSelectedchain.suppliers.map((e) => {
          return addProducts(e, selectedNodeId, supplierId, supplyChainData?.products);
        });
      }
      delete newSelectedchain.description;
      const payload = {
        ...newSelectedchain,
      };
      const response = await httpService.put(`${RestUrlsConstants.supplyChain}/${selectedSupplyChain?.id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        // setSupplyChainData({
        //   suppliers: [
        //     {
        //       products: [],
        //     },
        //   ],
        // });
        setSupplyChainData({ products: [] });
        setSupplierId('');
        setOpenSelectProduct(false);
        dispatch(fetchChainById(supplierChainID || decryptId(params.id)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectProduct = () => {
    handleCreateSupplyChain();
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openSelectProduct} onClose={() => setOpenSelectProduct(false)}>
        <DialogContent sx={{ bgcolor: '#fff', width: '500px', py: 0, pb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <DialogTitle sx={{ color: '#0e1090', fontWeight: 'bold', pl: 0 }}>{t('select_the_supplier_product')}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenSelectProduct(false)}
              sx={{
                color: 'black',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <TextField
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                  }}
                  variant="outlined"
                  placeholder={t('search')}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FilterSupplierProduct supplierId={supplierId} showFilter={true} />
              </Box>
              {loading ? (
                <Loader />
              ) : (
                <Grid mt={2} container spacing={2}>
                  {supplierProduct?.map((val, index) => (
                    <Card key={index} sx={{ position: 'relative', width: '100%', p: 2, pb: 0, borderRadius: 2, mt: 2, boxShadow: 'none' }}>
                      <CardMedia component="img" sx={{ width: '100%', height: 150, borderRadius: 2 }} image={img1} alt="Product image" />
                      <CardContent sx={{ px: 0, pt: 1, pb: 0 }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography sx={{ color: '#000', fontWeight: 'bold' }}>{val?.title}</Typography>
                            <Typography color="text.secondary" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                              Suppliers have products or services
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end' }}>
                            <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>SKU-{val?.skuId}</Typography>
                            <Typography color="text.secondary" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                              {val?.titleVanityName?.value}
                            </Typography>
                            <IconButton
                              onClick={() => toggleSupplierSelection(val)}
                              size="small"
                              sx={{
                                bgcolor: isProductSelected(val.id) ? '#0e1090' : '#888',
                                color: '#fff',
                                borderRadius: '5px',
                                ':hover': { bgcolor: isProductSelected(val.id) ? '#0e1090' : '#888' },
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <Grid container bgcolor={'#000'} py={2} px={1}>
          <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Button onClick={handleSelectProduct} size="small" sx={{ color: '#000', bgcolor: '#fff', borderRadius: '30px', px: 4, ':hover': { bgcolor: '#fff' } }}>
              {t('continue')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
