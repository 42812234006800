import CryptoJS from 'crypto-js';

export const encryptId = (id) => {
  if (!id || Array.isArray(id)) {
    return '';
  }
  return encodeURIComponent(CryptoJS.AES.encrypt(id.toString(), '').toString());
};

export const decryptId = (cipherText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, '');
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    if (!plainText) {
      throw new Error('Decryption resulted in empty plaintext, possibly due to invalid input.');
    }
    return plainText;
  } catch (error) {
    return null;
  }
};
