import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Grid, IconButton, InputAdornment, Pagination, Snackbar, TextField, Typography } from '@mui/material';
import AlertDialog from '../utils/AlertDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplierProduct, fetchSupplierProductWithSearch } from '../../features/supplierProductSlice';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterSupplierProduct from '../utils/FilterSupplierProduct';
import Loader from '../utils/loader';

const SelectClaimSupplierProduct = ({ supplierId, supplierProducts, setSupplierProducts, handleClose, supplierName, }) => {
  const { productData, loading } = useSelector((state) => state.product);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(productData?.supplierProducts?.length / itemsPerPage);
  const currentPageData = useMemo(() => {
    const sortedProducts = [...(productData?.supplierProducts || [])].sort((a, b) => {
      const isSelectedA = supplierProducts.some((product) => product.supplierProductId === a.id);
      const isSelectedB = supplierProducts.some((product) => product.supplierProductId === b.id);
      if (isSelectedA && !isSelectedB) return -1; // A comes first
      if (!isSelectedA && isSelectedB) return 1; // B comes first
      return 0;
    });
    return sortedProducts.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  }, [productData, page, supplierProducts]);

  const handleSupplierProductPageChange = (event, value) => setPage(value);


  useEffect(() => {
    dispatch(fetchSupplierProduct({ supplierId, page }));
  }, [dispatch, supplierId, page]);

  useEffect(() => {
    if (!Array.isArray(supplierProducts)) {
      setSupplierProducts([]);
    }
  }, [supplierProducts]);

  useEffect(() => {
    if (openAlertChainDialog) {
      setSupplierProducts([...supplierProducts]);
    }
  }, [openAlertChainDialog]);

  const handleSearchChange = (e) => {
    if (!checkOnlineStatus()) return;
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      dispatch(fetchSupplierProduct({ supplierId, page: 1 }));
    } else {
      dispatch(fetchSupplierProductWithSearch({ supplierId, term }));
    }
  };

  const selectProduct = (e, val) => {
    if (!checkOnlineStatus()) return;
    if (e.target.checked) {
      setSupplierProducts((prevData = []) => [...prevData, { supplierProductId: val?.id, name: val?.title,supplierId }]);
    } else {
      setSupplierProducts((prevData) => prevData.filter((product) => product.supplierProductId !== val?.id));
    }
  };

  const handleSelectAll = useCallback(() => {
    if (!checkOnlineStatus()) return;
    const allProducts = productData.supplierProducts.map((product) => ({
      supplierProductId: product.id,
      name: product.title,
    }));
    setSupplierProducts(supplierProducts.length === allProducts.length ? [] : allProducts);
  }, [checkOnlineStatus, productData.supplierProducts, supplierProducts, setSupplierProducts]);

  const handleRemoveSelectedProduct = () => {
    if (!checkOnlineStatus()) return;
    setSupplierProducts([]);
    setOpenAlertChainDialog(false);
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <TextField
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
              },
            }}
            variant="outlined"
            placeholder={t('search')}
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FilterSupplierProduct supplierId={supplierId} showFilter={false} />
        </Box>
        <Pagination
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'gray',
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: '#EC895A',
              color: '#fff',
            },
          }}
          count={pageCount}
          page={page}
          onChange={handleSupplierProductPageChange}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="50%" mt={1} gap={2}>
        <Button
          onClick={handleSelectAll}
          sx={{
            bgcolor: !productData.currentRecords ? 'gray' : '#101010',
            color: '#fff',
            ':hover': { bgcolor: !productData.currentRecords ? 'gray' : '#0a0a0a' },
            borderRadius: '20px',
            height: '40px',
            width: '120px',
            textAlign: 'center',
            textTransform: 'capitalize',
          }}
          disabled={!productData.currentRecords}
        >
          {!productData.currentRecords || supplierProducts?.length !== productData.supplierProducts?.length ? t('select_all') : t('deselect_all')}
        </Button>
        <Button
          sx={{
            bgcolor: 'gray',
            ':hover': { bgcolor: 'gray' },
            color: '#fff',
            borderRadius: '20px',
            height: '40px',
            width: '120px',
            textAlign: 'center',
            textTransform: 'capitalize',
            cursor: 'none',
          }}
        >
          {t('selected')}:
          {loading ? (
            <CircularProgress size={'1rem'} sx={{ color: 'blue', marginLeft: '5px' }} />
          ) : (
            supplierProducts?.filter((product) => productData?.supplierProducts?.some((supplierProduct) => supplierProduct.id === product.supplierProductId))?.length || 0
          )}
        </Button>
        <Button
          disabled={ (supplierProducts?.filter((product) => productData?.supplierProducts?.some((supplierProduct) => supplierProduct.id === product.supplierProductId))?.length  <= 0 || 0) || supplierProducts?.length === productData.supplierProducts?.length}
          onClick={() => setOpenAlertChainDialog(true)}
          sx={{
            bgcolor:  (supplierProducts?.filter((product) => productData?.supplierProducts?.some((supplierProduct) => supplierProduct.id === product.supplierProductId))?.length <= 0 ||0) || supplierProducts?.length === productData.supplierProducts?.length ? 'gray' : '#000',
            ':hover': { bgcolor: '#101010' },
            color: '#fff',
            borderRadius: '20px',
            height: '40px',
            width: '120px',
            textAlign: 'center',
            textTransform: 'capitalize',
          }}
        >
          {t('Unselect_All')}
        </Button>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Grid mt={2} container spacing={4}>
          {productData?.currentRecords > 0 ? (
            currentPageData?.map((val, index) => (
              <Grid key={val.id} item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container p={1} borderRadius={2} sx={{ boxShadow: '0px 1px 0px 1px #EFEFEF' }}>
                  <Grid item xs={3}>
                    <img src={val?.urls[0]} alt="" height={'40px'} width={'40px'} style={{ borderRadius: '10px' }} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography sx={{ color: '#000', fontSize: '14px', fontWeight: 'bold', display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {val?.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'gray',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {supplierName}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'gray',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {val?.stage}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign={'center'}>
                    <Checkbox
                      {...label}
                      icon={<CheckCircleIcon style={{ color: 'gray' }} />}
                      checkedIcon={<CancelIcon style={{ color: 'red' }} />}
                      checked={(supplierProducts || []).some((product) => product.supplierProductId === val.id)}
                      onChange={(e) => selectProduct(e, val)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>{t('no_product')}</Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
        <CardContent>
          <Box>
            {/* <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('create_the_claim')}</Typography>
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}</Typography> */}
            <Box sx={{ mt: 2 }}>
              <Button onClick={handleClose} sx={{ fontWeight: 'bold', textTransform: 'none', color: '#fff', borderRadius: '30px', background: '#0e1090', ':hover': { background: '#0e1090' } }}>
                {t('save_product')} <NavigateNextIcon />
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <AlertDialog
        btnName={t('yes')}
        title={t('are_you_sure_you_want_to_remove_all_prod')}
        description={t('remove_all_the_products_connected_to_this_supplier')}
        open={openAlertChainDialog}
        handleClick={handleRemoveSelectedProduct}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
        }}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default SelectClaimSupplierProduct;
