const PATH = {
  NAV: '/',
  LOGIN: `/login`,
  FORGOTPASSWORD: `/forgot-password`,
  RESETPASSWORD: `/reset-password/:token`,
  HOME: `/`,
  ROLE: `/role`,
  BRAND: `/brand`,
  USER: `/user`,
  FILES: '/files',
  REPORT: `/report`,
  IMPACTLABEL: `/impact-label`,
  CLAIMMANAGEMENT: `/claim-management`,
  QUESTIONFORM: `/claim-management/que`,
  CATEGORYQUESTIONS: `/claim-management/category/que`,
  CLAIMMANAGEMENTFORM: `/claim-management/form`,
  CERTIFICATE: `/claim-management/certificate`,
  ORDERENGINE: `/order-engine`,
  SUPPLYCHAIN: `/supply-chain-management`,
  USERPROFILE: '/user-profile',
  SETTINGS: '/settings',
  PRODUCTENGINE: `/product-engine`,
  CREATEPRODUCT: `create-new-product`,
  UPDATEPRODUCT: `update-product`,
  ADDSUPPLIER: `/supply-chain-management/supplier`,
  UPDATESUPPLIER: `update`,
  ADDSUPPLIERPRODUCT: `product`,
  SUPPLYCHAINGRAPH: `/supply-chain-management/graph`,
  LIFECYCLEASSESSMENT: `/life-cycle-assessment`,
  STARTCALCULATION: `/life-cycle-assessment/start-calculation`,
  STARTASSESSMENT: `/life-cycle-assessment/start-assessment`,
  EDITDRAFT: `/life-cycle-assessment/edit-draft`,
  VIEWRESULT: `/life-cycle-assessment/view-result`,
  ADDRESULT: `/life-cycle-assessment/add-result`,
};
export default PATH;
