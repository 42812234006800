import { Box, IconButton, ListItemText, Menu, MenuItem, Snackbar, Typography } from '@mui/material';
import AlertDialog from '../utils/AlertDialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { decryptId } from '../utils/encryptDecrypt';
import { fetchChainById } from '../../features/ChainByIdSlice';
import { useParams } from 'react-router-dom';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

function Products({ products, handleSelectSupplier, setSelectedNodeId, setProductId, setSupplierId, supplier, selectedNodeId, chainId, currentPage, supplierChainID }) {
  const [anchorProductEl, setAnchorProductEl] = React.useState(null);
  const open = Boolean(anchorProductEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const handleDeleteProduct = async () => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.delete(`${RestUrlsConstants.supplyChain}/${chainId}/node/${selectedNodeId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        dispatch(fetchChainById(supplierChainID || decryptId(params.id)));
        setOpenDialog(false);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  function handleProductClick(event, nodeId, id) {
    setAnchorProductEl(event.currentTarget);
    setProductId(id);
    setSupplierId(supplier?.supplierId);
    setSelectedNodeId(nodeId);
  }

  const handleProdutClose = (option) => {
    if (option === t('supplier_')) {
      handleSelectSupplier();
    } else if (option === t('delete')) {
      setOpenDialog(true);
    }
    setAnchorProductEl(null);
  };
  const handleMouseLeave = () => {
    setAnchorProductEl(null);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const options = [t('supplier_'), t('delete')];
  return (
    <Box
      sx={{
        borderRadius: 16,
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', background: '#fff', justifyContent: 'space-between', alignItems: 'center', py: 1, px: 2, borderRadius: 10, gap: '3px' }}>
        <Typography sx={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center' }}>
          {products?.titleVanityName !== 'undefined' ? products?.titleVanityName : products?.title} <br />
          <span style={{ color: 'gray', fontSize: '10px' }}>{ products?.stageVanityName?.value?.length  && products?.stageVanityName?.value !== 'undefined' ? products?.stageVanityName?.value : products?.stage}</span>
        </Typography>
        <IconButton size="small" onMouseEnter={(event) => handleProductClick(event, products._id, products?.supplierProductId)}>
          <MoreVertIcon sx={{ color: '#000', cursor: 'pointer' }} />
        </IconButton>
        <Menu
          open={open}
          anchorEl={anchorProductEl}
          onClose={handleMouseLeave}
          id=""
          MenuListProps={{
            onMouseLeave: handleMouseLeave,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleProdutClose(option)}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_supplier')}
        description={t('are_you_sure_you_want_to_delete_this_chain_product')}
        open={openDialog}
        handleClick={handleDeleteProduct}
        handleCloseDialog={handleCloseDialog}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
}
export default Products;
