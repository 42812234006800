import React, { useEffect, useState } from 'react';
import { Dialog, IconButton, Stack, Typography, Box, Paper, TextField, Chip, Select, MenuItem, Button, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import BG_CO2 from '../../../images/bg-co2.png';
import { lca_indicators_data } from '../../../data';
import { useNavigate } from 'react-router-dom';
import { RestUrlsConstants } from '../../../constants/rest-urls.constants';
import httpService from '../../../service/http.service';
import PATH from '../../utils/path';
import useCheckOnlineStatus from '../../../hooks/useOnlineCheckStatus';

// Styled Components
const StyledList = styled(Paper)(({ theme, isBgColor }) => ({
  backgroundColor: isBgColor,
  marginTop: theme.spacing(2.5),
  padding: theme.spacing(1.5, 2.5),
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '700',
  color: '#ffffff',
}));

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  padding: '12px 18px',
  backgroundColor: '#ffffff',
  borderRadius: theme.spacing(1),
}));

const StyledNextBtn = styled(Button)(({}) => ({
  backgroundColor: '#0E1090',
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: '#0E1090' },
}));

const chipIndicatorColors = {
  Impact: '#FF5722',
  Inventory: '#808080',
};

const HeaderRow = () => (
  <StyledList isBgColor="#0E1090">
    <Box width="40%">
      <StyledText>Type</StyledText>
    </Box>
    <Box width="20%" alignItems="center" display="flex">
      <StyledText>Quantity</StyledText>
    </Box>
    <Box width="15%">
      <StyledText>Unit</StyledText>
    </Box>
    <Box width="15%" display="flex" justifyContent="center">
      <StyledText>Indicator</StyledText>
    </Box>
  </StyledList>
);

const DataRow = ({ type, unit, indicators, quantity, onQuantityChange, onUnitChange, selectedUnit }) => {
  const handleInputChange = (e) => {
    const value = e.target.value;

    if (unit === 'Days' || unit === 'Hours') {
      const validPattern = /^\d{1,10}$/;
      if (value === '' || (validPattern.test(value) && parseInt(value, 10) <= 9999999)) {
        onQuantityChange(value);
      }
    } else {
      const validPattern = /^(?:\d{1,10}(\.\d{0,10})?)?$/;
      if (value === '' || validPattern.test(value)) {
        onQuantityChange(value);
      }
    }
  };

  const handleUnitChange = (e) => {
    const selectedUnit = e.target.value;
    onUnitChange(selectedUnit);
  };

  return (
    <StyledRow>
      <Box width="40%" display="flex" alignItems="center" gap={1}>
        <img src={BG_CO2} alt={type} height={32} width={32} />
        <Typography fontSize={12} textTransform="uppercase">
          {type}
        </Typography>
      </Box>
      <TextField
        variant="standard"
        type="text"
        value={quantity}
        onChange={handleInputChange}
        sx={{
          width: '20%',
          backgroundColor: 'none',
        }}
      />
      <Box width="15%">
        {Array.isArray(unit) && unit.length > 1 ? (
          <Select
            size="small"
            value={selectedUnit}
            onChange={handleUnitChange}
            sx={{
              fontSize: 12,
              fontWeight: '700',
            }}
          >
            {unit.map((u, idx) => (
              <MenuItem key={idx} value={u}>
                {u}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography fontSize={14} fontWeight="700">
            {unit}
          </Typography>
        )}
      </Box>
      <Box width="15%" display="flex" justifyContent="center">
        <Chip
          label={indicators}
          size="small"
          sx={{
            backgroundColor: chipIndicatorColors[indicators],
            color: '#fff',
          }}
        />
      </Box>
    </StyledRow>
  );
};

const AddResultModal = ({ open, onClose, productIds, assessmentName }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const navigate = useNavigate();

  useEffect(() => {
    if (lca_indicators_data) {
      setResults(
        lca_indicators_data.map((ele, index) => ({
          ...ele,
          quantity: '',
          id: index,
          selectedUnit: Array.isArray(ele.unit) && ele.unit.length > 0 ? ele.unit[0] : ele.unit,
        }))
      );
    }
  }, [lca_indicators_data]);

  const onSave = async () => {
    if (!checkOnlineStatus()) return;
    const result = results
      .filter((ele) => ele.quantity)
      .map((ele) => ({
        type: ele.type.replace(/\s+/g, ''),
        quantity: Number(ele.quantity),
        unit: ele.selectedUnit,
        indicator: ele.indicators.toUpperCase(),
      }));

    let obj = {
      name: assessmentName,
      productIds: productIds,
      result: result,
    };

    try {
      const response = await httpService.post(RestUrlsConstants.LCA, obj, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        navigate(`${PATH.LIFECYCLEASSESSMENT}`);
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const handleQuantityChange = (uniqueId, value) => {
    setResults((prevResults) => prevResults.map((item, i) => (item.id === uniqueId ? { ...item, quantity: value } : item)));
  };

  const handleUnitChange = (uniqueId, selectedUnit) => {
    setResults((prevResults) => prevResults.map((item, i) => (item.id === uniqueId ? { ...item, selectedUnit: selectedUnit } : item)));
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { padding: '20px' } }} fullWidth maxWidth="md" aria-labelledby="add-results-dialog">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={700} fontSize={24} id="add-results-dialog">
          {t('add_results')}
        </Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ color: 'black' }}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder={t('search')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{
            borderRadius: '20px',
            '& .MuiOutlinedInput-root': { borderRadius: '20px' },
          }}
        />
      </Box>

      <Box height="500px" overflow="auto" mt={2}>
        <HeaderRow />
        <Stack mt={1}>
          {results
            .filter((row) => row.type.toLowerCase().includes(searchValue.toLowerCase()))
            .map((row, index) => (
              <DataRow
                key={index}
                type={row.type}
                unit={row.unit}
                selectedUnit={row.selectedUnit}
                indicators={row.indicators}
                quantity={row.quantity}
                onQuantityChange={(value) => handleQuantityChange(row.id, value)}
                onUnitChange={(selectedUnit) => handleUnitChange(row.id, selectedUnit)}
              />
            ))}
          {lca_indicators_data.filter((row) => row.type.toLowerCase().includes(searchValue.toLowerCase())).length === 0 && (
            <Typography align="center" fontSize={20} color="textSecondary" mt={2}>
              No results found
            </Typography>
          )}
        </Stack>
      </Box>

      <Stack mt={2} alignItems="flex-end" width="100%">
        <StyledNextBtn onClick={onSave} variant="contained">
          {t('save')}
        </StyledNextBtn>
      </Stack>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Dialog>
  );
};

export default AddResultModal;
