import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Chip,
  Tooltip,
  Link,
  Button,
  Snackbar,
  IconButton,
  TextField,
  InputAdornment,
  InputLabel,
  DialogContentText,
  Pagination,
  CircularProgress,
  styled,
  tooltipClasses,
} from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import CommonSwitch from '../utils/CommonSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../utils/loader';
import { useNavigate } from 'react-router-dom';
import PATH from '../utils/path';
import { CategoryStatus, RolesStatus, CertificateStatus } from '../../constants/common.constants';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import { fetchCertificate, searchCertificate, sortCertificate } from '../../features/certificateProofPointSlice';
import { CommonButton } from '../utils/CommonButton';
import CommonSelect from '../utils/CommonSelect';
import CommonInput from '../utils/CommonInput';
import CommonDialog from '../utils/CommonDialog';
import EditIcon from '@mui/icons-material/Edit';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const ClaimCertification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const itemPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const user = useSelector((store) => store.user);
  const { certificate, loading } = useSelector((state) => state.certificate);
  const userId = user?.id;
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [modelFormData, setModelFormData] = useState({
    attachment: null,
    name: '',
    documentType: '',
    emittedBy: '',
    emissionDate: '',
    assignedTo: '',
    expirationDate: '',
    certificateOwner: '',
  });
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [certificateID, setCertificateID] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [switchStates, setSwitchStates] = useState({});
  const [switchStatesVisibility, setSwitchStatesVisibility] = useState({});

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      border: '1px solid #dadde9',
    },
  }));

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = certificate?.certificates?.map((cert) => cert?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  useEffect(() => {
    dispatch(fetchCertificate({ page, itemPerPage }));
  }, [dispatch, page]);

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleCertificateDownload = async () => {
    if (!checkOnlineStatus()) return;
    const payload = {
      certificateId: selected,
    };
    try {
      const response = await httpService.post(`${RestUrlsConstants.certificateDownload}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/zip' });
      saveAs(blob, 'documents.zip');
      dispatch(fetchCertificate({ page, itemPerPage }));
      setSelected([]);
    } catch (error) {
      console.log(error);
      error?.response?.status === (500 || 502 || 503) && setSnackBar({ open: true, message: 'Something went wrong ! Please try again.' });
    }
  };

  const handleVerifiedCertificate = async (id, isExpiredNotification) => {
    const notification = !isExpiredNotification;
    const payload = {
      isExpiredNotificationEnabled: notification,
    };
    try {
      if (!checkOnlineStatus()) return;
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setSwitchStates((prev) => ({
          ...prev,
          [id]: notification,
        }));
        // dispatch(fetchCertificate({ page, itemPerPage }));
        setSnackBar({ open: true, message: response?.data?.message });
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleCertificateApproval = async (id) => {
    if (!checkOnlineStatus()) return;
    const payload = {
      verificationStatus: 'VALID',
    };
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        dispatch(fetchCertificate({ page, itemPerPage }));
        setSnackBar({ open: true, message: response?.data?.message });
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handlePublicPrivateCertificate = async (id, privacy) => {
    if (!checkOnlineStatus()) return;
    const public_private = !privacy;
    const payload = {
      public: public_private,
    };
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setSwitchStatesVisibility((prev) => ({
          ...prev,
          [id]: public_private,
        }));
        // dispatch(fetchCertificate({ page, itemPerPage }));
        setSnackBar({ open: true, message: response?.data?.message });
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const totalPages = Math.ceil(certificate?.totalRecords / itemPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return ['pdf', 'doc', 'docx', 'csv', 'ppt', 'pptx', 'txt', 'xlsx', 'mp4', 'mov'].includes(extension) ? extension : '';
    }
    return '';
  }

  const handleSearchChange = (e) => {
    if (!checkOnlineStatus()) return;
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      dispatch(fetchCertificate({ page, itemPerPage, userId }));
    } else {
      dispatch(searchCertificate({ term, userId }));
    }
  };

  const handleReplaceFile = (val) => {
    if (!checkOnlineStatus()) return;
    setOpenDialog(true);
    setImageUrl(val?.urls[0]);
    setCertificateID(val?.id);
    setModelFormData({
      // attachment: val?.urls[0],
      name: val?.name,
      documentType: val?.documentType,
      emittedBy: val?.emittedBy,
      assignedTo: val?.assignedTo,
      emissionDate: val?.emissionDate?.slice(0, 10),
      expirationDate: val?.expirationDate?.slice(0, 10),
      certificateOwner: val?.certificateOwner,
    });
    setUpdateDocument(true);
  };
  const handleCreate = async () => {
    const newErrors = {};
    if (!updateDocument) {
      if (!modelFormData.attachment) newErrors.attachment = t('attachment_is_required');
    }
    if (!modelFormData.name) newErrors.name = t('name_is_required');
    if (!modelFormData.documentType) newErrors.documentType = t('document_type_is_required');
    if (!modelFormData.emittedBy) newErrors.emittedBy = t('emitted_by_is_required');
    if (!modelFormData.certificateOwner) newErrors.certificateOwner = t('certificate_owner_is_required');

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = new FormData();
      if (modelFormData.attachment) {
        formData.append('documents', modelFormData.attachment);
      }
      if (!updateDocument) {
        Object.keys(modelFormData).forEach((key) => {
          const value = modelFormData[key];
          if (value !== null && typeof value === 'object' && key !== 'attachment') {
            Object.keys(value).forEach((subKey) => {
              formData.append(`documents[${key}][${subKey}]`, value[subKey]);
            });
          } else if (key !== 'attachment') {
            formData.append(`documents[${key}]`, value);
          }
        });
      } else {
        if (modelFormData.name) {
          formData.append('name', modelFormData.name);
        }
        if (modelFormData.documentType) {
          formData.append('documentType', modelFormData.documentType);
        }
        if (modelFormData.emittedBy) {
          formData.append('emittedBy', modelFormData.emittedBy);
        }
        if (modelFormData.emissionDate) {
          formData.append('emissionDate', modelFormData.emissionDate);
        }
       /*  if (modelFormData?.assignedTo) {
          formData.append('assignedTo', modelFormData.assignedTo);
        } */
        if (modelFormData.expirationDate) {
          formData.append('expirationDate', modelFormData.expirationDate);
        }
        if (modelFormData.certificateOwner) {
          formData.append('certificateOwner', modelFormData.certificateOwner);
        }
      }
      handleUpdateDocument(formData);
      // setOpenDialog(false);
      setModelFormData({});
      setImageUrl('');
      setCertificateID(null);
      setUpdateDocument(false);
    }
  };
  const handleUpdateDocument = async (formData) => {
    try {
      if (!checkOnlineStatus()) return;
      setLoadingBtn(true);
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${certificateID}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setOpenDialog(false);
        setLoadingBtn(false);
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchCertificate({ page, itemPerPage }));
        setCertificateID(null);
        setUpdateDocument(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingBtn(false);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const handleInputChange = (event, name) => {
    const { value, type } = event.target;
    setErrors({
      ...errors,
      [name]: '',
    });
    if (type === 'file') {
      setModelFormData({
        ...modelFormData,
        [name]: event.target.files[0],
      });
    } else {
      setModelFormData({
        ...modelFormData,
        [name]: value,
      });
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const invalidFormats = ['.avi', '.mkv', '.flv', '.wmv', '.webm'];
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (invalidFormats.includes(fileExtension)) {
        setErrors({
          ...errors,
          attachment: 'Document format is invalid.',
        });
        setModelFormData((prevState) => ({
          ...prevState,
          attachment: null,
        }));
        setImageUrl(null);
        return;
      }
      setErrors({
        ...errors,
        attachment: '',
      });
      setModelFormData((prevState) => ({
        ...prevState,
        attachment: file,
      }));
      setImageUrl(URL.createObjectURL(file));
    }
  };
  const closeDialog = () => {
    setOpenDialog(false);
    setImageUrl('');
    setErrors({});
    setModelFormData({});
    setCertificateID(null);
    setUpdateDocument(false);
  };

  const [sortField, setSortField] = useState('');
  const [isAscending, setIsAscending] = useState(true);
  const handleSort = (field) => {
    const isSameField = sortField === field;
    const newSortDirection = isSameField ? !isAscending : true;
    setSortField(field);
    setIsAscending(newSortDirection);
    dispatch(
      sortCertificate({
        page,
        itemPerPage,
        userId,
        sort: field,
        orderBy: newSortDirection ? 'asc' : 'desc',
      })
    );
  };

  return (
    <>
      <Box>
        <Button
          startIcon={<IoMdArrowBack />}
          onClick={() => navigate(PATH.HOME)}
          sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2, mb: 3 }}
        >
          {t('back')}
        </Button>
        <Card sx={{ borderRadius: '20px', boxShadow: 'none' }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{t('certifications_and_proof_points_uploaded')}</Typography>
                {selected.length > 0 && (
                  <Tooltip title={t('download')} placement="top" arrow>
                    <Button variant="contained" color="success" size="small" sx={{ textTransform: 'capitalize', borderRadius: '10px' }} onClick={handleCertificateDownload}>
                      {t('download')}
                    </Button>
                  </Tooltip>
                )}
              </Box>
              <TextField
                size="small"
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <TableContainer>
                <Table sx={{ cursor: 'pointer' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={selected.length > 0 && selected.length < certificate?.currentRecords}
                          checked={certificate?.currentRecords > 0 && selected.length === certificate?.currentRecords}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('preview')}</TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('name')}</TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} width={'15%'}>
                        {t('date_of_emission')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} width={'15%'}>
                        {t('assigned_to')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} width={'15%'}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} onClick={() => handleSort('date')}>
                          {t('upload_date')}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              margin: 0,
                              padding: 0,
                              gap: '0px',
                            }}
                          >
                            <ArrowDropUpIcon
                              fontSize="large"
                              sx={{
                                marginBottom: '-12px',
                                color: sortField === 'date' && isAscending ? 'primary.main' : 'text.disabled',
                              }}
                            />
                            <ArrowDropDownIcon
                              fontSize="large"
                              sx={{
                                marginTop: '-12px',
                                color: sortField === 'date' && !isAscending ? 'primary.main' : 'text.disabled',
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} width={'15%'}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} onClick={() => handleSort('expirationDate')}>
                          {t('expiration_date')}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              margin: 0,
                              padding: 0,
                              gap: '0px',
                            }}
                          >
                            <ArrowDropUpIcon
                              fontSize="large"
                              sx={{
                                marginBottom: '-12px',
                                color: sortField === 'expirationDate' && isAscending ? 'primary.main' : 'text.disabled',
                              }}
                            />
                            <ArrowDropDownIcon
                              fontSize="large"
                              sx={{
                                marginTop: '-12px',
                                color: sortField === 'expirationDate' && !isAscending ? 'primary.main' : 'text.disabled',
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                        {t('alerts')} <br /> <span style={{ fontSize: '10px', color: 'gray' }}>{t('thirty_days_before_expiration_date')}</span>
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                        {t('status')} <br /> <span style={{ fontSize: '10px', color: 'gray' }}>{t('form_issuing_today')}</span>
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('verified_date')}</TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('visibility')}</TableCell>
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('edit')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {certificate?.currentRecords < 1 ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Typography py={2} textAlign={'center'}>
                                {t('no_certificate_found')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {certificate?.certificates?.map((val) => {
                              const isItemSelected = isSelected(val.id);
                              return (
                                <TableRow sx={{ ':hover': { background: '#f5f5f5' } }} key={val.id} role="checkbox" aria-checked={isItemSelected} selected={isItemSelected}>
                                  <TableCell onClick={() => handleClick(val?.id)} padding="checkbox" sx={{ border: 'none' }}>
                                    <Checkbox checked={isItemSelected} />
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                    <>
                                      {(val?.urls[0]?.includes('.pdf') ||
                                        val?.urls[0]?.includes('.doc') ||
                                        val?.urls[0]?.includes('.docx') ||
                                        val?.urls[0]?.includes('.csv') ||
                                        val?.urls[0]?.includes('.txt') ||
                                        val?.urls[0]?.includes('.xlsx') ||
                                        val?.urls[0]?.includes('.ppt') ||
                                        val?.urls[0]?.includes('.pptx')) && (
                                        <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                          <Box
                                            sx={{
                                              height: '90px',
                                              width: '80px',
                                              border: '2px solid lightgray',
                                              bgcolor: '#fff',
                                              textAlign: 'justify',
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              position: 'relative',
                                            }}
                                          >
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                              {val?.preview ? (
                                                <img height={120} width={100} alt="preview-img" src={val?.preview} />
                                              ) : (
                                                <>
                                                  <ArticleIcon style={{ fontSize: '50px' }} />
                                                  <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(val?.urls[0])}</span>
                                                </>
                                              )}
                                            </Box>
                                          </Box>
                                        </CustomTooltip>
                                      )}
                                      {(val?.urls[0]?.includes('.png') || val?.urls[0]?.includes('.jpeg') || val?.urls[0]?.includes('.jpg')) && (
                                        <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                          <img src={val?.urls} alt="" height={'90px'} width={'80px'} style={{ border: '2px solid lightgray ' }} />
                                        </CustomTooltip>
                                      )}

                                      {(val?.urls[0]?.includes('.mp4') || val?.urls[0]?.includes('.mov')) && (
                                        <CustomTooltip title={t(val?.name)} placement="top" arrow>
                                          <Box sx={{ position: 'relative', width: '80px', height: '90px', border: '2px solid lightgray' }}>
                                            <video src={val?.urls} alt="" height={'90px'} width={'80px'} style={{ border: '2px solid lightgray', objectFit: 'cover' }} muted />
                                            <PlayCircleOutlineIcon
                                              style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '30px',
                                                color: 'white',
                                                opacity: 0.8,
                                              }}
                                            />
                                          </Box>
                                        </CustomTooltip>
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                    <Tooltip
                                      title={
                                        <Link className="certificateDownload" href={val?.urls} sx={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '12px' }}>
                                          <CloudDownloadIcon />
                                          {t('download')}
                                        </Link>
                                      }
                                      placement="top-start"
                                      arrow
                                    >
                                      {val?.name}
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.emissionDate ? moment(val?.emissionDate).format('DD/MM/YYYY') : ''}</TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.assignedTo ?? ""}</TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.createdAt ? moment(val?.createdAt).format('DD/MM/YYYY') : ''}</TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.expirationDate ? moment(val?.expirationDate).format('DD/MM/YYYY') : ''}</TableCell>
                                  <TableCell sx={{ border: 'none' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '12px' }}>
                                      <CommonSwitch
                                        checkValue={switchStates[val.id] ?? val.isExpiredNotificationEnabled}
                                        onChange={() => handleVerifiedCertificate(val.id, switchStates[val.id] ?? val.isExpiredNotificationEnabled)}
                                      />
                                      {/* {switchStates[val.id] ?? val.isExpiredNotificationEnabled ? 'ON' : 'OFF'} */}
                                    </Box>
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                    {user?.userType === RolesStatus.client ? (
                                      <Chip
                                        size="small"
                                        label={
                                          <div>
                                            {val?.verificationStatus === CertificateStatus.valid && <CheckCircleIcon sx={{ fontSize: '16px', color: '#fff', mr: 1 }} />}
                                            {val?.verificationStatus}
                                          </div>
                                        }
                                        sx={{
                                          fontSize: '10px',
                                          bgcolor: val?.verificationStatus === CertificateStatus.valid ? '#28a745' : val?.verificationStatus === CategoryStatus.pending ? '#0e1090' : '#c82333',
                                          color: '#fff',
                                        }}
                                      />
                                    ) : (
                                      <>
                                        {val?.verificationStatus === CertificateStatus.valid ? (
                                          <Chip
                                            size="small"
                                            label={
                                              <div>
                                                {val?.verificationStatus === CertificateStatus.valid && <CheckCircleIcon sx={{ fontSize: '16px', color: '#fff', mr: 1 }} />}
                                                {val?.verificationStatus}
                                              </div>
                                            }
                                            sx={{
                                              fontSize: '10px',
                                              bgcolor: val?.verificationStatus === CertificateStatus.valid ? '#28a745' : val?.verificationStatus === CategoryStatus.pending ? '#0e1090' : '#c82333',
                                              color: '#fff',
                                            }}
                                          />
                                        ) : (
                                          <Button
                                            disabled={val?.verificationStatus === CertificateStatus.valid}
                                            size="small"
                                            variant="outlined"
                                            sx={{ textTransform: 'capitalize', borderRadius: '10px' }}
                                            onClick={() => handleCertificateApproval(val?.id)}
                                          >
                                            {t('pending')}
                                          </Button>
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                    {val?.verificationStatus === CertificateStatus.valid ? moment(val?.verifiedOn).format('DD/MM/YYYY') : 'Not Verified'}
                                  </TableCell>
                                  <TableCell sx={{ border: 'none' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '12px' }}>
                                      <CommonSwitch
                                        checkValue={switchStatesVisibility[val.id] ?? val.public}
                                        onChange={() => handlePublicPrivateCertificate(val.id, switchStatesVisibility[val.id] ?? val.public)}
                                      />
                                      {switchStatesVisibility[val.id] ?? val.public ? 'Public' : 'Private'}
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <FontAwesomeIcon style={{ color: '#c82333' }} icon={faPenToSquare} onClick={() => handleReplaceFile(val)} />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  {certificate?.totalRecords > 0 && (
                    <Pagination
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: 'gray',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                          backgroundColor: '#EC895A',
                          color: '#fff',
                        },
                      }}
                      count={totalPages}
                      page={page}
                      onChange={handleChange}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <CommonDialog
        heading={
          <span>
            {t('certificate_details')} <IoIosInformationCircleOutline />
          </span>
        }
        open={openDialog}
        handleClose={closeDialog}
      >
        <DialogContentText sx={{ width: '500px' }}>
          <form>
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('attachment') + '*'}</InputLabel>
            <input ref={fileInputRef} required multiple type="file" hidden onChange={handleFileInput} />
            <Button
              sx={{
                height: '200px',
                width: '300px',
                color: '#000',
                background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#EFEFEF',
                ':hover': { background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#ededed' },
              }}
              variant="contained"
              onClick={() => fileInputRef.current.click()}
            >
              {!imageUrl && <EditIcon />}
              {((imageUrl?.includes('.pdf') ||
                imageUrl?.includes('.doc') ||
                imageUrl?.includes('.docx') ||
                imageUrl?.includes('.csv') ||
                imageUrl?.includes('.txt') ||
                imageUrl?.includes('.pptx') ||
                imageUrl?.includes('.ppt') ||
                imageUrl?.includes('.xlsx')) &&
                getFileExtension(imageUrl)) ||
                imageUrl?.includes('.mp4') ||
                imageUrl?.includes('.mov')}
              {!updateDocument && <Typography sx={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{imageUrl}</Typography>}
            </Button>
            {errors.attachment && (
              <Typography color="error" fontSize={'14px'} mt={1}>
                {errors.attachment}
              </Typography>
            )}
            <CommonInput
              label={t('insert_name') + '*'}
              placeholder={t('insert_name')}
              type="text"
              variant="standard"
              name="name"
              value={modelFormData.name}
              handleChange={(e) => handleInputChange(e, 'name')}
            />
            {errors.name && (
              <Typography color="error" fontSize={'12px'}>
                {errors.name}
              </Typography>
            )}
            <CommonSelect
              options={['Certification', 'Proof point']}
              name="documentType"
              label={t('select_type') + '*'}
              value={modelFormData.documentType}
              handleChange={(e) => handleInputChange(e, 'documentType')}
            />
            {errors.documentType && (
              <Typography color="error" fontSize={'12px'}>
                {errors.documentType}
              </Typography>
            )}

            <CommonInput
              label={t('emitted_by') + '*'}
              required={true}
              placeholder={t('emitted_by')}
              type="text"
              variant="standard"
              name="emittedBy"
              value={modelFormData.emittedBy}
              handleChange={(e) => handleInputChange(e, 'emittedBy')}
            />
            {errors.emittedBy && (
              <Typography color="error" fontSize={'12px'}>
                {errors.emittedBy}
              </Typography>
            )}
            <CommonInput
              required={true}
              label={t('insert_date_of_emission')}
              type="date"
              variant="standard"
              name="emissionDate"
              value={modelFormData.emissionDate}
              handleChange={(e) => handleInputChange(e, 'emissionDate')}
            />
           {/*  <CommonInput
              label={t('assigned_to')}
              type="text"
              placeholder={t('assigned_to')}
              variant="standard"
              name="assignedTo"
              value={modelFormData.assignedTo}
              handleChange={(e) => handleInputChange(e, 'assignedTo')}
            /> */}

            <CommonInput
              label={t('insert_expiration_date')}
              type="date"
              variant="standard"
              name="expirationDate"
              value={modelFormData.expirationDate}
              handleChange={(e) => handleInputChange(e, 'expirationDate')}
            />

            <CommonSelect
              options={['Supplier', 'Brand']}
              name="certificateOwner"
              label={t('select_certificate_owner') + '*'}
              value={modelFormData.certificateOwner}
              handleChange={(e) => handleInputChange(e, 'certificateOwner')}
            />
            {errors.certificateOwner && (
              <Typography color="error" fontSize={'12px'}>
                {errors.certificateOwner}
              </Typography>
            )}
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              <CommonButton
                buttonName={
                  loadingBtn ? (
                    <>
                      <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                      {t('updating') + '...'}
                    </>
                  ) : (
                    t('save')
                  )
                }
                handleClick={handleCreate}
              />
            </Box>
          </form>
        </DialogContentText>
      </CommonDialog>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default ClaimCertification;
