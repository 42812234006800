import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel, ListItemIcon } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { Stages } from './CommonList';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch } from 'react-redux';
import {
  fetchSupplierProduct,
  fetchSupplierProductWithSort,
  fetchSupplierProductWithStage,
  fetchSupplierProductWithSupplyChain,
  fetchSupplierProductWithType,
} from '../../features/supplierProductSlice';

export default function FilterSupplierProduct(props) {
  const { supplierId, showFilter } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSupplyChain('');
    setStage('');
    setSort('');
    setAnchorEl(null);
  };
  const [supplyChain, setSupplyChain] = React.useState('');
  const [stage, setStage] = React.useState('');
  const [sort, setSort] = React.useState('');
  const [productType, setProductType] = React.useState('');
  const dispatch = useDispatch();
  const handleSupplyChainChange = (event) => {
    setSupplyChain(event.target.value);
    let chain = event.target.value;
    dispatch(fetchSupplierProductWithSupplyChain({ supplierId, chain }));
    setAnchorEl(null);
  };

  const handleStageChange = (event) => {
    setStage(event.target.value);
    let stage = event.target.value;
    dispatch(fetchSupplierProductWithStage({ supplierId, stage }));
    setAnchorEl(null);
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
    let type = event.target.value;
    dispatch(fetchSupplierProductWithType({ supplierId, type }));
    setAnchorEl(null);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    let sort = event.target.value;
    dispatch(fetchSupplierProductWithSort({ supplierId, sort }));
    setAnchorEl(null);
  };

  const handleClearAll = () => {
    dispatch(fetchSupplierProduct({ supplierId, page: 1 }));
    setSupplyChain('');
    setProductType('');
    setStage('');
    setSort('');
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button disabled={!supplierId} onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '50px', ':hover': { bgcolor: '#0e1090' }, height: '40px', width: '40px' }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {showFilter ? t('clear_all') : t('clear')}
            </Button>
          </Box>
          {showFilter && (
            <>
              <InputLabel sx={{ fontSize: '14px' }}>{t('is_in_a_supply_chain')}</InputLabel>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select
                  variant="standard"
                  size="small"
                  value={supplyChain}
                  onChange={handleSupplyChainChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemText primary={selected} />
                      <DoneIcon sx={{ color: '#0e1090', marginLeft: '8px' }} />
                    </Box>
                  )}
                >
                  {[t('yes'), t('no')].map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemText primary={option} />
                      {supplyChain === option && <DoneIcon sx={{ color: '#0e1090' }} />}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          <InputLabel sx={{ fontSize: '14px' }}>{t('stage')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={stage} onChange={handleStageChange}>
              {Stages.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showFilter && (
            <>
              <InputLabel sx={{ fontSize: '14px' }}>{t('type')}</InputLabel>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select variant="standard" size="small" value={productType} onChange={handleProductTypeChange}>
                  {[t('PRODUCT'), t('SERVICE')].map((option) => (
                    <MenuItem key={option} value={option} sx={{ fontSize: '12px' }}>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {showFilter && (
            <>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {t('sort_by')} <SortIcon />
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select variant="standard" size="small" value={sort} onChange={handleSortChange}>
                  {[t('Title'), t('Stage')].map((sortOption) => (
                    <MenuItem key={sortOption} value={sortOption}>
                      {sortOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}
