import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
//import icons
import CloseIcon from '@mui/icons-material/Close';
import DEFAULT_IMAGE from '../../../../images/default_product_image.png';
//import images
import BG_CO2 from '../../../../images/bg-co2.png';
import CO2_CUP from '../../../../images/co2-cup.png';
//relative path imports
import { StyledList, StyledText } from '../../AssessmentDashboard';
import { elements_card } from '../../../../data';
import httpService from '../../../../service/http.service';
import { RestUrlsConstants } from '../../../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../../../hooks/useOnlineCheckStatus';
import { CircularProgress, Snackbar } from '@mui/material';

//create styled components
const StyledProductCard = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  width: '100%',
  flexDirection: 'column',
  minHeight: '100%',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  backgroundColor: '#EFEFEF',
  minHeight: '100%',
}));

const chip_indicator = {
  IMPACT: '#FF5722',
  INVENTORY: '#808080',
};

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  display: 'inline',
  lineHeight: '14px',
}));

const ProductResultsModal = ({ open, onClose, completeLcaId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [productResult, setProductResult] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  useEffect(() => {
    if (completeLcaId) {
      fetchSingleLCA(completeLcaId);
    }
  }, [completeLcaId]);

  const fetchSingleLCA = async (lcaID) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.LCA}/${lcaID}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        const productID = response?.data?.data?.productId;
        setData(response?.data?.data);
        if (productID) {
          fetchSingleProduct(productID);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSingleProduct = async (productID) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/${productID}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setProductInfo(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const formatQuantity = (quantity) => {
    const [integerPart, decimalPart] = quantity.toString().split('.');
    const formattedInteger = integerPart.slice(0, 5);
    const formattedDecimal = decimalPart ? decimalPart.slice(0, 5) : '';
    return formattedDecimal ? `${formattedInteger}.${formattedDecimal}` : formattedInteger;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Paper sx={{ p: 4 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography fontWeight={'700'} fontSize={24}>
            Product Results
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box mt={2}>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <StyledProductCard>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <img src={productInfo?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={140} height={140} style={{ objectFit: 'cover' }} />
                </Box>
                <Stack width={'100%'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                  {data?.name && (
                    <Typography mt={1} fontSize={14} color={'#0E1090'} fontWeight={'700'}>
                      {data?.name}
                    </Typography>
                  )}
                  {productInfo?.title ? (
                    <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography fontSize={12} mt={1} fontWeight={'700'}>
                        {typeof productInfo?.title === 'object' ? productInfo?.title?.value : productInfo?.title}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography fontSize={12} mt={1} fontWeight={'700'}>
                        N/A
                      </Typography>
                    </Box>
                  )}
                  <Stack mt={1}>
                    {productInfo?.weight?.value || productInfo?.weight?.unit ? (
                      <StyledProductDescText>
                        {t('weight')}&nbsp;:&nbsp;
                        <Typography fontSize={12} component="span" fontWeight={'700'}>
                          {productInfo?.weight?.value || 'N/A'}&nbsp;{productInfo?.weight?.unit || ''}
                        </Typography>
                      </StyledProductDescText>
                    ) : (
                      <StyledProductDescText>
                        {t('weight')}&nbsp;:&nbsp;
                        <Typography fontSize={12} component="span" fontWeight={'700'}>
                          N/A
                        </Typography>
                      </StyledProductDescText>
                    )}
                    <StyledProductDescText>
                      {t('category')}&nbsp;:&nbsp;{' '}
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.category || 'N/A'}
                      </Typography>
                    </StyledProductDescText>
                    <StyledProductDescText>
                      {t('composition')}&nbsp;:&nbsp;{' '}
                      <Typography fontSize={12} component="span" fontWeight="700">
                        {productInfo?.composition?.value
                          ? Array.isArray(productInfo?.composition?.value)
                            ? productInfo?.composition?.value?.map((ele) => ele.name).join(', ')
                            : productInfo?.composition?.value
                          : 'N/A'}
                      </Typography>
                    </StyledProductDescText>

                    <StyledProductDescText>
                      {t('sku_number')}&nbsp;:&nbsp;
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.qrId || 'N/A'}
                      </Typography>
                    </StyledProductDescText>

                    <StyledProductDescText>
                      {t('collection')}&nbsp;:&nbsp;
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.collectionName || 'N/A'}
                      </Typography>
                    </StyledProductDescText>
                  </Stack>
                </Stack>
              </StyledProductCard>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={6}>
                {data?.result?.slice(0, 4)?.map((ele, index) => {
                  return (
                    <Grid item key={index} xs={12} sm={12} md={6}>
                      <StyledPaper>
                        <Stack width={'100%'} alignItems={'flex-end'}>
                          <Chip label={ele?.indicator} size="small" sx={{ backgroundColor: chip_indicator[ele?.indicator], color: '#fff' }} />
                        </Stack>
                        <Stack width={'100%'} my={2} direction={'row'} spacing={4} justifyContent={'center'} alignItems={'center'}>
                          <Box>
                            <img src={CO2_CUP} alt="co2" height={58} width={58} />
                          </Box>
                          <Stack alignItems={'center'}>
                            <Typography variant="h5" fontWeight={'700'}>
                              <Typography fontWeight="bold">{formatQuantity(ele?.quantity)}</Typography>
                            </Typography>
                            <Typography textTransform={'uppercase'} fontSize={12} fontWeight={'700'}>
                              {ele?.unit}
                            </Typography>
                            <Typography
                              textTransform="uppercase"
                              variant="subtitle2"
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '120px', 
                              }}
                            >
                              {ele?.type || ele?.title}
                            </Typography>
                          </Stack>
                        </Stack>
                      </StyledPaper>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Box>
            <StyledList isbgColor={'#0E1090'}>
              <Box width={'40%'}>
                <StyledText>{t('lca_type')}</StyledText>
              </Box>
              <Box width={'25%'}>
                <StyledText>{t('qty')}</StyledText>
              </Box>
              <Box width={'20%'}>
                <StyledText>{t('unit')}</StyledText>
              </Box>
              <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                <StyledText>{t('indicator')}</StyledText>
              </Box>
            </StyledList>
            {loading ? (
              <Box mt={4} display={'flex'} justifyContent={'center'}>
                <CircularProgress size={18} />
              </Box>
            ) : (
              <>
                {data?.result?.slice(4)?.length > 0 ? (
                  <>
                    {data?.result?.slice(4)?.map((ele, index) => {
                      return (
                        <Stack key={index}>
                          <StyledList isbgColor={'#ffffff'}>
                            <Box width={'40%'} display={'flex'} gap={2} alignItems={'center'}>
                              <img src={BG_CO2} alt="co2" height={32} width={32} />
                              <Typography fontSize={12} textTransform={'uppercase'}>
                                {ele?.type}
                              </Typography>
                            </Box>
                            <Box width={'25%'}>
                              <Typography fontWeight="bold">{formatQuantity(ele?.quantity)}</Typography>
                            </Box>
                            <Box width={'20%'}>
                              <Typography textTransform={'uppercase'} fontSize={12} fontWeight={'bold'}>
                                {ele?.unit}
                              </Typography>
                            </Box>
                            <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                              <Chip label={ele?.indicator} size="small" sx={{ backgroundColor: chip_indicator[ele?.indicator], color: '#fff' }} />
                            </Box>
                          </StyledList>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Typography textAlign={'center'} mt={2} fontSize={20}>
                    No Data Found
                  </Typography>
                )}{' '}
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Dialog>
  );
};

export default ProductResultsModal;
