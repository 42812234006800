import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });

  const handleForgotPasswordClick = () => {
    setForgotPasswordClicked(true);
  };

  const 
  handleForgotPasswordSubmit = async () => {
    try {
      const response = await httpService.post(RestUrlsConstants.verifyEmailUrl, { email });
      if (response.status === 200 || response.status === 201) {
        setResetLinkSent(true);
        setIsValidEmail(true);
        setSnackBar({ open: true, message: 'Password reset link sent successfully.' });
      } else {
        setIsValidEmail(false);
        setSnackBar({ open: true, message: 'Failed to send password reset link.' });
      }
    } catch (error) {
      setIsValidEmail(false);
      setSnackBar({ open: true, message:error?.response?.data?.message || 'Oops! something went wrong.' });
    }
  };

  const handleClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <div className="w-100">
      <div className="m-top-6">
        <div className="w-100 center">
          <img src={require('../images/renoon_app_icon.png')} alt="panda" className="w-5 b-radius-20 m-50px" />
        </div>
        <div className="w-100 center">
          <div className="login-container">
            <div>
              <p className="login-text">{t('forget_your_password')}</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleForgotPasswordSubmit();
                }}
                className="p-hor-15"
              >
                <TextField placeholder="Email" id="outlined-basic" label="Email" variant="outlined" fullWidth required type="email" onChange={(e) => setEmail(e.target.value)} />
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    background: '#101010',
                    fontWeight: '1000',
                    marginTop: '10px',
                  }}
                  onClick={handleForgotPasswordSubmit}
                >
                  {t('submit')}
                </Button>
              </form>
            </div>
            <Snackbar
              open={snackBar.open}
              autoHideDuration={2000}
              message={snackBar.message}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleClose}
              className="snackBarColor"
              key="snackbar"
            />
            {resetLinkSent && (
              <div className="success-message" style={{marginTop:"10px"}}>
                <p>{t('your_password_reset_link_is_ready')}</p>
                <p>{t('just_check_your_email_for_reset_link')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
