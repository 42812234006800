const frenchTranslation = {
  home: 'Maison',
  user_management: 'Gestion des utilisateurs',
  user: 'Utilisateur',
  brand: 'Marque',
  role: 'Rôle',
  measures: 'Mesures',
  claim_management: 'Gestion des réclamations',
  order_engine: 'Gestion des commandes',
  product_engine: 'Gestion de produits',
  create_new_product: 'Créer un nouveau produit',
  supplier_map: 'Carte des fournisseurs',
  compliance: 'Conformité',
  over_view: 'Aperçu',
  product_compliance: 'Conformité du produit',
  reports: 'Rapports',
  reporting: 'Reporting',
  product_lca: 'Analyse du cycle de vie du produit',
  clustring: 'Regroupement',
  impact_label: "Étiquette d'impact",
  eCom_widget: 'Widget de commerce électronique',
  qr_codes: 'Codes QR',
  analytics: 'Analytique',
  settings: 'Paramètres',
  back: 'Retour',
  demo_account: 'Compte démo',
  your_product: 'Ton produit',
  retailer: 'Détaillante',
  status: 'Statut',
  approved: 'Approuvé',
  approve: 'Approuver',
  submit_for_review: 'Soumettre pour révision',
  submitted_for_review: 'Soumis à révision',
  verified: 'Vérifié',
  pending: 'En attente',
  percentile: 'centile',
  renoon_score: 'Score de Renoon',
  brand_name: 'Nom de la marque',
  contract_start_date: 'Date de début du contrat',
  contract_end_date: 'Date de fin du contrat',
  max_products: 'Produits max',
  max_users: "Utilisateurs max",
  features: 'Fonctionnalités',
  contract_files: 'Fichier de contrat',
  logo: 'Logo',
  actions: 'Actions',
  add_brand: 'Ajouter une marque',
  brands: 'MARQUES',
  page: 'Page',
  download: 'Télécharger',
  update_brand: 'METTRE À JOUR LA MARQUE',
  brand_onboarding_form: "FORMULAIRE D'INTÉGRATION DE MARQUE",
  update: 'Mise à jour',
  submit: 'Soumettre',
  cancel: 'Annuler',
  delete: 'Supprimer',
  duplicate: 'Double',
  delete_brand: 'Supprimer la marque',
  are_you_sure_you_want_to_delete_this_brand: 'Etes-vous sûr de vouloir supprimer cette marque ?',
  are_you_sure_you_want_to_delete_this_user: 'Etes-vous sûr de vouloir supprimer cette Utilisateur ?',
  brand_name_required: 'nom de la marque requis',
  contract_start_date_required: 'La date de début du contrat est requise',
  contract_end_date_required: 'La date de fin du contrat est requise',
  max_product_required: 'Le nombre maximal de produits est requis',
  max_user_required: "Le nombre maximal d'utilisateurs est requis",
  features_required: 'Des fonctionnalités sont requises',
  contract_files_required: 'Les fichiers de contrat sont requis',
  logo_required: 'Le logo est requis',
  add_role: 'Ajouter un rôle',
  role_name: 'Nom de rôle',
  resources: 'Ressources',
  loading: 'Chargement',
  roles: 'ROLES',
  edit: 'Modifier',
  update_role: 'Mettre à jour le rôle',
  create_role: 'Créer un rôle',
  role_form: 'Formulaire de rôle',
  add_users: 'Ajouter des utilisateurs',
  update_user: "Mettre à jour l'utilisateur",
  user_onboarding_form: "Formulaire d'intégration utilisateur",
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email_address: 'Adresse e-mail',
  password: 'Mot de passe',
  select_role: 'Sélectionner un rôle',
  select_brand: 'Sélectionner une marque',
  create_user_account: 'Créer un compte utilisateur',
  users: 'UTILISATEURS',
  email: 'E-mail',
  begin: 'Commencer',
  manage: 'Gérer',
  goals_targets: 'Objectifs et cibles',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Votre engagement continu envers la transparence se poursuit ici',
  goals_sets: "Ensembles d'objectifs",
  certifications_and_proof_points: 'Certifications et points de preuve',
  select_category: 'Sélectionner des revendications',
  suggested_category: 'Revendications suggérées',
  discovery_mode: 'Mode découverte',
  questions_answered: 'Questions répondues',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Sélectionnez les valeurs que vous souhaitez revendiquer pour agréger facilement vos données dans la section ci-dessous – mises à jour automatiquement.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'Consultez et répondez à autant de questions que possible pour découvrir les valeurs que vous pouvez revendiquer sur la base de vos données et de vos documents de preuve.',
  next: 'Suivant',
  prev: 'Précédent',
  uploaded: 'Téléchargé',
  you_have_set_this_as_a_goal: 'Vous avez défini ceci comme un objectif',
  set_as_future_goal: 'Définir comme objectif futur',
  choose_file: 'Choisir le fichier',
  file_types: 'pdf, doc, docx, csv, txt, xlsx, ppt, pptx, png, jpg, jpeg ,mp4, mov',
  extra_notes: 'Décrivez davantage',
  pick_from_library: 'Choisir dans la bibliothèque',
  create_new: 'Créer nouveau',
  certificate_details: 'Détails de la certification',
  attachment: 'Pièce jointe',
  insert_name: 'Insérer le nom',
  select_type: 'Sélectionner le type',
  emitted_by: 'Émis par',
  insert_date_of_emission: "Insérer la date d'émission",
  insert_expiration_date: "Insérer la date d'expiration",
  select_certificate_owner: 'Sélectionner le propriétaire du document',
  create: 'Créer',
  attachment_is_required: 'La pièce jointe est requise',
  name_is_required: 'Le nom est requis',
  document_type_is_required: 'Le type de document est requis',
  emitted_by_is_required: 'Émis par est requis',
  emission_date_is_required: "La date d'émission est requise",
  expiration_date_is_required: "La date d'expiration est requise",
  certificate_owner_is_required: 'Le propriétaire du certificat est requis',
  pick_certificate: 'Choisir le document',
  preview: 'Aperçu',
  name: 'Nom',
  date_of_emission: "Date d'émission",
  expiration_date: "Date d'expiration",
  alerts: 'Alertes',
  thirty_days_before_expiration_date: "30 jours avant la date d'expiration",
  validated: 'Validé',
  form_issuing_today: "Validité du document",
  certifications_and_proof_points_uploaded: 'Certifications et points de preuve téléchargés',
  verified_date: 'Date de validation',
  privacy: 'Confidentialité',
  search: 'Recherche',
  view_profile: 'Voir le profil',
  user_name: "Nom d'utilisateur",
  user_profile: "Profil utilisateur",
  log_out: 'Se déconnecter',
  user_details: "Détails de l'utilisateur",
  full_name: 'Nom complet',
  form_status: 'Statut du formulaire',
  visibility: 'Visibilité',
  image: 'Image',
  E_commerce_item_code: "Code d'article E-commerce",
  title: 'Titre',
  supply_chain: "Chaîne d'approvisionnement",
  collection: 'Collection',
  uploaded_products: 'Produits téléchargés',
  upload_product: 'Télécharger des produits',
  new_product: 'Nouveau produit',
  additional_product_facts_form: "Formulaire de faits supplémentaires sur le produit",
  total: 'Total',
  product_title: 'Titre du produit',
  enter_sku: 'Entrer UGS',
  e_commerce_id: 'Id E-commerce',
  item_code: "Code de l'article",
  insert_collection: 'Insérer la collection',
  composition: 'Composition',
  care_instruction: "Instructions d'entretien",
  additional_care_info: 'Informations supplémentaires relatives à la prise en charge',
  weight: 'Poids',
  colors: 'Couleurs',
  choose_category: 'Choisir une catégorie',
  price: 'Prix',
  save: 'Enregistrer',
  enter_detail_here: 'Entrez les détails ici',
  enter_collection_name: 'insérer le nom de la collection',
  mandatory_for_product_creation: "Obligatoire pour l'installation du gadget",
  mandatory_for_qr_generation: 'Obligatoire pour la génération de QR',
  attach_image: 'Joindre une image',
  upload_files: 'Télécharger des fichiers',
  upload_CSV_file: 'Télécharger un fichier CSV',
  upload_images_zip: 'Téléchargez des images ou un fichier zip',
  update_product: 'Mettre à jour le produit',
  material: 'Matériel',
  percentage: 'Pourcentage',
  add: 'Ajouter',
  qr_id: 'Identifiant QR',
  mandatory_ecommerceId: 'Identifiant e-commerce obligatoire',
  weave: 'Tissure',
  title_is_required: 'Le titre est requis',
  weave_is_required: ' Le tissage est requis',
  sku_id_is_required: "L'identifiant UGS est requis",
  ecommerce_id_is_required: "L'identifiant e-commerce est requis",
  cannot_contain_slash_or_underscore: 'Ne peut pas contenir / ou _',
  no_files_selected: 'Aucun fichier sélectionné',
  invalid_file_format: 'Format de fichier invalide (seulement .jpeg, .png)',
  file_size_exceeds_limit: 'La taille du fichier dépasse la limite (de 1 Mo)',
  total_file_size_exceeds_limit: 'La taille totale du fichier dépasse la limite (de 5 Mo)',
  active: 'Actif',
  archive: 'Archiver',
  download_QR: 'Télécharger QR',
  no_product_found: 'Aucun produit trouvé',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested: "Ce sont les revendications approuvées, en attente et rejetées. Veuillez prendre les mesures demandées.",
  requested_category: 'Revendications demandées',
  submit_the_detail_and_wait_for_approval: "Soumettre les détails et attendre l'approbation",
  submitted: 'Soumis',
  set_of_questions_to_be_answered: 'Ensemble de questions à répondre',
  verify: 'Vérifier',
  categories_to_be_reviewed: 'Revendications à examiner',
  are_you_sure_you_want_to_delete_this_certificate: 'Êtes-vous sûr de vouloir supprimer ce document ?',
  delete_certificate: 'Supprimer le document',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below: 'Sélectionnez la valeur que vous souhaitez revendiquer pour agréger facilement vos données dans la section ci-dessous',
  review_categories: "Examiner les revendications",
  review: 'Réviser',
  categories: 'Revendications',
  categories_and_claims: 'Catégories et allégations',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting: 'Remarque avant de commencer : vous pouvez enregistrer et modifier vos questions à tout moment avant de soumettre.',
  enjoy_the_process: 'Profitez du processus.',
  eco_packaging: 'Emballage éco',
  submit_the_detail: 'Soumettre les détails',
  claim_status: 'État de la réclamation',
  create_the_claim: 'Créer la revendication',
  save_claim: 'Enregistrer la revendication',
  reject_document: 'Rejeter le document',
  reject: 'Rejeter',
  reason_for_rejection: 'Raison du rejet',
  locally_made: 'Fabriqué localement',
  reviewd_category: 'Revendications examinées',
  document_already_uploaded: 'Document déjà téléchargé',
  select_or_enter_a_claim: 'Sélectionnez ou saisissez une réclamation',
  reason: 'Raison',
  explain_for_selecting_the_particular_status_for_the_claim: 'Expliquez la sélection du statut particulier pour la réclamation',
  assign_a_label: 'Attribuer une étiquette',
  label_name: "Nom de l'étiquette",
  label_eg: "P.ex., Collection 2024",
  description: 'Description',
  assign_document: 'Attribuer un document',
  document_attach: 'Joindre le document',
  assigned_document: 'Document attribué',
  label_assigned: 'Étiquette attribuée',
  all: 'Tout',
  supply_chain_management: "Gestion de la chaîne d'approvisionnement",
  supplier: 'Fournisseur',
  suppliers: 'Fournisseurs',
  upload_csv: 'Télécharger CSV',
  add_suppliers: 'Ajouter des fournisseurs',
  view_claims: 'Voir les revendications',
  add_proudcts: 'Ajouter des biens ou des services',
  create_supply_chain: "Créer une chaîne d'approvisionnement",
  suppliers_have_products_or_services: 'Les fournisseurs fournissent des biens ou des services',
  claim_name: 'Nom de la revendication',
  claims: 'Revendications',
  list_of_claims: 'Liste des revendications',
  add_supplier: 'Ajouter un fournisseur',
  name_eg: 'P.ex. : Renoon',
  vanity_name: "Nom d'enseigne du fournisseur",
  products: 'Produits associés',
  website_url: 'URL du site web',
  website_eg: 'P.ex. : https://renoon.com',
  vat_eg: 'P.ex. : IT123456789',
  vat_code: 'Code de TVA',
  vat: 'T.V.A.',
  address: 'Adresse',
  state: 'État',
  city: 'Ville',
  region_country: 'Pays/Région',
  street: 'Rue',
  upload_images: 'Télécharger des images',
  contact: 'Contact',
  no_of_employee: "Nombre d'employés",
  no_of_factories: "Nombre d'usines",
  add_a_new_supplier_by_submitting_this_details: 'Ajoute un nouveau fournisseur en soumettant ces détails',
  add_new_products: 'Ajouter un produit fourni',
  document: 'Document',
  select_products: 'Sélectionner des produits',
  more_custom_properties_may_be_added_like_colors_etc: "D'autres propriétés personnalisées peuvent être ajoutées, comme les couleurs, etc.",
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'Elles ne sont pas toujours pertinentes et ne sont pas nécessaires pour le PMV.',
  vanity_name_is_required: "Le nom d'enseigne est requis",
  website_url_is_required: "L'URL du site est requise",
  vat_is_required: 'Le code de TVA est requis',
  description_is_required: 'La description est requise',
  contact_name_is_required: 'Le nom du contact est requis',
  contact_email_is_required: "L'e-mail de contact est requis",
  please_enter_a_valid_email: 'Veuillez entrer un email valide',
  street_is_required: 'La rue est requise',
  city_name_is_required: 'Le nom de la ville est requis',
  country_is_required: 'Le pays est requis',
  state_is_required: "L'état est requis",
  add_new: 'Ajouter nouveau',
  stage: 'Étape',
  sku: 'UGS',
  sku_eg: 'P.ex. : FW24CBSHEJ66',
  add_new_product_or_service: 'Ajouter des biens ou des services',
  stage_is_required: "L'étape est requise",
  delete_supplier: 'Supprimer le fournisseur',
  are_you_sure_you_want_to_delete_this_supplier: 'Etes-vous sûr de vouloir supprimer ce fournisseur ?',
  view_claim: 'Voir la revendication',
  search_products: 'Rechercher des biens',
  create_product: 'Créer des biens',
  create_service: 'Créer un service',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Etes-vous sûr de vouloir supprimer ce produit fournisseur ?',
  search_services: 'Rechercher des services',
  edit_supply_chain: "Modifier la chaîne d'approvisionnement",
  are_you_sure_you_want_to_delete_this_supply_chain: "Êtes-vous sûr de vouloir supprimer cette chaîne d'approvisionnement ?",
  delete_supply_Chain: "Supprimer la chaîne d'approvisionnement",
  continue: 'Continuer',
  select_the_supplier: 'Sélectionner le fournisseur',
  edit_product: 'Modifier le service',
  save_draft: 'Enregistrer le brouillon',
  detailed_view: 'Vue détaillée',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'Elles ne sont pas toujours pertinentes et ne sont pas nécessaires pour le PMV.',
  add_products: 'Ajouter des produits',
  select_the_supplier_product: 'Sélectionner le service du fournisseur',
  is_in_supply_chain: "Est dans la chaîne d'approvisionnement",
  country: 'Pays',
  has_suppliers_products: 'A des produits fournisseurs',
  is_selected: 'Est sélectionné',
  sort_by: 'Trier par',
  filter: 'Filtrer',
  clear_all: 'Effacer tout',
  has_products: 'A des produits',
  is_in_a_supply_chain: "Est dans une chaîne d'approvisionnement",
  service: 'Service',
  product: 'Produit',
  type: 'Type de produit',
  has_suppliers: 'A des fournisseurs',
  are_you_sure_you_want_to_delete_this_chain_product: 'Êtes-vous sûr de vouloir supprimer le produit de cette chaîne ?',
  are_you_sure_you_want_to_delete_this_chain_supplier: 'Êtes-vous sûr de vouloir supprimer le fournisseur de cette chaîne ?',
  zoom_in: 'Zoomer',
  zoom_out: 'Dézoomer',
  reset: 'Réinitialiser',
  no_certificate_found: 'Aucun document trouvé',
  reviewed_claims: 'RÉCLAMATIONS EXAMINÉES',
  view_all: 'Voir tout',
  unique_Id: 'Id unique',
  view_docs: 'Voir doc',
  select_the_value_you_want_to_communicate: 'Sélectionnez les valeurs que vous souhaitez communiquer. Nous nous occupons de les valider et de les traduire en revendications conformes.',
  edit_supplier: 'Modifier le fournisseur',
  submit_for_approval: 'Soumettre pour approbation',
  fill_the_forms: 'Remplir les formulaires',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered:
    'Les formulaires ci-dessous contiennent toutes les questions pertinentes pour obtenir les requêtes sélectionnées, ainsi que quelques questions génériques. Ils sont enregistrés automatiquement. Vous pouvez les soumettre une fois que vous avez répondu à toutes les questions.',
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:
    "Dans cette section, vous pouvez consulter tous les documents téléchargés. Vous aurez la possibilité de vérifier facilement les certifications imminentes d'expiration, de recevoir des notifications à ce sujet et d'agir pour les renouveler. De plus, vous pourrez modifier et mettre à jour toutes les informations liées à ces documents, assurant ainsi que les données restent toujours exactes et à jour.",
  documents: 'DOCUMENTS ET CERTIFICATIONS',
  create_brand: 'Créer une marque',
  select_claim: 'Sélectionner la revendication',
  select_supplier: 'Sélectionner le fournisseur',
  select_product: 'Sélectionner le produit',
  filter_by: 'Filtrer par',
  additional_settings: 'Paramètres supplémentaires',
  stage_vanity_name: "Nom d'enseigne",
  vanity_title:"Titre d'enseigne",
  has_not_product: "N'a pas de produit",
  read_more: 'Lire la suite',
  read_less: 'Lire moins',
  add_user: 'Ajouter un utilisateur',
  files: 'Fichiers',
  file_type: 'Type de fichier',
  deselect_all: 'Désélectionner tout',
  select_all: 'Sélectionner tout',
  is_archived: 'Est archivé',
  qr_code: 'Code QR',
  date: 'date',
  only_image_files_PNG_JPEG_JPG_are_allowed: 'Seuls les fichiers images (PNG, JPG, JPEG) sont autorisés.',
  only_PDF_files_are_allowed: 'Seuls les fichiers PDF sont autorisés',
  download_qr: 'Télécharger le code QR',
  no_category_found: 'Aucun réclamation trouvée',
  yes: 'Oui',
  no: 'Non',
  collectionName: 'Nom de la collection',
  date_: 'date',
  title_: 'titre',
  name_: 'nom',
  Name: 'Nom',
  Date: 'Date',
  Title: 'Titre',
  Stage: 'Étape',
  PRODUCT: 'PRODUIT',
  SERVICE: 'SERVICE',
  Verified: 'Vérifié',
  Proven: 'Prouvé',
  Declared: 'Déclaré',
  Rejected: 'Rejeté',
  additional_information: 'Informations supplémentaires',
  upload: 'Télécharger',
  SUPPLIER: 'FOURNISSEUR',
  SUPPLIER_CHAIN: "Chaîne d'approvisionnement",
  SUPPLIER_PRODUCT: 'FOURNISSEUR-PRODUIT',
  BRAND_PRODUCT: 'MARQUE-PRODUIT',
  Less_than_9: 'Moins de 9',
  ten_to_49: '10 à 49',
  fifty_to_249: '50 à 249',
  More_than_250: 'Plus de 250',
  VERIFIED: 'VÉRIFIÉ',
  PROVEN: 'PROUVÉ',
  DECLARED: 'DÉCLARÉ',
  REJECTED: 'REJETÉ',
  PENDING: 'EN ATTENTE',
  product_: 'Produit +',
  supplier_: 'Fournisseur +',
  email_is_required: "L'e-mail est requis",
  sku_id: 'Identifiant SKU',
  collection_name: 'Nom de la collection',
  category: 'Catégorie',
  Unselect_All: 'désélectionner Tout',
  are_you_sure_you_want_to_remove_all_products: 'Êtes-vous sûr de supprimer tous les produits liés à cette réclamation ?',
  remove_all_the_products_connected_to_this_claim: 'Supprimer tous les produits liés à cette réclamation',
  are_you_sure_you_want_to_remove_all_suppliers: 'Êtes-vous sûr de supprimer tous les fournisseurs liés à cette réclamation ?',
  remove_all_the_suppliers_connected_to_this_claim: 'Supprimer tous les fournisseurs liés à cette réclamation',
  life_cycle_assessment: 'Analyse du cycle de vie',
  start_calculation: 'Démarrer le calcul',
  upload_csv_file: 'Télécharger CSV',
  view_results: 'Afficher les résultats',
  key_matrix: 'Matrice clé',
  total_of_all_te_elements: 'Total de tous les éléments',
  lca_type: 'Taper',
  qty: 'Quantité',
  unit: 'Unité',
  Selected: 'Choisi',
  max_lca_products: 'Produits Max ACV',
  product_category: 'Catégorie de produit',
  assessment_name_label: "Nom de l'évaluation",
  assessment_name_placeholder: 'Entrez le nom',
  edit_draft: 'Modifier le brouillon',
  select_product_and_continue: 'Sélectionnez le produit et continuez',
  indicator: 'Indicateur',
  selected_product: 'Vous avez sélectionné 1 produit',
  submit: 'Soumettre',
  your_password_reset_link_is_ready: 'VOTRE LIEN DE RÉINITIALISATION DE MOT DE PASSE EST PRÊT.',
  just_check_your_email_for_reset_link: 'Vérifiez simplement votre courrier électronique pour réinitialiser le LIEN.',
  forget_your_password: 'Vous avez oublié votre mot de passe ?',
  easy_template_download: 'Téléchargement facile du modèle',
  easy_template_download_desc: `C'est un fait établi depuis longtemps qu'un lecteur sera distrait par le contenu lisible d'une page lorsqu'il examinera sa mise en page. L'intérêt d'utiliser le Lorem Ipsum est qu'il a une distribution de lettres plus ou moins normale, par opposition à l'utilisation de « Contenu ici, contenu ici », ce qui donne l'impression qu'il s'agit d'un anglais lisible. De nombreux logiciels de publication assistée par ordinateur et éditeurs de pages Web utilisent désormais Lorem Ipsum comme leur texte de modèle par défaut et une recherche f`,
  csv_template: 'Modèle CSV',
  how_to_upload_manual: 'Comment télécharger le manuel',
  how_to_upload_manual_desc: `C'est un fait établi depuis longtemps qu'un lecteur sera distrait par le contenu lisible d'une page lorsqu'il examinera sa mise en page. L’intérêt d’utiliser le Lorem Ipsum est qu’il a`,
  download_pdf: 'Télécharger le PDF',
  upload_the_template_created: 'Téléchargez le modèle créé',
  updating: 'Mise à jour',
  creating: 'Création',
  select_recommanded_products: 'Sélectionnez les produits recommandés',
  search_for_assessment: 'Rechercher des évaluations',
  created: 'Créé',
  archive_tool: 'Archive - Les produits archivés auront encore une DPP publique, si créée précédemment, mais ne seront pas modifiables',
  ecommerce_id_tool: 'Identifiants e-commerce - Séparez chaque id par une ,',
  weave_tool: 'Tissage - ex: 3/1 Z sergé',
  color_tool: 'Couleurs - Séparez chaque couleur par une ,',
  garment: 'Vêtement',
  type_of_garment: 'Type de vêtement',
  garment_code: 'Code vêtement',
  garment_title: 'Titre du vêtement',
  product_composition: 'Composition du produit',
  distribution: 'Distribution',
  transport: 'Transport',
  distance: 'Distance (km)',
  packaging: 'Conditionnement',
  primary_packaging_used: 'Emballage primaire utilisé',
  quantity: 'Quantité',
  production_volume: 'Volume de production',
  consumption_of_water: "Consommation d'eau",
  consumption_of_natural_gas: 'Consommation de gaz naturel',
  electricity: 'Électricité',
  total_use: 'Consommation totale (kWh)',
  use_of_electricity_from_renewable_sources: "Utilisation d'électricité issue de sources renouvelables",
  do_you_use_electricity_from_renewable_sources: 'Utilisez-vous de l’électricité provenant de sources renouvelables ?',
  use_of_electricity_from_pv: "Utilisation de l'électricité photovoltaïque",
  do_you_have_a_pv_system_installed_in_your_production_site: 'Avez-vous un système photovoltaïque installé sur votre site de production ?',
  share_of_electricity_from_pv: 'Part de l’électricité issue du PV',
  share_of_electricity_from_pv_desc: "Veuillez indiquer la part de l'électricité utilisée sur votre site de production qui provient de votre système photovoltaïque.",
  location_of_renewable_electricity_supply: 'Localisation de l’approvisionnement en électricité renouvelable',
  location_of_renewable_electricity_supply_desc: "Veuillez indiquer la part de l'électricité utilisée sur votre site de production qui provient de votre système photovoltaïque.",
  share_of_electricity_from_renewable_sources: "Part de l'électricité issue de sources renouvelables",
  share_of_electricity_from_renewable_sources_desc: "Veuillez indiquer la part de l'électricité que vous achetez sur le réseau national et qui provient de sources renouvelables.",
  waste: 'Déchets',
  share_of_electricity_from_renewable_sources: "Part de l'électricité issue de sources renouvelables",
  waste_quantity: 'Quantité de déchets (kg)',
  waste_recycling: 'Déchets - recyclage (%)',
  waste_incineration: 'Déchets - incinération',
  waste_landfill: 'Déchets - mise en décharge',
  dyeing: 'Teinture',
  fabric_dyeing: 'Teinture de tissus',
  transportation_type: 'Type de transport',
  fabric_manufacturing: 'Fabrication de tissus',
  yarn_count: 'Nombre de fils (dtex)',
  yarn_manufacturing: 'fabrication de fils',
  spinning: 'Filage',
  raw_material_supply: 'Approvisionnement en matières premières',
  which_is_the_composition_of_the_garment_you_want_to_analyze: 'Quelle est la composition du vêtement que vous souhaitez analyser ?',
  material_description: 'Description du matériau',
  material_family: 'Famille de matériaux',
  material_type: 'Type de matériau',
  material_weight: 'Poids du matériau',
  material_percentage: 'Matériel %',
  Waste_generation: 'Production de déchets',
  weight_kg: 'Poids (kg)',
  this_product_already_selected_in_supply_chain: 'Ce produit déjà sélectionné dans la supply chain',
  add_results: 'Ajouter des résultats',
  view_product: 'Voir le produit',
  no_product: 'Aucun produit',
  save_product: 'Enregistrer le produit',
  are_you_sure_you_want_to_remove_all_prod: 'Êtes-vous sûr de supprimer tous les produits liés à ce fournisseur ?',
  remove_all_the_products_connected_to_this_supplier: 'Supprimez tous les produits connectés à ce fournisseur.',
  supplier_products: 'Produits du fournisseur',
  has_lca: 'A une ACV',
  has_supply_chain: "A une chaîne d'approvisionnement",
  assign_result: 'Attribuer des résultats',
  update_result: 'Résultat de la mise à jour',
  upload_file: 'Télécharger le fichier',
  bulk_upload_results: 'Résultats du téléchargement groupé pour le produit',
  sku_number: 'Numéro SKU',
  upload_date: 'Date de téléchargement',
  assigned_to: "Attribué à"
};
export default frenchTranslation;
