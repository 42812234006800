import React, { useEffect, useState } from 'react';
import { Dialog, IconButton, Stack, Typography, Box, Paper, TextField, Chip, Select, MenuItem, Button, Snackbar, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import BG_CO2 from '../../../images/bg-co2.png';
import { lca_indicators_data } from '../../../data';
import { useNavigate } from 'react-router-dom';
import { RestUrlsConstants } from '../../../constants/rest-urls.constants';
import httpService from '../../../service/http.service';
import PATH from '../../utils/path';
import useCheckOnlineStatus from '../../../hooks/useOnlineCheckStatus';

// Styled Components
const StyledList = styled(Paper)(({ theme, isBgColor }) => ({
  backgroundColor: isBgColor,
  marginTop: theme.spacing(2.5),
  padding: theme.spacing(1.5, 2.5),
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '700',
  color: '#ffffff',
}));

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  padding: '12px 18px',
  backgroundColor: '#ffffff',
  borderRadius: theme.spacing(1),
}));

const StyledNextBtn = styled(Button)(({}) => ({
  backgroundColor: '#0E1090',
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: '#0E1090' },
}));

const chipIndicatorColors = {
  IMPACT: '#FF5722',
  INVENTORY: '#808080',
};

const HeaderRow = () => (
  <StyledList isBgColor="#0E1090">
    <Box width="40%">
      <StyledText>Type</StyledText>
    </Box>
    <Box width="15%" alignItems="center" display="flex">
      <StyledText>Quantity</StyledText>
    </Box>
    <Box width="25%">
      <StyledText>Unit</StyledText>
    </Box>
    <Box width="10%" display="flex" justifyContent="center">
      <StyledText>Indicator</StyledText>
    </Box>
  </StyledList>
);

const DataRow = ({ type, unit, indicators, quantity, onQuantityChange }) => {
  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      onQuantityChange(indicators, type, '');
      return;
    }

    const validPattern = /^\d{1,5}(\.\d{0,5})?$/;

    if (unit === 'Days' || unit === 'Hours') {
      const validPatternForDaysOrHours = /^\d{1,3}$/;
      if (value === '' || (validPatternForDaysOrHours.test(value) && parseInt(value, 10) <= 999)) {
        onQuantityChange(indicators, type, value);
      }
    } else {
      if (value === '' || validPattern.test(value)) {
        let formattedValue = value;
        if (formattedValue.includes('.')) {
          const [integerPart, decimalPart] = formattedValue.split('.');
          formattedValue = `${integerPart.slice(0, 5)}.${decimalPart.slice(0, 5)}`;
        } else {
          formattedValue = formattedValue.slice(0, 5);
        }
        onQuantityChange(indicators, type, formattedValue);
      }
    }
  };

  return (
    <StyledRow>
      <Box width="40%" display="flex" alignItems="center" gap={1}>
        <img src={BG_CO2} alt={type} height={32} width={32} />
        <Typography fontSize={12} textTransform="uppercase">
          {type}
        </Typography>
      </Box>
      <TextField
        variant="standard"
        type="text"
        value={quantity}
        onChange={handleInputChange}
        sx={{
          width: '15%',
          backgroundColor: 'none',
        }}
      />

      <Box width="25%">
        <Typography fontSize={14} fontWeight="700">
          {unit}
        </Typography>
      </Box>

      <Box width="10%" display="flex" justifyContent="center">
        <Chip
          label={indicators}
          size="small"
          sx={{
            backgroundColor: chipIndicatorColors[indicators],
            color: '#fff',
          }}
        />
      </Box>
    </StyledRow>
  );
};

const AddResultCSVModal = ({ open, onClose, selectedLcaId }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [quantities, setQuantities] = useState({
    IMPACT: {},
    INVENTORY: {},
  });
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [resultData, setResultData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedLcaId) {
      fetchSingleLCA(selectedLcaId);
    }
  }, [selectedLcaId]);

  const fetchSingleLCA = async (lcaID) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.LCA}/${lcaID}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        const filteredData = response?.data?.data?.result?.filter((item) => item && item.type.trim() !== '');

        const processedData = filteredData?.map((item) => {
          if (item.quantity && !isNaN(item.quantity)) {
            item.quantity = parseFloat(item.quantity).toFixed(5);
          }
          return item;
        });

        setResultData(processedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (indicator, type, value) => {
    setQuantities((prevQuantities) => {
      const updatedQuantities = {
        ...prevQuantities,
        [indicator]: {
          ...prevQuantities[indicator],
          [type]: value,
        },
      };

      return updatedQuantities;
    });
  };

  const onSave = async () => {
    if (!checkOnlineStatus()) return;

    const result = resultData
      ?.filter((item) => {
        const quantity = quantities[item.indicator]?.[item.type] !== undefined ? quantities[item.indicator][item.type] : item.quantity;

        return quantity && !isNaN(Number(quantity));
      })
      .map((item) => {
        const quantity = quantities[item.indicator]?.[item.type] !== undefined ? Number(quantities[item.indicator][item.type]) : Number(item.quantity);

        return {
          type: item.type,
          quantity,
          unit: Array.isArray(item.unit) ? item.unit[0] : item.unit,
          indicator: item.indicator?.toUpperCase(),
        };
      });

    try {
      let obj = {
        result: result,
      };
      const response = await httpService.patch(`${RestUrlsConstants.LCA}/${selectedLcaId}`, obj, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        navigate(`${PATH.LIFECYCLEASSESSMENT}`);
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { padding: '20px' } }} fullWidth maxWidth="md" aria-labelledby="add-results-dialog">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={700} fontSize={24} id="add-results-dialog">
          {t('add_results')}
        </Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ color: 'black' }}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder={t('search')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{
            borderRadius: '20px',
            '& .MuiOutlinedInput-root': { borderRadius: '20px' },
          }}
        />
      </Box>

      <Box height="500px" overflow="auto" mt={2}>
        <HeaderRow />
        <Stack mt={1}>
          {loading ? (
            <Stack mt={3} alignItems="center" justifyContent="center" height="100%">
              <CircularProgress size={20} />
            </Stack>
          ) : resultData?.length > 0 ? (
            <>
              {resultData
                ?.filter((row) => row.type.toLowerCase().includes(searchValue.toLowerCase()))
                ?.map((row, index) => (
                  <DataRow
                    key={index}
                    type={row.type}
                    unit={row.unit}
                    indicators={row.indicator}
                    quantity={quantities[row.indicator] && quantities[row.indicator][row.type] !== undefined ? quantities[row.indicator][row.type] : row.quantity}
                    onQuantityChange={handleQuantityChange}
                  />
                ))}
            </>
          ) : (
            <>
              {resultData?.filter((row) => row.type.toLowerCase().includes(searchValue.toLowerCase())).length === 0 && (
                <Typography align="center" fontSize={20} color="textSecondary" mt={2}>
                  No results found
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Box>

      <Stack mt={2} alignItems="flex-end" width="100%">
        <StyledNextBtn onClick={onSave} variant="contained">
          {t('save')}
        </StyledNextBtn>
      </Stack>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Dialog>
  );
};

export default AddResultCSVModal;
