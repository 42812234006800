import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
//import images
import CLIPBOARD from '../../../images/clipboard.png';
//relative path imports
import PATH from '../../utils/path';

const SuccessModal = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const countdown = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          navigate(PATH.LIFECYCLEASSESSMENT);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [navigate]);

  return (
    <Box mt={5}>
      <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
        <CardContent>
          <Typography textAlign={'center'} fontSize={24} fontWeight={'700'} color={'#0E1090'}>
            Form submitted successfully!
          </Typography>
          <Box display={'flex'} mt={4} alignItems={'center'} justifyContent={'center'} width={'100%'}>
            <img src={CLIPBOARD} alt="clipboard-icon" height={250} width={250} />
          </Box>
          <Typography textAlign={'center'} mt={2} fontWeight={'700'}>
            Page will redirect to dashboard in <span style={{ color: '#B92932' }}>{seconds} second</span>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SuccessModal;
