import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box, Tooltip } from '@mui/material';

export default function CommonSelect(props) {
  const { label, options, value, handleChange, tooltip, variant, isSpacing } = props;

  return (
    <Box mt={isSpacing ? 0 : 4}>
      <Tooltip title={tooltip} placement="top" arrow>
        <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }} id="demo-simple-select-standard-label">
          {label}
        </InputLabel>
      </Tooltip>

      <Select
        variant="standard"
        sx={{
          width: '100%',
          '& .MuiInputBase-root:after': {
            borderColor: '#101010',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderColor: 'none',
            borderBottom: '1px solid gray',
          },
        }}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
      >
        {options?.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </Box>
  );
}
