import { Autocomplete, Box, Button, Card, CardContent, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import CommonInput from '../utils/CommonInput';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import CommonSelect from '../utils/CommonSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { decryptId, encryptId } from '../utils/encryptDecrypt';
import LanguageSelector from '../utils/LanguageSelector';
import { IoMdArrowBack } from 'react-icons/io';
import CloseIcon from '@mui/icons-material/Close';
import CommonSwitch from '../utils/CommonSwitch';
import InfoIcon from '@mui/icons-material/Info';
import HandIcon from '../../images/icons/handIcon';
import PATH from '../utils/path';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

export const categoryList = ['TOP', 'BOTTOM', 'DRESS', 'SHOES', 'ACCESSORIES', 'BAG', 'TEXTILE', 'YARN'];

const CreateProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  let currentPage = params?.page;
  const fileInputRef = useRef(null);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectImage, setSelectImage] = useState('');
  const [showTitle, setShowTitle] = useState(true);
  const [showAdditionalSetting, setShowAdditionalSettings] = useState(false);
  const [careInstructions, setCareInstructions] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const priceUnit = ['rs', '$', '£', '€', '¥'];
  const weightUnit = ['g', 'kg', 'oz'];
  const [formData, setFormData] = useState({
    files: [],
    skyQRcode: '',
    qrUrl: '',
    title: { en: '', fr: '', it: '', de: '', tr: '' },
    composition: { en: [], fr: [], it: [], de: [], tr: [] },
    ecommerceId: '',
    weight: '',
    weightUnit: weightUnit[0],
    collection: '',
    careInstructions: [],
    careInfo: '',
    category: '',
    colors: { en: '', fr: '', it: '', de: '', tr: '' },
    price: '',
    priceUnit: priceUnit[0],
    weave: { en: '', fr: '', it: '', de: '', tr: '' },
    isArchived: false,
    supplierChainId: '',
  });
  const [skuId, setSkuId] = useState('');
  const [errors, setErrors] = useState({
    title: '',
    skyQRcode: '',
    ecommerceId: '',
    file: '',
  });
  const [lang, setLang] = useState('');
  const [name, setName] = useState('');
  const [percentage, setPercentage] = useState();
  const [SingleData, setSingleData] = useState({});

  const handleAdd = () => {
    if (name && percentage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        composition: {
          ...prevFormData.composition,
          [lang]: [...(prevFormData?.composition?.[lang] || []), { name, percentage }],
        },
      }));
      setName('');
      setPercentage('');
    }
  };
  const handleDelete = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      composition: {
        ...prevFormData.composition,
        [lang]: prevFormData.composition[lang].filter((_, i) => i !== index),
      },
    }));
  };
  const handleAdditionalSetting = () => {
    setShowAdditionalSettings(!showAdditionalSetting);
  };

  const validFileFormat = (files) => {
    const newErrors = { file: '' };
    let valid = true;
    let totalSize = 0;

    if (files.length === 0) {
      newErrors.file = t('no_files_selected');
      valid = false;
    } else {
      files.forEach((file) => {
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
          newErrors.file = t('only_image_files_PNG_JPEG_JPG_are_allowed');
          valid = false;
        } else if (file.size > 1024 * 1024) {
          newErrors.file = t('file_size_exceeds_limit');
          valid = false;
        }
        totalSize += file.size;
      });
      if (totalSize > 5 * 1024 * 1024) {
        newErrors.file = t('total_file_size_exceeds_limit');
        valid = false;
      }
    }

    return { valid, newErrors };
  };
  useEffect(() => {
    if (imageUrls) {
      setSelectImage(imageUrls[0]);
    }
  }, [imageUrls]);
  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    const allFiles = [...newFiles];
    const { valid, newErrors } = validFileFormat(allFiles);
    if (!valid) {
      setErrors(newErrors);
      return;
    }
    const imageUrlUpdate = newFiles.map((file) => URL.createObjectURL(file));
    if (updateMode) {
      setImageUrls([...imageUrls, ...imageUrlUpdate]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        files: [...formData.files, ...newFiles],
      }));
    } else {
      setImageUrls([...imageUrls, ...imageUrlUpdate]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        files: [...formData.files, ...newFiles],
      }));
    }
  };

  const removeImage = (index, isUploaded) => {
    if (isUploaded) {
      handleRemoveImage(imageUrls[index], index);
    } else {
      const updatedFiles = imageUrls.filter((_, i) => i !== index);
      setImageUrls(updatedFiles);
    }
  };

  const handleRemoveImage = async (imgUrl, index) => {
    // const newUrl = imgUrl.split('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/' || 'https://static.renoon.com/')[1];
    let newUrl = imgUrl;
    if (imgUrl.startsWith('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/')) {
      newUrl = imgUrl.split('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/')[1];
    } else if (imgUrl.startsWith('https://static.renoon.com/')) {
      newUrl = imgUrl.split('https://static.renoon.com/')[1];
    }
    try {
      const response = await httpService.get(`${RestUrlsConstants.product_image_delete}/${decryptId(params.id)}?file=${newUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        // setSnackBar({ open: true, message: response?.data?.message });
        const updatedFiles = imageUrls.filter((_, i) => i !== index);
        setImageUrls(updatedFiles);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      // setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  useEffect(() => {
    const fetchInitialProductData = async () => {
      if (params?.id) {
        try {
          const response = await httpService.get(`${RestUrlsConstants.product}/${decryptId(params.id)}`, {
            headers: { Authorization: localStorage.getItem('Authorization') },
          });
          if (response?.data?.status) {
            setSingleData(response?.data?.data);
            setUpdateMode(true);
            setImageUrls(response?.data?.data?.urls);
            setSkuId(response?.data?.data?.qrId === 'undefined' || response?.data?.data?.qrId === 'null' ? '' : response?.data?.data?.qrId);
            const qrIdExists = response?.data?.data.hasOwnProperty('qrId');
            const qrIdValue = qrIdExists && response?.data?.data?.qrId && response?.data?.data?.qrId !== 'undefined' && response?.data?.data?.qrId !== 'null' ? response?.data?.data?.qrId : '';
            // const qrUrlExists = response?.data?.data.hasOwnProperty('qrUrl');
            // const qrUrlValue = qrUrlExists && response?.data?.data?.qrUrl && response?.data?.data?.qrUrl !== 'undefined' && response?.data?.data?.qrUrl !== 'null' ? response?.data?.data?.qrUrl : '';
            setFormData((prevData) => ({
              ...prevData,
              title: response?.data?.data?.title,
              composition: response?.data?.data?.composition,
              weave: response?.data?.data?.weave,
              colors: response?.data?.data?.colors,
              skyQRcode: qrIdValue,
              qrUrl: response?.data?.data?.qrUrl,
              ecommerceId: response?.data?.data?.ecommerceId[0] === 'null' || response?.data?.data?.ecommerceId[0] === 'undefined' ? '' : response?.data?.data?.ecommerceId.map((ids) => ids),
              weight: response?.data?.data?.weight?.value,
              weightUnit: response?.data?.data?.weight?.unit,
              collection:
                response?.data?.data?.collectionName === 'null' || response?.data?.data?.collectionName === 'undefined' || response?.data?.data?.collectionName === null
                  ? ''
                  : response?.data?.data?.collectionName,
              careInstructions: response?.data?.data?.careInstructions,
              careInfo:
                response?.data?.data?.additionalCareInstructions === 'null' || response?.data?.data?.additionalCareInstructions === 'undefined' ? '' : response?.data?.data?.additionalCareInstructions,
              category: response?.data?.data?.category === 'undefined' || response?.data?.data?.category === 'null' ? '' : response?.data?.data?.category,
              price: response?.data?.data?.price?.value,
              priceUnit: response?.data?.data?.price?.unit,
              isArchived: response?.data?.data?.isArchived,
              supplierChainId: response?.data?.data?.supplierChainId,
            }));
          }
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      }
    };
    fetchInitialProductData();
  }, [params?.id]);

  useEffect(() => {
    if (SingleData && params?.id) {
      setFormData((prevData) => ({
        ...prevData,
        title: {
          ...prevData?.title,
          [lang]: prevData?.title?.[lang] ?? SingleData?.title?.[lang] ?? '',
        },
        composition: {
          ...prevData?.composition,
          [lang]: prevData?.composition?.[lang] ?? SingleData?.composition?.[lang] ?? '',
        },
        colors: {
          ...prevData?.colors,
          [lang]: prevData?.colors?.[lang] ?? SingleData?.colors?.[lang] ?? '',
        },
        weave: {
          ...prevData?.weave,
          [lang]: prevData?.weave?.[lang] ?? SingleData?.weave?.[lang] ?? '',
        },
      }));
    }
  }, [lang, SingleData, params?.id]);

  const fetchCareInstructions = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product_care_instruction}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setCareInstructions(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCareInstructions();
  }, []);

  const validateForm = () => {
    let valid = true;
    const newErrors = { title: '', skyQRcode: '', ecommerceId: '' };

    if (!formData.title[lang]?.trim()) {
      newErrors.title = t('title_is_required');
      valid = false;
    }

    const isSkyQRcodeFilled = formData.skyQRcode?.trim()?.length > 0;
    const isEcommerceIdFilled = Array.isArray(formData.ecommerceId) ? formData.ecommerceId.length > 0 : formData.ecommerceId?.trim()?.length > 0;

    if (!isSkyQRcodeFilled && !isEcommerceIdFilled) {
      newErrors.skyQRcode = t('sku_id_is_required');
      newErrors.ecommerceId = t('ecommerce_id_is_required');
      valid = false;
    }

    if (isSkyQRcodeFilled) {
      if (/[\/_]/.test(formData.skyQRcode)) {
        newErrors.skyQRcode = t('cannot_contain_slash_or_underscore');
        valid = false;
      }
    }

    if (isEcommerceIdFilled) {
      if (Array.isArray(formData.ecommerceId)) {
        formData.ecommerceId.forEach((id, index) => {
          if (/[\/_]/.test(id)) {
            newErrors.ecommerceId = t('cannot_contain_slash_or_underscore');
            valid = false;
          }
        });
      } else if (/[\/_]/.test(formData.ecommerceId)) {
        newErrors.ecommerceId = t('cannot_contain_slash_or_underscore');
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const createProduct = async () => {
    if (!validateForm()) return;
    const formDataSend = new FormData();
    if (formData?.files?.length) {
      for (let i = 0; i < formData?.files?.length; i++) {
        formDataSend.append('images', formData.files[i]);
      }
    }

    if (!updateMode) {
      let ecommerceIdArray = [];
      ecommerceIdArray = formData?.ecommerceId?.split(',');
      if (formData?.ecommerceId !== '') {
        for (let i = 0; i < ecommerceIdArray?.length; i++) {
          formDataSend.append(`ecommerceId[${i}]`, ecommerceIdArray[i]);
        }
      }
      if (formData.skyQRcode !== '') formDataSend.append('qrId', formData.skyQRcode);
    }

    Object.keys(formData.composition).forEach((langKey) => {
      if (formData.composition[langKey]?.length) {
        formData.composition[langKey].forEach((compositionItem, index) => {
          formDataSend.append(`composition[${langKey}][${index}][name]`, compositionItem.name);
          formDataSend.append(`composition[${langKey}][${index}][percentage]`, compositionItem.percentage);
        });
      }
    });

    if (formData.careInstructions?.length) {
      for (let i = 0; i < formData?.careInstructions?.length; i++) {
        formDataSend.append(`careInstructions[${i}]`, formData.careInstructions[i]);
      }
    }

    const filteredTitle = Object.keys(formData.title).reduce((acc, langKey) => {
      if (formData.title[langKey]) {
        acc[langKey] = formData.title[langKey];
      }
      return acc;
    }, {});

    for (const langKey in filteredTitle) {
      if (filteredTitle.hasOwnProperty(langKey)) {
        formDataSend.append(`title[${langKey}]`, filteredTitle[langKey]);
      }
    }

    const filteredWeave = Object.keys(formData.weave).reduce((acc, langKey) => {
      if (formData.weave[langKey]) {
        acc[langKey] = formData.weave[langKey];
      }
      return acc;
    }, {});

    for (const langKey in filteredWeave) {
      if (filteredWeave.hasOwnProperty(langKey)) {
        formDataSend.append(`weave[${langKey}]`, filteredWeave[langKey]);
      }
    }

    formDataSend.append('lang', lang);
    if (formData.collection !== '' && formData.collection !== undefined) formDataSend.append('collectionName', formData?.collection?.trim() === '' ? null : formData.collection);

    const filteredColors = Object.keys(formData.colors).reduce((acc, langKey) => {
      if (formData.colors[langKey]) {
        acc[langKey] = formData.colors[langKey];
      }
      return acc;
    }, {});

    for (const langKey in filteredColors) {
      if (filteredColors.hasOwnProperty(langKey)) {
        formDataSend.append(`colors[${langKey}]`, filteredColors[langKey]);
      }
    }

    if (formData.category !== '' && formData.category !== undefined && formData.category !== null) formDataSend.append('category', formData.category);
    if (formData.careInfo !== '' && formData.careInfo !== undefined && formData.careInfo !== null) formDataSend.append('additionalCareInstructions', formData.careInfo);
    formDataSend.append(`weight[unit]`, formData.weightUnit);
    formDataSend.append(`weight[value]`, formData.weight);
    formDataSend.append('price[value]', formData.price);
    formDataSend.append('price[unit]', formData.priceUnit);
    formDataSend.append('isArchived', formData.isArchived);

    try {
      if (!checkOnlineStatus()) return;
      const response = await httpService.post(`${RestUrlsConstants.product}`, formDataSend, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setFormData({});
        setSkuId('');
        setImageUrls([]);
        navigate(`${PATH.PRODUCTENGINE}/1`);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const updateProduct = async () => {
    if (!validateForm()) return;
    const formDataSend = new FormData();
    if (formData?.files?.length) {
      for (let i = 0; i < formData?.files?.length; i++) {
        formDataSend.append('images', formData.files[i]);
      }
    }
    if (formData.skyQRcode !== '') {
      formDataSend.append('qrId', formData.skyQRcode);
    }

    let ecommerceIdArray = [];
    let ecommerceIdString = '';
    if (Array.isArray(formData?.ecommerceId)) {
      ecommerceIdString = formData?.ecommerceId.join(',');
    } else {
      ecommerceIdString = formData?.ecommerceId;
    }
    ecommerceIdArray = ecommerceIdString.includes(',') ? ecommerceIdString.split(',') : [ecommerceIdString];
    ecommerceIdArray = ecommerceIdArray.filter(Boolean); // Remove null, undefined, '' values in ecommerceId
    if (ecommerceIdArray.length > 0) {
      for (let i = 0; i < ecommerceIdArray?.length; i++) {
        formDataSend.append(`ecommerceId[${i}]`, ecommerceIdArray[i]?.trim() === '' ? null : ecommerceIdArray[i]);
      }
    } else {
      formDataSend.append(`ecommerceId[0]`, '');
    }

    if (formData?.composition) {
      Object.keys(formData?.composition).forEach((langKey) => {
        const compositionArray = formData.composition[langKey];
        if (compositionArray && Array.isArray(compositionArray)) {
          if (compositionArray.length === 0) {
            formDataSend.append(`composition[${langKey}][]`, '');
          } else {
            compositionArray.forEach((comp, index) => {
              formDataSend.append(`composition[${langKey}][${index}][name]`, comp.name || '');
              formDataSend.append(`composition[${langKey}][${index}][percentage]`, comp.percentage || '');
            });
          }
        }
      });
    }

    if (formData?.careInstructions?.length && formData?.careInstructions !== undefined) {
      for (let i = 0; i < formData?.careInstructions?.length; i++) {
        formDataSend.append(`careInstructions[${i}]`, formData.careInstructions[i]);
      }
    }
    Object.keys(formData?.title).forEach((key) => {
      if (formData.title[key] !== undefined && formData.title[key] !== null) {
        formDataSend.append(`title[${key}]`, formData.title[key]);
      } else {
        formDataSend.append(`title[${key}]`, '');
      }
    });

    if (formData?.weave !== undefined && formData?.weave !== null) {
      Object.keys(formData.weave).forEach((key) => {
        const value = formData.weave[key];
        if (value !== undefined && value !== null) {
          formDataSend.append(`weave[${key}]`, value);
        }
      });
    }

    formDataSend.append('lang', lang);
    if (formData?.collection !== undefined && formData?.collection !== null) {
      formDataSend.append('collectionName', formData?.collection?.trim());
    }

    Object.keys(formData.colors).forEach((key) => {
      const colorValue = formData.colors[key]?.trim();
      if (colorValue !== undefined && colorValue !== null) {
        formDataSend.append(`colors[${key}]`, colorValue === '' ? '' : formData.colors[key]);
      }
    });

    if (formData.category !== '' && formData.category !== undefined && formData.category !== null) formDataSend.append('category', formData.category);
    if (formData.additionalCareInstructions !== undefined && formData.additionalCareInstructions !== null)
      formDataSend.append('additionalCareInstructions', formData?.careInfo?.trim() === '' ? null : formData.careInfo);
    formDataSend.append(`weight[unit]`, formData.weightUnit);
    formDataSend.append(`weight[value]`, formData.weight);
    formDataSend.append('price[value]', formData.price);
    formDataSend.append('price[unit]', formData.priceUnit);
    formDataSend.append('isArchived', formData.isArchived);
    try {
      if (!checkOnlineStatus()) return;
      const response = await httpService.patch(`${RestUrlsConstants.product}/${decryptId(params.id)}`, formDataSend, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setFormData({});
        setSkuId('');
        setImageUrls([]);
        navigate(`${PATH.PRODUCTENGINE}/${currentPage}`);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleFormStatus = () => {
    setFormData({ ...formData, isArchived: !formData.isArchived });
  };
  const submitProduct = () => {
    if (!checkOnlineStatus()) return;
    if (updateMode) {
      updateProduct();
    } else {
      createProduct();
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<IoMdArrowBack />}
            onClick={() => navigate(`${PATH.PRODUCTENGINE}/${currentPage}`)}
            sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2 }}
          >
            {t('back')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <LanguageSelector setLang={setLang} selectLang={lang} />
              </Box>

              <Box mt={2}>
                <CommonInput
                  label={
                    <span>
                      {t('product_title') + '*'}
                      <Tooltip title={t('product_title')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('product_title')}
                  value={formData?.title ? formData?.title[lang] : ''}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 25) {
                      setFormData({
                        ...formData,
                        title: {
                          ...formData?.title,
                          [lang]: inputValue,
                        },
                      });
                    }
                  }}
                  error={!!errors.title}
                  helperText={errors.title}
                />

                <CommonInput
                  label={
                    <span>
                      {t('enter_sku') + '*'}
                      <Tooltip title={t('sku_id')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('mandatory_for_qr_generation')}
                  value={formData.skyQRcode}
                  type="text"
                  variant="standard"
                  disabled={updateMode && skuId}
                  autoComplete={false}
                  handleChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 25) {
                      setFormData({ ...formData, skyQRcode: inputValue });
                    }
                  }}
                  error={!!errors.skyQRcode}
                  helperText={errors.skyQRcode}
                />
                <CommonInput
                  label={
                    <span>
                      {t('e_commerce_id') + '*'}
                      <Tooltip title={t('ecommerce_id_tool')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('ecommerce Id')}
                  value={formData.ecommerceId}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => {
                    setFormData({ ...formData, ecommerceId: e.target.value });
                  }}
                  error={!!errors.ecommerceId}
                  helperText={errors.ecommerceId}
                />
                <CommonInput
                  label={
                    <span>
                      {t('insert_collection')}
                      <Tooltip title={t('collection_name')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('enter_collection_name')}
                  value={formData.collection}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => setFormData({ ...formData, collection: e.target.value })}
                />
                <CommonInput
                  label={
                    <span>
                      {t('weave')}
                      <Tooltip title={t('weave_tool')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('weave')}
                  value={formData?.weave ? formData?.weave[lang] : ''}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => {
                    setFormData({
                      ...formData,
                      weave: {
                        ...formData?.weave,
                        [lang]: e.target.value,
                      },
                    });
                  }}
                />

                <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                  {t('composition')}{' '}
                  <Tooltip title={t('composition')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>
                <Box display="flex" flexWrap="wrap" sx={{ borderBottom: '1px solid gray', pt: 1 }} gap={1}>
                  {Array.isArray(formData?.composition?.[lang]) &&
                    formData.composition[lang].map((item, index) => <Chip sx={{ mb: '2px' }} key={index} label={`${item?.name} - ${item?.percentage}%`} onDelete={() => handleDelete(index)} />)}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', height: '50px' }} gap={2} mb={2}>
                  <CommonInput placeholder={t('material')} value={name} type="text" variant="standard" autoComplete={false} handleChange={(e) => setName(e.target.value)} />
                  <CommonInput
                    placeholder={t('percentage')}
                    value={percentage}
                    type="number"
                    variant="standard"
                    autoComplete={false}
                    handleChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[+-]/g, '');
                      const regex = /^(?!0\.00$)(?!0\.0+$)([1-9][0-9]{0,2}|0)(\.[0-9])?$/;
                      if (value === '0.00' || value === '0.0000') {
                        setPercentage('');
                      } else if (regex.test(value) || value === '') {
                        const floatValue = parseFloat(value);
                        if (value === '' || (!isNaN(floatValue) && floatValue <= 100)) {
                          setPercentage(floatValue);
                        }
                      }
                    }}
                  />
                  <Button sx={{ mt: 4, background: '#0e1090', borderRadius: '20px', ':hover': { background: '#0e1090' } }} size="small" variant="contained" onClick={handleAdd}>
                    {t('add')}
                  </Button>
                </Box>

                <Box sx={{ width: '100px' }}>
                  <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                    {t('archive')}
                    <Tooltip title={t('archive_tool')} placement="top-start">
                      <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                    </Tooltip>
                  </InputLabel>
                  <CommonSwitch checkValue={formData.isArchived} onChange={() => handleFormStatus()} />
                </Box>
                <Typography onClick={() => handleAdditionalSetting()} mt={4} sx={{ color: '#0e1090', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', width: '200px' }}>
                  {t('additional_information')} {showAdditionalSetting ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
                {showAdditionalSetting && (
                  <Box>
                    <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                      {t('care_instruction')}
                      <Tooltip title={t('care_instruction')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </InputLabel>
                    <Autocomplete
                      multiple
                      id="care-instructions"
                      options={careInstructions}
                      freeSolo
                      getOptionLabel={(option) => option?.value || option}
                      value={formData.careInstructions?.map((id) => careInstructions?.find((item) => item?.id === id))}
                      onChange={(event, newValue) => {
                        const updatedValue = newValue
                          ?.map((option) => {
                            if (typeof option === 'string') {
                              return null;
                            }
                            return option.id;
                          })
                          .filter((id) => id !== null);
                        setFormData({ ...formData, careInstructions: updatedValue });
                      }}
                      renderTags={(value, getTagProps) => value?.map((option, index) => <Chip variant="contained" label={option?.value} {...getTagProps({ index })} />)}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                    <CommonInput
                      label={
                        <span>
                          {t('additional_care_info')}
                          <Tooltip title={t('additional_care_info')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder={t('enter_detail_here')}
                      value={formData?.careInfo}
                      type="text"
                      variant="standard"
                      autoComplete={false}
                      handleChange={(e) => setFormData({ ...formData, careInfo: e.target.value })}
                    />
                    <Grid container>
                      <Grid item xs={10}>
                        <CommonInput
                          label={
                            <span>
                              {t('weight')}
                              <Tooltip title={t('weight')} placement="top-start">
                                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                              </Tooltip>
                            </span>
                          }
                          placeholder={t('enter_detail_here')}
                          value={formData.weight}
                          type="text"
                          variant="standard"
                          autoComplete="off"
                          handleChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/[+-]/g, '');
                            const regex = /^(?!0\.00$)([1-9][0-9]{0,2}|0)(\.[0-9]{0,2})?$/;
                            if (regex.test(value) || value === '') {
                              const floatValue = parseFloat(value);
                              if (value === '' || (!isNaN(floatValue) && floatValue <= 999.99)) {
                                setFormData({ ...formData, weight: value });
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            fullWidth
                            sx={{ border: 'none' }}
                            size="small"
                            value={formData.weightUnit}
                            onChange={(e) => setFormData({ ...formData, weightUnit: e.target.value })}
                            autoWidth
                          >
                            {weightUnit?.map((unit) => (
                              <MenuItem value={unit}>{unit}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <CommonInput
                      label={
                        <span>
                          {t('colors')}
                          <Tooltip title={t('color_tool')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder={t('colors')}
                      value={formData?.colors ? formData?.colors?.[lang] : ''}
                      type="text"
                      variant="standard"
                      autoComplete={false}
                      // handleChange={(e) => setFormData({ ...formData, colors: e.target.value })}
                      handleChange={(e) => {
                        setFormData({
                          ...formData,
                          colors: {
                            ...formData?.colors,
                            [lang]: e.target.value,
                          },
                        });
                      }}
                    />

                    <CommonSelect
                      label={
                        <span>
                          {t('product_category')}
                          <Tooltip title={t('category')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      options={categoryList}
                      value={formData.category}
                      handleChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    />
                    <Grid container>
                      <Grid item xs={10}>
                        <CommonInput
                          label={
                            <span>
                              {t('price')}
                              <Tooltip title={t('price')} placement="top-start">
                                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                              </Tooltip>
                            </span>
                          }
                          placeholder={t('price')}
                          value={formData.price}
                          type="number"
                          variant="standard"
                          autoComplete={false}
                          handleChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            fullWidth
                            sx={{ border: 'none' }}
                            size="small"
                            value={formData.priceUnit}
                            onChange={(e) => setFormData({ ...formData, priceUnit: e.target.value })}
                            autoWidth
                          >
                            {priceUnit?.map((unit) => (
                              <MenuItem value={unit}>{unit}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box sx={{ mt: 2, display: 'flex', gap: '10px' }}>
                <Button
                  startIcon={<HandIcon color="#fff" />}
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090', height: 'auto' }, px: 2 }}
                  onClick={submitProduct}
                >
                  {t('save')}
                </Button>
                {formData.supplierChainId && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090', height: 'auto' }, px: 2 }}
                    onClick={() => {
                      if (!checkOnlineStatus()) return;
                      navigate(`${PATH.SUPPLYCHAINGRAPH}/${encryptId(formData.supplierChainId)}/1/${currentPage}?from=product`);
                    }}
                  >
                    {t('edit_supply_chain')}
                  </Button>
                )}
                {formData.qrUrl && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090', height: 'auto' }, px: 2 }}
                    onClick={() => {
                      if (!checkOnlineStatus()) return;
                      window.open(formData.qrUrl.replace(/https?:\/\/[^/]+(\/portalfiles\/.+?)\.png$/, 'https://myimpact.renoon.com$1.html'));
                    }}
                  >
                    {t('view_digital_product_passport')}
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('attach_image')}</Typography>
              <Box mb={2}>
                {imageUrls?.length > 0 && (
                  <Box sx={{ borderRadius: '10px' }}>
                    <img src={selectImage} alt="" style={{ borderRadius: '10px' }} width="100%" />
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {imageUrls?.map((url, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: '80px',
                        width: '80px',
                        borderRadius: '8px',
                        mt: 2,
                        position: 'relative',
                      }}
                    >
                      <img
                        src={url}
                        alt=""
                        height="80px"
                        width="80px"
                        style={{ border: selectImage === url ? '2px solid red' : 'none', borderRadius: '8px' }}
                        onClick={() => {
                          setSelectImage(url);
                          setShowTitle(index === 0);
                        }}
                      />
                      {selectImage === url && (
                        <Typography
                          sx={{
                            lineHeight: '80px',
                            width: '80px',
                            textAlign: 'center',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#fff',
                            background: 'rgba(0,0,0,.5)',
                            borderRadius: '8px',
                            position: 'absolute',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                          }}
                        >
                          {showTitle && 'Default'}
                        </Typography>
                      )}
                      <CloseIcon
                        onClick={(e) => {
                          removeImage(index, url.startsWith('https'));
                        }}
                        sx={{
                          cursor: 'pointer',
                          fontSize: '16px',
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          color: 'red',
                          zIndex: 200,
                          background: '#fff',
                          borderRadius: '5px',
                          border: '.5px solid gray',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <input ref={fileInputRef} multiple type="file" accept="image/png, image/jpeg, image/png" hidden onChange={handleFileInput} />
              {errors.file && (
                <Typography variant="body2" color={'error'}>
                  {errors.file}
                </Typography>
              )}
              <Button
                sx={{
                  height: '80px',
                  width: '80px',
                  color: '#fff',
                  background: '#000',
                  ':hover': { background: '#222' },
                }}
                variant="contained"
                onClick={() => {
                  fileInputRef.current.click();
                  setErrors({ file: '' });
                }}
              >
                <AddIcon sx={{ color: '#fff' }} />
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default CreateProduct;
