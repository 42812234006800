import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  suppliers: [],
  loading: false,
  error: null,
};

export const fetchSupplier = createAsyncThunk(
  'suppliers/fetchSupplier',
  async (claimId, { rejectWithValue }) => {
    try {
      let url = `${RestUrlsConstants.supplier}`;
      if (typeof claimId === 'string' && claimId.trim() !== '') {
        url += `?productClaimId=${encodeURIComponent(claimId)}`;
      }

      const response = await httpService.get(url, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);



export const fetchSupplierWithSearch = createAsyncThunk('suppliers/fetchSupplierWithSearch', async ({ term }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?query=${term}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchSupplierFilterSupplier = createAsyncThunk('suppliers/fetchSupplierFilterSupplier', async ({ page, itemsPerPage, filterValue, claimId }, { rejectWithValue }) => {
  try {
    const queryParams = Object.entries(filterValue)
      .filter(([key, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => {
        if (key === 'isSelected') {
          return value === 'Yes' ? `claimId=${claimId}` : value === 'No' ? `noClaimId=${claimId}` : '';
        }
        if (value === 'Yes' || value === true) return `${key}=true`;
        if (value === 'No' || value === false) return `${key}=false`;

        return `${key}=${value}`;
      })
      .filter(Boolean);
    const queryString = queryParams.length ? `&${queryParams.join('&')}` : '';
    const url = `${RestUrlsConstants.supplier}/?page=${page}&limit=${itemsPerPage}${queryString}`;

    const response = await httpService.get(url, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data || error.message);
  }
});

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierFilterSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierFilterSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierFilterSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplierSlice.reducer;
