import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  productData: [],
  loading: false,
  error: null,
};

export const fetchSupplierProduct = createAsyncThunk(
  'supplier/product',
  async ({supplierId,page},{ rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplierProductWithSearch = createAsyncThunk(
  'supplier/productWithSearch',
  async ({supplierId,term}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product/?query=${term}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplierProductWithStage = createAsyncThunk(
  'supplier/productWithStage',
  async ({supplierId,stage}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product/?stage=${stage}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplierProductWithType = createAsyncThunk(
  'supplier/productWithType',
  async ({supplierId,type}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product/?type=${type}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplierProductWithSort = createAsyncThunk(
  'supplier/productWithSort',
  async ({supplierId,sort}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product/?sortBy=${sort}&orderBy=${"asc"}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchSupplierProductWithSupplyChain = createAsyncThunk(
  'supplier/productWithSupplyChain',
  async ({supplierId,chain}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}/product/?supplyChain=${chain}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const supplierProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductWithSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductWithSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProductWithSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductWithStage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductWithStage.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProductWithStage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductWithType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductWithType.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProductWithType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductWithSort.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductWithSort.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProductWithSort.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductWithSupplyChain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductWithSupplyChain.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchSupplierProductWithSupplyChain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplierProductSlice.reducer;
