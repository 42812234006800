import * as React from 'react';
import { IconButton, MenuItem, Box, Typography, Button, ListItemText, Popover, InputLabel, FormControl, Select } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';

export default function FilterLCA(props) {
  const { handleFilterProductCollection, minWidth, selectedItems, fetchAllLCA } = props;
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const open = Boolean(menuAnchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [created, setCreated] = React.useState('');

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleFilterProduct = (filterType, value) => {
    if (filterType === 'created') setCreated(value);
    handleFilterProductCollection(filterType === 'created' ? value : created);
    handleClose();
  };

  const handleClearAll = () => {
    fetchAllLCA(selectedItems);
    handleClose();
    setCreated('');
  };

  return (
    <Box minWidth={minWidth}>
      <IconButton onClick={handleMenuOpen} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>

          <InputLabel sx={{ fontSize: '14px' }}>{t('created')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={created} onChange={(e) => handleFilterProduct('created', e.target.value)}>
              <MenuItem value={'date'}>Date</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </Box>
  );
}
