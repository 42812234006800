import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import icons
import { IoMdArrowBack } from 'react-icons/io';
//relative path imports
import PATH from '../../utils/path';
import LcaProductList from './LcaProductList';

//create mui styled components
const StyledBackButton = styled(Button)(({ theme }) => ({
  bgcolor: 'none',
  color: '#000',
  textTransform: 'capitalize',
  px: theme.spacing(2),
  '&:hover': {
    bgcolor: 'none',
  },
}));

const EditDraft = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <StyledBackButton startIcon={<IoMdArrowBack />} onClick={() => navigate(`${PATH.LIFECYCLEASSESSMENT}`)}>
        {t('back')}
      </StyledBackButton>
      <Typography mt={2} fontWeight="700" variant="h5" color="#0E1090">
        Select Recommanded Products
      </Typography>
      <LcaProductList selectedTab={1} />
    </Box>
  );
};

export default EditDraft;
