import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
 const {btnName,description,title,open,handleCloseDialog,handleClick}=props;
const {t}=useTranslation();
  return (
    <React.Fragment>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 1500,
          '& .css-12pf3jm': { zIndex: 1500 },
        }}
        
      >
        <DialogTitle sx={{color:"#000",fontWeight:"bold",fontSize:"20px"}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size='small' variant='contained' sx={{bgcolor:"red",":hover":{background:"#ed3e46"}}} onClick={handleClick}>{btnName}</Button>
          <Button size='small' variant='outlined' onClick={handleCloseDialog}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
