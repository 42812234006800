import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
//import icons
import { FaEdit } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import DEFAULT_IMAGE from '../../../images/default_product_image.png';
import NOFOUNDIMG from '../../../images/no_product_found.png';
//relative path imports
import AssesmentFooter from '../../utils/AssesmentFooter';

// create mui styled components
const StyledButton = styled(Button)(({ isBgColor }) => ({
  backgroundColor: isBgColor,
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: isBgColor },
}));

const StyledProductCard = styled(Paper)(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  cursor: 'pointer',
  outline: isSelected && `1px solid #0E1090`,
  minHeight: '100%',
}));

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  fontWeight: '700',
}));

const EditDraftBtn = styled(Button)(({}) => ({
  color: '#ffffff',
  textTransform: 'capitalize',
  backgroundColor: '#0E1090',
  width: '100%',
  height: '26px',
  padding: '4px 8px',
  borderRadius: '100px',
  '&:hover': {
    backgroundColor: '#0E1090',
    boxShadow: 'none',
  },
}));

const AssessmentStep2 = ({ products, selectedProducts, setSelectedProducts, handleSubmit, productCount, page, totalPages, handleChange, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (data?.linkedProductIds) {
      setSelectedProducts((prevSelected) => {
        const updatedSelected = [...prevSelected];

        data?.linkedProductIds?.forEach((productId) => {
          if (!updatedSelected?.includes(productId)) {
            updatedSelected?.push(productId);
          }
        });

        return updatedSelected;
      });
    }
  }, [data?.linkedProductIds]);

  const toggleProductSelection = (productId) => {
    setSelectedProducts((prevSelected) => (prevSelected.includes(productId) ? prevSelected.filter((id) => id !== productId) : [...prevSelected, productId]));
  };

  const onNext = (isDraft = false) => {
    handleSubmit({ linkedProductIds: selectedProducts }, isDraft);
  };

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <CardContent>
            <Typography fontWeight="700" variant="h5" color="#0E1090">
              Select Recommended Products
            </Typography>
            <Typography mt={1} fontSize={12}>
              Products with the same weight, composition, and category can be analyzed together. please select any additional product below:
            </Typography>

            <Box mt={4}>
              {products?.length > 0 ? (
                <Grid container columnSpacing={9} rowSpacing={4}>
                  {products.map((ele, index) => {
                    const isSelected = selectedProducts.includes(ele.id);
                    return (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
                        <StyledProductCard isSelected={isSelected} onClick={() => toggleProductSelection(ele.id)}>
                          <img src={ele?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={100} height={100} style={{ objectFit: 'cover' }} />
                          <Typography mt={1} fontWeight={'700'}>
                            {ele?.title?.value}
                          </Typography>
                          <Stack mt={1}>
                            {ele?.weight?.value !== '' && (
                              <StyledProductDescText>
                                {t('weight')}&nbsp;:&nbsp;{ele?.weight?.value}&nbsp;{ele?.weight?.unit}
                              </StyledProductDescText>
                            )}
                            {ele?.category && (
                              <StyledProductDescText>
                                {t('category')}&nbsp;:&nbsp;{ele?.category}
                              </StyledProductDescText>
                            )}
                            {ele.composition?.value?.length > 0 && ele?.composition?.value[0]?.percentage !== '' && (
                              <StyledProductDescText>
                                {t('composition')}&nbsp;:&nbsp;{ele?.composition?.value[0]?.percentage}
                              </StyledProductDescText>
                            )}
                            {ele?.qrId && ele?.qrId !== '' && (
                              <StyledProductDescText>
                                {t('sku')}&nbsp;:&nbsp;{ele?.qrId}
                              </StyledProductDescText>
                            )}
                          </Stack>
                          <Stack mt={2} direction={'row'} alignItems={'center'} spacing={1}>
                            <EditDraftBtn variant="contained">
                              <Typography pr={2} fontSize={10} fontWeight={'700'}>
                                {t('edit_draft')}
                              </Typography>
                              <FaEdit style={{ fontSize: '12px' }} />
                            </EditDraftBtn>
                            <FiInfo style={{ fontSize: '26px' }} />
                          </Stack>
                        </StyledProductCard>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Stack alignItems={'center'} justifyContent={'center'}>
                  <Box position="relative" display="inline-block">
                    <img
                      src={NOFOUNDIMG}
                      alt="No product found"
                      style={{
                        height: 300,
                        width: 300,
                        objectFit: 'cover',
                      }}
                    />
                    <Box position="absolute" top="35%" left="35%" transform="translate(-35%, -35%)" display="flex" alignItems="center" justifyContent="center">
                      <IoMdCloseCircleOutline size={100} color="red" />
                    </Box>
                  </Box>
                  <Typography mt={2} color={'#CD0F24'} textAlign={'center'} fontWeight={'700'}>
                    No Similar Products Found
                  </Typography>
                </Stack>
              )}
            </Box>
          </CardContent>
          <Box my={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {productCount > 0 && (
              <Pagination
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: 'gray',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EC895A',
                    color: '#fff',
                  },
                }}
                count={totalPages}
                page={page}
                onChange={handleChange}
              />
            )}
          </Box>
        </Card>
      </Box>

      <AssesmentFooter onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
    </Box>
  );
};

export default AssessmentStep2;
