const dutchTranslation = {
  home: 'Thuis',
  user_management: 'Benutzerwaltung',
  user: 'Benutzer',
  brand: 'Marke',
  role: 'Rolle',
  measures: 'Maßnahmen',
  claim_management: 'Anspruchsmanagement',
  order_engine: 'Bestellverwaltung',
  product_engine: 'Produktverwaltung',
  create_new_product: 'Neues Produkt erstellen',
  supplier_map: 'Lieferantenübersicht',
  compliance: 'Regelkonformität',
  over_view: 'Übersicht',
  product_compliance: 'Produktkonformität',
  reports: 'Berichte',
  reporting: 'Berichterstattung',
  product_lca: 'Produkt-LCA (Lebenszyklus-Analyse)',
  clustring: 'Clustering',
  impact_label: 'Impact-label',
  eCom_widget: 'E-com-widget',
  qr_codes: 'QR-code',
  analytics: 'Analyses',
  settings: 'Einstellungen',
  back: 'Zurück',
  demo_account: 'Demo-account',
  your_product: 'Uw product',
  retailer: 'Detailhandelaar',
  status: 'Status',
  approved: 'Goedgekeurd',
  approve: 'Goedkeuren',
  submit_for_review: 'Zur Überprüfung einreichen',
  submitted_for_review: 'Zur Überprüfung eingereicht',
  verified: 'Verifiziert',
  pending: 'Ausstehend',
  percentile: 'percentiel',
  renoon_score: 'Renoon-score',
  brand_name: 'Markenname',
  contract_start_date: 'Vertragsbeginn',
  contract_end_date: 'Vertragsende',
  max_products: 'Max-Produkte',
  max_users: 'Max-Nutzer',
  features: 'Funktionen',
  contract_files: 'Vertragsdatei',
  logo: 'Logo',
  actions: 'Aktionen',
  add_brand: 'Marke hinzufügen',
  brands: 'MARKEN',
  page: 'Seite',
  download: 'Herunterladen',
  update_brand: 'MARKE AKTUALISIEREN',
  brand_onboarding_form: 'MARKENEINFÜHRUNGS-FORMULAR',
  update: 'Aktualisieren',
  submit: 'Einreichen',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  duplicate: 'Duplicaat',
  delete_brand: 'Marke löschen',
  are_you_sure_you_want_to_delete_this_brand: 'Sind Sie sicher, dass Sie diese Marke löschen möchten?',
  are_you_sure_you_want_to_delete_this_user: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
  brand_name_required: 'Markenname ist erforderlich',
  contract_start_date_required: 'Vertragsbeginn ist erforderlich',
  contract_end_date_required: 'Vertragsende ist erforderlich',
  max_product_required: 'Maximale Anzahl an Produkten ist erforderlich',
  max_user_required: 'Maximale Anzahl an Benutzern ist erforderlich',
  features_required: 'Funktionen sind erforderlich',
  contract_files_required: 'Vertragsdatei ist erforderlich',
  logo_required: 'Logo ist erforderlich',
  add_role: 'Rolle hinzufügen',
  role_name: 'Rollenname',
  resources: 'Ressourcen',
  loading: 'Laden',
  roles: 'ROLLEN',
  edit: 'Bearbeiten',
  update_role: 'Rolle aktualisieren',
  create_role: 'Rolle erstellen',
  role_form: 'Rol creëren',
  add_users: 'Benutzer hinzufügen',
  update_user: 'Benutzer aktualisieren',
  user_onboarding_form: 'Formular für die Benutzereinführung',
  first_name: 'Vorname',
  last_name: 'Nachname',
  email_address: 'E-Mail Adresse',
  password: 'Passwort',
  select_role: 'Rolle auswählen',
  select_brand: 'Marke auswählen',
  create_user_account: 'Benutzerkonto erstellen',
  users: 'BENUTZER',
  email: 'E-Mail',
  begin: 'Beginnen',
  manage: 'Verwalten',
  goals_targets: 'Ziele und Zielvorgaben',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Ihr kontinuierliches Engagement für Transparenz wird hier fortgesetzt',
  goals_sets: 'Zielvorgaben',
  certifications_and_proof_points: 'Zertifizierungen und Nachweispunkte',
  select_category: 'Anspruch auswählen',
  suggested_category: 'Vorgeschlagene Ansprüche',
  discovery_mode: 'Entdeckungsmodus',
  questions_answered: 'Beantwortete Fragen',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Selecteer de waarden die u wilt claimen om uw gegevens eenvoudig samen te voegen in het onderstaande gedeelte - worden automatisch bijgewerkt.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'Bekijk en beantwoord zoveel mogelijk vragen om te ontdekken welke waarden u kunt claimen op basis van uw gegevens en bewijsdocumentatie.',
  next: 'Weiter',
  prev: 'Vorherige',
  uploaded: 'Hochgeladen',
  you_have_set_this_as_a_goal: 'Sie haben dies als Ziel festgelegt!',
  set_as_future_goal: 'Als zukünftiges Ziel festlegen',
  choose_file: 'Datei auswählen',
  file_types: 'pdf, doc, docx, csv, txt, xls, xlsx, ppt, pptx, png, jpg, jpeg, mp4, mov',
  extra_notes: 'Mehr beschreiben',
  pick_from_library: 'Aus Bibliothek auswählen',
  create_new: 'Neu erstellen',
  certificate_details: 'Zertifizierungsdetails',
  attachment: 'Anhang',
  insert_name: 'Namen einfügen',
  select_type: 'Typ auswählen',
  emitted_by: 'Ausgestellt von',
  insert_date_of_emission: 'Ausstellungsdatum einfügen',
  insert_expiration_date: 'Ablaufdatum einfügen',
  select_certificate_owner: 'Dokumenteneigentümer auswählen',
  create: 'Erstellen',
  attachment_is_required: 'Anhang ist erforderlich',
  name_is_required: 'Name ist erforderlich',
  document_type_is_required: 'Dokumenttyp ist erforderlich',
  emitted_by_is_required: 'Ausgestellt von ist erforderlich',
  emission_date_is_required: 'Ausstellungsdatum ist erforderlich',
  expiration_date_is_required: 'Ablaufdatum ist erforderlich',
  certificate_owner_is_required: 'Zertifikateigentümer ist erforderlich',
  pick_certificate: 'Dokument auswählen',
  preview: 'Vorschau',
  name: 'Name',
  date_of_emission: 'Ausstellungsdatum',
  expiration_date: 'Ablaufdatum',
  alerts: 'Warnungen',
  thirty_days_before_expiration_date: '30 Tage vor Ablaufdatum',
  validated: 'Gültig',
  form_issuing_today: 'Gültigkeit des Dokuments',
  certifications_and_proof_points_uploaded: 'Hochgeladene Zertifizierungen und Nachweispunkte',
  verified_date: 'Validierungsdatum',
  privacy: 'Privatsphäre',
  search: 'Suche',
  view_profile: 'Profil anzeigen',
  user_name: 'Benutzername',
  user_profile: 'Benutzerprofil',
  log_out: 'Abmelden',
  user_details: 'Benutzerdetails',
  full_name: 'Vollständiger Name',
  form_status: 'Status des Formulars',
  visibility: 'Sichtbarkeit',
  image: 'Bild',
  E_commerce_item_code: 'Online-Handel Artikelcode',
  title: 'Titel',
  supply_chain: 'Lieferkette',
  collection: 'Kollektion',
  uploaded_products: 'Hochgeladene Produkte',
  upload_product: 'Produkte hochladen',
  new_product: 'Neues Produkt',
  additional_product_facts_form: 'Formular für zusätzliche Produktinformationen',
  total: 'Gesamt',
  product_title: 'Produkttitel',
  enter_sku: 'SKU (Lagerhaltungseinheit) eingeben',
  e_commerce_id: 'Online Handel-ID (Identifikation)',
  item_code: 'Artikelcode',
  insert_collection: 'Kollektion einfügen',
  composition: 'Zusammensetzung',
  care_instruction: 'Onderhoudsinstructies',
  additional_care_info: 'Zusätzliche Pflegeinformationen',
  weight: 'Gewicht',
  colors: 'Farben',
  choose_category: 'Kategorie auswählen',
  price: 'Preis',
  save: 'Speichern',
  enter_detail_here: 'Voer hier de details in',
  enter_collection_name: 'Namen der Kollektion eingeben',
  mandatory_for_product_creation: 'Obligatorisch für die Widget-Installation',
  mandatory_for_qr_generation: 'Obligatorisch für die QR-Generierung',
  attach_image: 'Bild anhängen',
  upload_files: 'Dateien hochladen',
  upload_CSV_file: 'CSV-Datei hochladen',
  upload_images_zip: 'Bilder oder Zip-Datei hochladen',
  update_product: 'Produkt aktualisieren',
  material: 'Material',
  percentage: 'Prozentsatz',
  add: 'Hinzufügen',
  qr_id: 'QR-Kennung (ID/ Identifikation)',
  mandatory_ecommerceId: 'Obligatorische E-Handel ID',
  weave: 'Gewebe',
  title_is_required: 'Titel ist erforderlich',
  weave_is_required: 'Gewebe ist erforderlich',
  sku_id_is_required: 'SKU-ID (Kennung/Identifikation) ist erforderlich',
  ecommerce_id_is_required: 'Online-Handel ID (Kennung) ist erforderlich',
  cannot_contain_slash_or_underscore: 'Darf kein / oder _ enthalten',
  no_files_selected: 'Keine Datei ausgewählt',
  invalid_file_format: 'Ungültiges Dateiformat (nur .jpeg, .png unterstützt)',
  file_size_exceeds_limit: 'Dateigröße überschreitet das Limit von 1 MB',
  total_file_size_exceeds_limit: 'Gesamte Dateigröße überschreitet das Limit von 5 MB',
  active: 'Aktiv',
  archive: 'Archief',
  download_QR: 'QR herunterladen',
  no_product_found: 'Kein Produkt gefunden',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested: 'Dies sind die genehmigten, ausstehenden und abgelehnten Ansprüche. Bitte treffen Sie die angeforderten Maßnahmen.',
  requested_category: 'Angeforderte Ansprüche',
  submit_the_detail_and_wait_for_approval: 'Details einreichen und auf Genehmigung warten',
  submitted: 'Eingereicht',
  set_of_questions_to_be_answered: 'Zu beantwortende Fragen',
  verify: 'Überprüfen',
  categories_to_be_reviewed: 'Zu überprüfende Ansprüche',
  are_you_sure_you_want_to_delete_this_certificate: 'Sind Sie sicher, dass Sie dieses Dokument löschen möchten?',
  delete_certificate: 'Dokument löschen',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below: 'Wählen Sie den Wert aus, den Sie beanspruchen möchten, um Ihre Daten im untenstehenden Abschnitt einfach zu aggregieren',
  review_categories: 'Anspruch überprüfen',
  review: 'Überprüfung',
  categories: 'Anspruch',
  categories_and_claims: 'Anspruch',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting: 'Hinweis, bevor Sie beginnen: Sie können Ihre Fragen jederzeit vor der Einreichung speichern und bearbeiten.',
  enjoy_the_process: 'Genieße den Prozess.',
  eco_packaging: 'Öko-Verpackung',
  submit_the_detail: 'Details einreichen',
  claim_status: 'Status des Anspruchs',
  create_the_claim: 'Anspruch erstellen',
  save_claim: 'Anspruch speichern',
  reject_document: 'Dokument ablehnen',
  reject: 'Ablehnen',
  reason_for_rejection: 'Ablehnungsgrund',
  locally_made: 'Lokal hergestellt',
  reviewd_category: 'Überprüfte Ansprüche',
  document_already_uploaded: 'Dokument bereits hochgeladen',
  select_or_enter_a_claim: 'Wählen oder eine Forderung eingeben',
  reason: 'Grund',
  explain_for_selecting_the_particular_status_for_the_claim: 'Erkläre, warum der bestimmte Status für den Anspruch ausgewählt wurde',
  assign_a_label: 'Label zuweisen',
  label_name: 'Labelname',
  label_eg: 'Z. B. Kollektion 2024',
  description: 'Beschreibung',
  assign_document: 'Dokument zuweisen',
  document_attach: 'Dokument anhängen',
  assigned_document: 'Zugewiesenes Dokument',
  label_assigned: 'Zugewiesenes Label',
  all: 'Alle',
  supply_chain_management: 'Lieferkettenmanagement',
  supplier: 'Lieferanten',
  suppliers: 'Lieferanten',
  upload_csv: 'CSV hochladen',
  add_suppliers: 'Lieferanten hinzufügen',
  view_claims: 'Ansprüche anzeigen',
  add_proudcts: 'Waren oder Dienstleistungen hinzufügen',
  create_supply_chain: 'Lieferkette erstellen',
  suppliers_have_products_or_services: 'Lieferanten liefern Waren oder Dienstleistungen',
  claim_name: 'Name des Anspruch',
  claims: 'Anspruch',
  list_of_claims: 'Liste der Ansprüche',
  add_supplier: 'Lieferanten (Anbieter) hinzufügen',
  name_eg: 'Z. B.: Renoon',
  vanity_name: 'Name des Anbieters',
  products: 'Vebundene Produkte',
  website_url: 'Webseite-URL',
  website_eg: 'Z. B.: https://renoon.com',
  vat_eg: 'Z. B.: IT123456789',
  vat_code: 'Mehrwertsteuerkennzeichen',
  vat: 'VAT',
  address: 'Adresse',
  state: 'Bundesland',
  city: 'Stadt',
  region_country: 'Land/Region',
  street: 'Straße',
  upload_images: 'Bilder hochladen',
  contact: 'Kontakt',
  no_of_employee: 'Anzahl der Mitarbeiter',
  no_of_factories: 'Anzahl der Fabriken',
  add_a_new_supplier_by_submitting_this_details: 'Fügen Sie einen neuen Lieferanten hinzu, indem Sie diese Angaben übermitteln',
  add_new_products: 'Lieferprodukt hinzufügen',
  document: 'Dokument',
  select_products: 'Produkte auswählen',
  more_custom_properties_may_be_added_like_colors_etc: 'Weitere benutzerdefinierte Eigenschaften können hinzugefügt werden, wie z. B. Farben usw.',
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'Sie sind nicht immer relevant und nicht für das MVP erforderlich.',
  vanity_name_is_required: 'Alternativer Name ist erforderlich',
  website_url_is_required: 'Website-URL ist erforderlich',
  vat_is_required: 'MwSt. ist erforderlich',
  description_is_required: 'Beschreibung ist erforderlich',
  contact_name_is_required: 'Kontaktname ist erforderlich',
  contact_email_is_required: 'Kontakt-E-Mail ist erforderlich',
  please_enter_a_valid_email: 'Bitte geben Sie eine gültige E-Mail ein',
  street_is_required: 'Straße ist erforderlich',
  city_name_is_required: 'Name der Stadt ist erforderlich',
  country_is_required: 'Land ist erforderlich',
  state_is_required: 'Bundesland ist erforderlich',
  add_new: 'Neu hinzufügen',
  stage: 'Phase',
  sku: 'SKU',
  sku_eg: 'Z. B.: FW24CBSHEJ66',
  add_new_product_or_service: 'Waren oder Dienstleistungen hinzufügen',
  stage_is_required: 'Die Stufe ist erforderlich',
  delete_supplier: 'Lieferanten löschen',
  are_you_sure_you_want_to_delete_this_supplier: 'Sind Sie sicher, dass Sie diesen Lieferanten löschen möchten?',
  view_claim: 'Anspruch anzeigen',
  search_products: 'Waren suchen',
  create_product: 'Waren erstellen',
  create_service: 'Dienstleistung erstellen',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Sind Sie sicher, dass Sie dieses Lieferantenprodukt löschen möchten?',
  search_services: 'Dienstleistungen suchen',
  edit_supply_chain: 'Lieferkette bearbeiten',
  are_you_sure_you_want_to_delete_this_supply_chain: 'Sind Sie sicher, dass Sie diese Lieferkette löschen möchten?',
  delete_supply_Chain: 'Lieferkette löschen',
  continue: 'Fortfahren',
  select_the_supplier: 'Den Lieferanten/ Anbieter auswählen',
  edit_product: 'Dienstleistung bearbeiten',
  save_draft: 'Entwurf speichern',
  detailed_view: 'Detaillierte Ansicht',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'Sie sind nicht immer relevant und nicht für das MVP erforderlich.',
  add_products: 'Produkte hinzufügen',
  select_the_supplier_product: 'Wählen Sie den Dienst des Anbieters',
  is_in_supply_chain: 'Ist in der Lieferkette',
  country: 'Land',
  has_suppliers_products: 'Hat Lieferantenprodukte',
  is_selected: 'Is geselecteerd',
  sort_by: 'Sortieren nach',
  filter: 'Filtern',
  clear_all: 'Alles löschen',
  has_products: 'Hat Produkte',
  is_in_a_supply_chain: 'Ist in einer Lieferkette',
  service: 'Dienstleistung',
  product: 'Produkt',
  type: 'Produkttyp',
  has_suppliers: 'Hat Lieferanten/ Anbieter',
  are_you_sure_you_want_to_delete_this_chain_product: 'Sind Sie sicher, dass Sie das Produkt dieser Kette löschen möchten?',
  are_you_sure_you_want_to_delete_this_chain_supplier: "Sind Sie sicher, dass Sie den Lieferanten dieser Kette löschen möchten?",
  zoom_in: 'Hineinzoomen',
  zoom_out: 'Herauszoomen',
  reset: 'Zurücksetzen',
  no_certificate_found: 'Kein Dokument gefunden',
  reviewed_claims: 'Überprüfte Ansprüche',
  view_all: 'Alle ansehen',
  unique_Id: 'Eindeutige ID',
  view_docs: 'Dokumente ansehen',
  select_the_value_you_want_to_communicate: 'Wählen Sie die Werte aus, die Sie kommunizieren möchten. Wir kümmern uns um deren Validierung und Übersetzung in konforme Ansprüche.',
  edit_supplier: 'Lieferanten/ Anbieter bearbeiten',
  submit_for_approval: 'Zur Genehmigung einreichen',
  fill_the_forms: 'Formulare ausfüllen',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered:
    'Die folgenden Formulare enthalten alle relevanten Fragen zur Erlangung der ausgewählten Ansprüche sowie einige allgemeine Fragen. Die Formulare werden automatisch gespeichert. Sie können die Formulare einreichen, sobald alle Fragen beantwortet sind.',
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:'In diesem Abschnitt haben Sie Zugriff auf alle hochgeladenen Dokumente. Sie können problemlos überprüfen, welche Bescheinigungen demnächst auslaufen, Benachrichtigungen über das Ablaufdatum erhalten und Maßnahmen zur Erneuerung der Bescheinigungen vornehmen.Darüber hinaus haben Sie die Möglichkeit, alle mit diesen Dokumenten verbundenen Informationen zu bearbeiten und zu aktualisieren, um zu gewährleisten, dass alle Daten stets korrekt und aktuell sind.',
  documents: 'DOKUMENTE UND BESCHEINIGUNGEN',
  create_brand: 'Marke erstellen',
  select_claim: 'Anspruch auswählen',
  select_supplier: 'Lieferanten auswählen',
  select_product: 'Produkt auswählen',
  filter_by: 'Filtern nach',
  additional_settings: 'Zusätzliche Einstellungen',
  stage_vanity_name: 'Alternativer Name der Phase',
  vanity_title: 'Alternativtitel',
  has_not_product: 'Heeft geen product',
  read_more: 'Mehr lesen',
  read_less: 'Weniger lesen',
  add_user: 'Benutzer hinzufügen',
  files: 'Dateien',
  file_type: 'Dateityp',
  deselect_all: 'Alle abwählen',
  select_all: 'Alle auswählen',
  is_archived: 'Ist archiviert',
  qr_code: 'QR-Code',
  date: 'Datum',
  only_image_files_PNG_JPEG_JPG_are_allowed: 'Nur Bilddateien (PNG, JPG, JPEG) sind erlaubt.',
  only_PDF_files_are_allowed: 'Nur PDF-Dateien sind erlaubt.',
  download_qr: 'QR-Code herunterladen',
  no_category_found: 'Keine Ansprüche gefunden',
  yes: 'Ja',
  no: 'Nein',
  collectionName_: 'Name der Kollektion',
  date_: 'datum',
  title_: 'titel',
  name_: 'name',
  Name: 'Name',
  Date: 'Datum',
  Title: 'Titel',
  Stage: 'Phase',
  PRODUCT: 'PRODUKT',
  SERVICE: 'DIENSTLEISTUNG',
  Verified: 'Überprüft',
  Proven: 'Geprüft',
  Declared: 'Deklariert',
  Rejected: 'Abgelehnt',
  additional_information: 'Zusätzliche Informationen',
  upload: 'Hochladen',
  SUPPLIER: 'Lieferanten',
  SUPPLIER_CHAIN: 'Lieferkette',
  SUPPLIER_PRODUCT: 'LEVERANCIER-PRODUCT',
  BRAND_PRODUCT: 'MERKPRODUCT',
  Less_than_9: 'Weniger als 9',
  ten_to_49: '10 bis 49',
  fifty_to_249: '50 bis 249',
  More_than_250: 'Mehr als 250',
  VERIFIED: 'VERIFIZIERT',
  PROVEN: 'GEPRÜFT',
  DECLARED: 'DEKLARIERT',
  REJECTED: 'ABGELEHNT',
  PENDING: 'AUSSTEHEND',
  product_: 'Produkt +',
  supplier_: 'Lieferant +',
  email_is_required: 'E-mail is vereist',
  sku_id: 'SKU-Identifikation (ID)',
  collection_name: 'Name der Kollektion',
  category: 'Kategorie',
  Unselect_All: 'deselecteer Alles',
  are_you_sure_you_want_to_remove_all_products: 'Weet u zeker dat u alle producten die verband houden met deze claim verwijdert?',
  remove_all_the_products_connected_to_this_claim: 'Verwijder alle producten die verband houden met deze claim',
  are_you_sure_you_want_to_remove_all_suppliers: 'Weet u zeker dat u alle leveranciers die aan deze claim zijn gekoppeld, verwijdert?',
  remove_all_the_suppliers_connected_to_this_claim: 'Verwijder alle leveranciers die aan deze claim zijn gekoppeld',
  life_cycle_assessment: 'Levenscyclusanalyse',
  start_calculation: 'Berekening starten',
  upload_csv_file: 'CSV uploaden',
  view_results: 'Resultaten bekijken',
  key_matrix: 'Sleutelmatrix',
  total_of_all_te_elements: 'Totaal van alle te elementen',
  lca_type: 'Type',
  qty: 'Aantal',
  unit: 'Eenheid',
  Selected: 'Gekozen',
  max_lca_products: 'Max LCA-producten',
  product_category: 'Productcategorie',
  assessment_name_label: 'Beoordelingsnaam',
  assessment_name_placeholder: 'Voer Naam in',
  edit_draft: 'Bewerk concept',
  select_product_and_continue: 'Selecteer product en ga verder',
  indicator: 'Indicator',
  selected_product: 'U heeft 1 product geselecteerd',
  submit: 'Indienen',
  your_password_reset_link_is_ready: 'UW WACHTWOORD RESET LINK IS KLAAR.',
  just_check_your_email_for_reset_link: 'Controleer gewoon uw e-mail voor een reset-LINK.',
  forget_your_password: 'Wachtwoord vergeten?',
  easy_template_download: 'Eenvoudig sjabloon downloaden',
  easy_template_download_desc: `Het is een al lang bekend feit dat een lezer wordt afgeleid door de leesbare inhoud van een pagina wanneer hij naar de lay-out kijkt. Het punt van het gebruik van Lorem Ipsum is dat het een min of meer normale verdeling van letters heeft, in tegenstelling tot het gebruik van 'Inhoud hier, inhoud hier', waardoor het op leesbaar Engels lijkt. Veel desktop publishing-pakketten en webpagina-editors gebruiken nu Lorem Ipsum als hun standaardmodeltekst en een zoekfunctie f`,
  csv_template: 'CSV-sjabloon',
  how_to_upload_manual: 'Handleiding uploaden',
  how_to_upload_manual_desc: `Het is een al lang bekend feit dat een lezer wordt afgeleid door de leesbare inhoud van een pagina wanneer hij naar de lay-out kijkt. Het punt van het gebruik van Lorem Ipsum is dat het zo is`,
  download_pdf: 'PDF downloaden',
  upload_the_template_created: 'Upload de gemaakte sjabloon',
  updating: 'Updaten',
  creating: 'Creëren',
  select_recommanded_products: 'Selecteer Aanbevolen producten',
  search_for_assessment: 'Zoek naar beoordelingen',
  created: 'Gemaakt',
  archive_tool: 'Archiv - Archivierte Produkte haben weiterhin ein öffentliches DPP, falls bereits erstellt, sind jedoch nicht änderbar',
  ecommerce_id_tool: 'Online-Handel-ID - Trennen Sie jede ID mit einem ,',
  weave_tool: 'Webart - z.B. 3/1 Z Köper',
  color_tool: 'Farben - Trennen Sie jede Farbe mit einem ,',
  garment: 'Kledingstuk',
  type_of_garment: 'Soort kledingstuk',
  garment_code: 'Kledingcode',
  garment_title: 'Titel van kledingstuk',
  product_composition: 'Productsamenstelling',
  distribution: 'Verdeling',
  transport: 'Vervoer',
  distance: 'Afstand (km)',
  packaging: 'Verpakking',
  primary_packaging_used: 'Primaire verpakking gebruikt',
  quantity: 'Hoeveelheid',
  production_volume: ' Productievolume',
  consumption_of_water: 'Verbruik van water',
  consumption_of_natural_gas: 'Verbruik van aardgas',
  electricity: 'Elektriciteit',
  total_use: 'Totaal verbruik (kWh)',
  use_of_electricity_from_renewable_sources: 'Gebruik van elektriciteit uit hernieuwbare bronnen',
  do_you_use_electricity_from_renewable_sources: 'Maakt u gebruik van elektriciteit uit hernieuwbare bronnen?',
  use_of_electricity_from_pv: 'Gebruik van elektriciteit uit PV',
  do_you_have_a_pv_system_installed_in_your_production_site: 'Heeft u op uw productielocatie een PV-systeem geïnstalleerd?',
  share_of_electricity_from_pv: 'Aandeel elektriciteit uit PV',
  share_of_electricity_from_pv_desc: 'Geef aan welk deel van de elektriciteit die op uw productielocatie wordt gebruikt, afkomstig is van uw PV-systeem.',
  location_of_renewable_electricity_supply: 'Locatie van duurzame elektriciteitsvoorziening',
  location_of_renewable_electricity_supply_desc: 'Geef aan welk deel van de elektriciteit die op uw productielocatie wordt gebruikt, afkomstig is van uw PV-systeem.',
  share_of_electricity_from_renewable_sources: 'Aandeel elektriciteit uit hernieuwbare bronnen',
  share_of_electricity_from_renewable_sources_desc: 'Geef het aandeel elektriciteit aan dat u koopt van het nationale elektriciteitsnet en dat afkomstig is uit hernieuwbare bronnen',
  waste: 'Afval',
  share_of_electricity_from_renewable_sources: 'Aandeel elektriciteit uit hernieuwbare bronnen',
  waste_quantity: 'Afvalhoeveelheid (kg)',
  waste_recycling: 'Afval - recycling (%)',
  waste_incineration: 'Afval - verbranding',
  waste_landfill: 'Afval - stortplaats',
  dyeing: 'Verven',
  fabric_dyeing: 'Verven van stoffen',
  transportation_type: 'Transporttype',
  fabric_manufacturing: 'Fabricage van stoffen',
  yarn_count: 'Aantal garens (dtex)',
  yarn_manufacturing: 'productie van garen',
  spinning: 'Spinnen',
  raw_material_supply: 'Levering van grondstoffen',
  which_is_the_composition_of_the_garment_you_want_to_analyze: 'Wat is de samenstelling van het kledingstuk dat u wilt analyseren?',
  material_description: 'Materiaalbeschrijving',
  material_family: 'Materiële familie',
  material_type: 'Materiaalsoort',
  material_weight: 'Materiaal gewicht',
  material_percentage: 'Materiaal %',
  Waste_generation: 'Afvalproductie',
  weight_kg: 'Gewicht (kg)',
  this_product_already_selected_in_supply_chain: 'Dit product is al geselecteerd in de toeleveringsketen',
  view_product: 'Bekijk product',
  add_results: 'Resultaten toevoegen',
  no_product: 'Geen product',
  save_product: 'Product opslaan',
  are_you_sure_you_want_to_remove_all_prod: 'Weet u zeker dat u alle producten die aan deze leverancier zijn gekoppeld, verwijdert?',
  remove_all_the_products_connected_to_this_supplier: 'Verwijder alle producten die bij deze leverancier zijn aangesloten.',
  supplier_products: 'Producten van leveranciers',
  has_lca: 'Heeft LCA',
  has_supply_chain: 'Heeft toeleveringsketen',
  assign_result: 'Resultaten toewijzen',
  update_result: 'Resultaat bijwerken',
  upload_file: 'Bestand uploaden',
  bulk_upload_results: 'Bulkuploadresultaten voor het product',
  sku_number: 'SKU-nummer',
  upload_date: 'Uploaddatum',
  assigned_to: "Toegewezen aan"
};
export default dutchTranslation;
