import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
//relative path imports
import CommonInput from '../../utils/CommonInput';
import AssesmentFooter from '../../utils/AssesmentFooter';
import CommonSelect from '../../utils/CommonSelect';
import { five_digit_validation_regex, location, locationRenewableElectricity_option, weightUnit } from '../../../data';

// create mui styled components
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontWeight: '700',
}));

const InputDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const AssessmentStep5 = ({ handleSubmit, data }) => {
  const { t } = useTranslation();
  const [productionForm, setProductionForm] = useState({
    location: '',
    volume: '',
    waterConsumption: '',
    naturalGasConsumption: '',
    electicity: '',
    isElectricityUseFromRenewableSources: '',
    isPvSystemInstalled: '',
    pvElectricityShare: '',
    locationRenewableElectricity: '',
    renewableElectricityShare: '',
    isScrapQuantityCalculated: '',
    wasteQuantity: '',
    wasteRecyclingPercentage: '',
    wasteincineration: '',
    wasteLandfill: '',
  });


  useEffect(() => {
    if (data?.production) {
      setProductionForm((prevForm) => ({
        ...prevForm,
        ...data.production,
      }));
    }
  }, [data]);

  const handleElectricitySourceChange = (event) => {
    setProductionForm((prev) => ({
      ...prev,
      isElectricityUseFromRenewableSources: event.target.value === 'true',
    }));
  };

  const onNext = (isDraft = false) => {
    let obj = {
      location: productionForm.location,
      volume: productionForm.volume,
      waterConsumption: productionForm.waterConsumption,
      naturalGasConsumption: productionForm.naturalGasConsumption,
      electicity: productionForm.electicity,
      isElectricityUseFromRenewableSources: productionForm.isElectricityUseFromRenewableSources,
      isScrapQuantityCalculated: productionForm.isScrapQuantityCalculated,
     
    };

    if (productionForm.isElectricityUseFromRenewableSources) {
      obj = {
        ...obj,
        isPvSystemInstalled: productionForm.isPvSystemInstalled,
        pvElectricityShare: productionForm.pvElectricityShare,
        locationRenewableElectricity: productionForm.locationRenewableElectricity,
        renewableElectricityShare: productionForm.renewableElectricityShare,
      };
    }

    if (productionForm.isScrapQuantityCalculated) {
      obj = {
        ...obj,
        wasteQuantity: productionForm.wasteQuantity,
        wasteRecyclingPercentage: productionForm.wasteRecyclingPercentage,
        wasteincineration: productionForm.wasteincineration,
        wasteLandfill: productionForm.wasteLandfill,
      };
    }
    handleSubmit({ production: obj }, isDraft);

    setProductionForm({
      location: '',
      volume: '',
      waterConsumption: '',
      naturalGasConsumption: '',
      electicity: '',
      isElectricityUseFromRenewableSources: '',
      isPvSystemInstalled: '',
      pvElectricityShare: '',
      locationRenewableElectricity: '',
      renewableElectricityShare: '',
      isScrapQuantityCalculated: '',
      wasteQuantity: '',
      wasteRecyclingPercentage: '',
      wasteincineration: '',
      wasteLandfill: '',
    });
  };

  const isDisabled =
    !productionForm.location || !productionForm.volume || !productionForm.electicity || productionForm.isElectricityUseFromRenewableSources === '' || productionForm.isScrapQuantityCalculated === '';

  return (
    <Box>
      <StyledCard>
        <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
          {t('production')}
        </Typography>
        <Stack>
          <CommonSelect
            label={
              <span style={{ cursor: 'pointer' }}>
                {t('Location')}
                <span style={{ color: 'red' }}>*</span>
                <Tooltip title={'Please indicate the arrival location (intermediate if more than 1 transportation mode)'} placement="top-start">
                  <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                </Tooltip>
              </span>
            }
            options={location}
            value={productionForm.location}
            handleChange={(e) => setProductionForm({ ...productionForm, location: e.target.value })}
          />
          <Grid container>
            <Grid item xs={10}>
              <CommonInput
                label={
                  <span>
                    {t('production_volume')}
                    <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title={
                        'Please indicate the production volume (as total kg of products manufactured) of your production site in the year chosen for the analysis (e.g. last year). (Please use the same reference year as for the next questions on electricity and gas consumption.)'
                      }
                      placement="top-start"
                    >
                      <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                    </Tooltip>
                  </span>
                }
                value={productionForm.volume}
                type="text"
                placeholder="Enter Here"
                variant="standard"
                autoComplete={false}
                handleChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                    setProductionForm({ ...productionForm, volume: e.target.value });
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                <Select fullWidth sx={{ border: 'none' }} size="small" value="kg" disabled>
                  {weightUnit.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box>
            <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
              {t('consumption_of_water')}{' '}
              <Tooltip
                title={"Please indicate the amount of water (in m3) that your production plant consumed during last year. This information is usually available in your tap water supplier's bill."}
                placement="top-start"
              >
                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
              </Tooltip>
            </InputLabel>
            <CommonInput
              type="text"
              variant="standard"
              isSpacing
              placeholder="Enter Here"
              autoComplete={false}
              value={productionForm.waterConsumption}
              handleChange={(e) => {
                const value = e.target.value;
                if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                  setProductionForm({ ...productionForm, waterConsumption: e.target.value });
                }
              }}
            />
          </Box>
          <Box>
            <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
              {' '}
              {t('consumption_of_natural_gas')}{' '}
              <Tooltip
                title={
                  "Please indicate the amount of natural gas (in m3) that your production plant consumed during last year. This information is usually available in your natural gas supplier's bill."
                }
                placement="top-start"
              >
                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
              </Tooltip>
            </InputLabel>
            <CommonInput
              type="text"
              variant="standard"
              isSpacing
              placeholder="Enter Here"
              autoComplete={false}
              value={productionForm.naturalGasConsumption}
              handleChange={(e) => {
                const value = e.target.value;
                if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                  setProductionForm({ ...productionForm, naturalGasConsumption: e.target.value });
                }
              }}
            />
          </Box>
          <Box>
            <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>{t('electricity')}</InputLabel>
            <InputDescription>
              {' '}
              {t('total_use')} <span style={{ color: 'red' }}>*</span>
              <Tooltip
                title={
                  'Please indicate the amount of electricity that your production site used during last year (total kWh). This information is usually available in your electricity bill. If you have other sources of electricity (e.g. PV panels), please add also the electricity generated by the PV system and used in the production plant (NOT the electricity generated by the PV system and sold to the national grid).'
                }
                placement="top-start"
              >
                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
              </Tooltip>
            </InputDescription>
            <CommonInput
              type="text"
              variant="standard"
              isSpacing
              placeholder="Enter Here"
              autoComplete={false}
              value={productionForm.electicity}
              handleChange={(e) => {
                const value = e.target.value;
                if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                  setProductionForm({ ...productionForm, electicity: e.target.value });
                }
              }}
            />
          </Box>
          <Box>
            <InputDescription fontWeight={'700'}>
              {t('use_of_electricity_from_renewable_sources')}
              <span style={{ color: 'red' }}>*</span>
            </InputDescription>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography color={'#0E1090'}>{t('do_you_use_electricity_from_renewable_sources')}</Typography>
              <FormControl sx={{ mt: 2 }}>
                <RadioGroup row value={productionForm.isElectricityUseFromRenewableSources.toString()} onChange={handleElectricitySourceChange}>
                  <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
                  <FormControlLabel value="false" control={<Radio />} label={t('no')} />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>
          {productionForm.isElectricityUseFromRenewableSources && (
            <Box mx={2}>
              <Stack>
                <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
                  {t('use_of_electricity_from_pv')}{' '}
                  <Tooltip title={t('do_you_have_a_pv_system_installed_in_your_production_site')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>
                <FormControl sx={{ mt: 2 }}>
                  <RadioGroup row value={productionForm.isPvSystemInstalled} onChange={(e) => setProductionForm({ ...productionForm, isPvSystemInstalled: e.target.value === 'true' })}>
                    <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
                    <FormControlLabel value="false" control={<Radio />} label={t('no')} />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Box>
                <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
                  {t('share_of_electricity_from_pv')}{' '}
                  <Tooltip title={t('share_of_electricity_from_pv_desc')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>

                <CommonInput
                  type="text"
                  variant="standard"
                  isSpacing
                  placeholder="Enter Here"
                  autoComplete={false}
                  value={productionForm.pvElectricityShare}
                  handleChange={(e) => {
                    let value = e.target.value;
                    if (value === '') {
                      setProductionForm({ ...productionForm, pvElectricityShare: value });
                      return;
                    }
                    const numericValue = parseFloat(value);
                    const isValidFormat = /^\d+(\.\d{0,2})?$/.test(value);
                    if (isValidFormat && numericValue > 0 && numericValue <= 100) {
                      setProductionForm({ ...productionForm, pvElectricityShare: value });
                    }
                  }}
                />
              </Box>
              <Box>
                <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
                  {t('location_of_renewable_electricity_supply')}{' '}
                  <Tooltip title={t('location_of_renewable_electricity_supply_desc')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>

                <CommonSelect
                  isSpacing
                  options={locationRenewableElectricity_option}
                  value={productionForm.locationRenewableElectricity}
                  handleChange={(e) => setProductionForm({ ...productionForm, locationRenewableElectricity: e.target.value })}
                />
              </Box>
              <Box>
                <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
                  {t('share_of_electricity_from_renewable_sources')}
                  <Tooltip title={t('share_of_electricity_from_renewable_sources_desc')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>
                <CommonInput
                  type="text"
                  variant="standard"
                  isSpacing
                  placeholder="Enter Here"
                  autoComplete={false}
                  value={productionForm.renewableElectricityShare}
                  handleChange={(e) => setProductionForm({ ...productionForm, renewableElectricityShare: e.target.value })}
                />
              </Box>
            </Box>
          )}
        </Stack>
        <Box mt={4}>
          <Typography fontWeight={'700'}>{t('waste')}</Typography>
          <InputLabel>
            {t('Waste_generation')}
            <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <Stack mt={4} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box maxWidth={'500px'}>
              <Typography color={'#0E1090'}>Are you able to estimate the quantity of textile scraps generated by the manufacture of 1 piece of the garment that we are analyzing?</Typography>
            </Box>
            <FormControl sx={{ mt: 1 }}>
              <RadioGroup
                row
                value={productionForm.isScrapQuantityCalculated.toString()}
                onChange={(e) => setProductionForm({ ...productionForm, isScrapQuantityCalculated: e.target.value === 'true' })}
              >
                <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
                <FormControlLabel value="false" control={<Radio />} label={t('no')} />
              </RadioGroup>
            </FormControl>
          </Stack>

          {productionForm.isScrapQuantityCalculated && (
            <Box mx={2}>
              <Stack>
                <InputLabel sx={{ fontSize: '14px', cursor: 'pointer' }}>
                  {t('waste_quantity')}{' '}
                  <Tooltip title={'Please indicate the quantity of textile scraps (in kg) generated during the manufacture of 1 piece of the garment that we are analyzing'} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>
                <Grid container>
                  <Grid item xs={10}>
                    <CommonInput
                      value={productionForm.wasteQuantity}
                      type="text"
                      isSpacing
                      placeholder="Enter Here"
                      variant="standard"
                      autoComplete={false}
                      handleChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                          setProductionForm({ ...productionForm, wasteQuantity: e.target.value });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl size="small" fullWidth variant="filled">
                      <Select fullWidth sx={{ border: 'none' }} size="small" value="kg" disabled>
                        {weightUnit.map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>
              <Box>
                <Stack direction="row" spacing={2}>
                  <Box width={'33%'}>
                    <CommonInput
                      label={
                        <span>
                          {t('waste_recycling')}{' '}
                          <Tooltip title={'Please indicate the percentage of textile waste from manufacturing that is sent to recycling/ incineration / landfill'} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      value={productionForm.wasteRecyclingPercentage}
                      type="text"
                      placeholder="Enter Here"
                      variant="standard"
                      autoComplete={false}
                      fullWidth
                      handleChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                          setProductionForm({ ...productionForm, wasteRecyclingPercentage: e.target.value });
                        }
                      }}
                    />
                  </Box>
                  <Box width={'33%'}>
                    <CommonInput
                      label={
                        <span>
                          {t('waste_incineration')}
                          <Tooltip title={'Please indicate the percentage of textile waste from manufacturing that is sent to recycling/ incineration / landfill'} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      value={productionForm.wasteincineration}
                      type="text"
                      placeholder="Enter Here"
                      variant="standard"
                      autoComplete={false}
                      fullWidth
                      handleChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                          setProductionForm({ ...productionForm, wasteincineration: value });
                        }
                      }}
                    />
                  </Box>
                  <Box width={'33%'}>
                    <CommonInput
                      label={
                        <span>
                          {t('waste_landfill')}
                          <Tooltip title={'Please indicate the percentage of textile waste from manufacturing that is sent to recycling/ incineration / landfill'} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      value={productionForm.wasteLandfill}
                      type="text"
                      placeholder="Enter Here"
                      variant="standard"
                      autoComplete={false}
                      fullWidth
                      handleChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                          setProductionForm({ ...productionForm, wasteLandfill: e.target.value });
                        }
                      }}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          )}
        </Box>
      </StyledCard>

      <AssesmentFooter isDraftDisabled={isDisabled} isNextBtnDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
    </Box>
  );
};

export default AssessmentStep5;
