import React, { useState } from 'react';
import { Box, Button, Chip, Input, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonSelect from './CommonSelect';
import info from '../../images/Vector.png';
import { CommonInfoWrapper, CommonSquareChipsWrapper } from '../life_cycle_assessment/start_assessment/AssessmentStep4';

// Create MUI styled components
export const StyledButton = styled(Button)(({ isBgColor }) => ({
  backgroundColor: isBgColor,
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: isBgColor },
}));

export const StepTitle = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '24px',
  fontWeight: '700',
  color: '#0E1090',
}));

export const StepTabs = styled(Typography)(() => ({
  textTransform: 'capitalize',
  fontSize: '16px',
  fontWeight: '700',
  color: '#101010',
}));

export const InfoText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '400',
  color: '#808080',
  lineHeight: '14.06px',
  marginTop: '8px',
}));

export const SquareChips = styled(Chip)(({ customColor }) => ({
  backgroundColor: customColor ? customColor : '#808080',
  color: '#fff',
  '& .MuiChip-deleteIcon': {
    color: '#fff',
    fontSize: '16px',
    '&:hover': {
      color: '#fff',
    },
  },
}));

const LCAStep = () => {
  const { t } = useTranslation();

  const location = ['Bangalore', 'Mumbai', 'Delhi', 'Chennai', 'Hyderabad', 'Kolkata', 'Pune', 'Ahmedabad', 'Jaipur', 'Lucknow'];
  const transportationMethods = [
    'Transportation by Air',
    'Transportation by Sea',
    'Transportation by Rail',
    'Transportation by Road',
    'Transportation by Bus',
    'Transportation by Truck',
    'Transportation by Cargo Ship',
    'Transportation by Drone',
  ];

  const [formData, setFormData] = useState({
    location: '',
    distance: '',
    transportationMethods: '',
  });

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  return (
    <Box my={3}>
      <StepTitle>distribution</StepTitle>

      <Box my={3}>
        <StepTabs pb={4}>Transport</StepTabs>
        <Input
          placeholder="Enter Transport  Name"
          sx={{ width: '100%', paddingBottom: 3 }}
          variant="standard"
          endAdornment={
            <InputAdornment position="end">
              <StyledButton variant="contained" isBgColor="#0E1090">
                {t('ADD')}
              </StyledButton>
            </InputAdornment>
          }
        />
      </Box>

      {CommonSquareChipsWrapper([{ label: 'Box', customColor: '#0E1090', varient: '' }, { label: 'Plastic Bag' }, { label: 'Polyester Thread' }, { label: 'Cotton Fabric' }], handleDelete)}

      <Box>
        <Stack mt={2}>
          <CommonSelect
            label={<span>{t('Method*')}</span>}
            options={transportationMethods}
            value={formData.transportationMethods}
            handleChange={(e) => setFormData({ ...formData, transportationMethods: e.target.value })}
          />

          <StepTabs sx={{ marginTop: '20px', fontSize: '14px' }}>Distance (km)</StepTabs>
          <Input id="input-with-icon-textfield" placeholder="120" sx={{ width: '100%' }} variant="standard" />

          {CommonInfoWrapper(`Please indicate the average distance (km) for transport of the garment from the production site to the point of distribution In case the transport is made by several modes of trnasport,
              please insert the km per each of the modes used (they will be summed up to account for the total transport of 1 piece of garment)`)}

          <CommonSelect label={<span>{t('Location')}</span>} options={location} value={formData.location} handleChange={(e) => setFormData({ ...formData, location: e.target.value })} />

          {CommonInfoWrapper(`Please indicate the arrival location (intermediate if more than 1 transportation mode)`)}
        </Stack>
      </Box>
    </Box>
  );
};

export default LCAStep;
