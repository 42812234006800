import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Checkbox, Grid, IconButton, InputAdornment, Pagination, Snackbar, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import Loader from '../utils/loader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, fetchSearchProducts } from '../../features/productSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterBrandProduct from '../utils/FilterBrandProduct';
import { DisabledByDefault } from '@mui/icons-material';
import AlertDialog from '../utils/AlertDialog';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

export default function SelectClaimProduct({ claimProduct, setClaimProduct, claimId }) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const { products, productLoading } = useSelector((state) => state.productEngine);
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(products?.totalRecords / itemsPerPage);
  const currentPageData = products?.products?.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const claimProductIds = claimProduct?.map((claim) => claim?.productId);
    const allProductsIncluded = products.products?.every((product) => claimProductIds?.includes(product?.id));
    if (allProductsIncluded) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [products, claimProduct]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    if (!checkOnlineStatus()) return;
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      dispatch(fetchProducts({ page, itemsPerPage }));
    } else {
      dispatch(fetchSearchProducts({ term }));
    }
  };

  const selectProduct = (e, val) => {
    if (!checkOnlineStatus()) return;
    if (e.target.checked) {
      setClaimProduct((prevData = []) => [...prevData, { productId: val?.id, name: val?.title?.value }]);
    } else {
      setClaimProduct((prevData) => prevData.filter((product) => product.productId !== val?.id));
    }
  };

  const handleSelectAll = () => {
    if (!checkOnlineStatus()) return;
    if (selectAll) {
      setClaimProduct([]);
    } else {
      const allProducts = products.products?.map((product) => ({
        productId: product.id,
        name: product.title?.value,
      }));
      setClaimProduct(allProducts);
    }
    setSelectAll(!selectAll);
  };

  const handleRemoveSelectedProduct = () => {
    if (!checkOnlineStatus()) return;
    setClaimProduct([]);
    setOpenAlertChainDialog(false);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FilterBrandProduct page={page} itemsPerPage={itemsPerPage} claimProduct={claimProduct} claimId={claimId} showSelected={true} />
            </Box>
            <Pagination
              sx={{
                '& .MuiPaginationItem-root': {
                  color: 'gray',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#EC895A',
                  color: '#fff',
                },
              }}
              count={pageCount}
              page={page}
              onChange={handleChange}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="50%" mt={1} gap={2}>
            <Button
              onClick={handleSelectAll}
              sx={{
                bgcolor: '#101010',
                color: '#fff',
                ':hover': { bgcolor: '#0a0a0a' },
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {selectAll ? t('deselect_all') : t('select_all')}
            </Button>
            <Button sx={{
                bgcolor: 'gray',
                ':hover': { bgcolor: 'gray' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
                cursor: 'none',
              }}>
              {t('selected')}: {claimProduct?.length || 0}
            </Button>
            <Button
              disabled={claimProduct?.length <= 0 || selectAll}
              onClick={() => setOpenAlertChainDialog(true)}
              sx={{
                bgcolor: claimProduct?.length <= 0 || selectAll ? 'gray' : '#000',
                ':hover': { bgcolor: '#101010' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {t('Unselect_All')}
            </Button>
          </Box>
          {productLoading ? (
            <Loader />
          ) : (
            <Grid mt={2} container spacing={4}>
              {currentPageData?.map((val, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid container p={1} borderRadius={2} sx={{ boxShadow: '0px 1px 0px 1px #EFEFEF' }}>
                    <Grid item xs={3}>
                      <img src={val?.urls[0]} alt="" height={'40px'} width={'40px'} style={{ borderRadius: '10px' }} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{ color: '#000', fontSize: '14px', fontWeight: 'bold', display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {val?.title?.value}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'gray',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {val?.qrId}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={'center'}>
                      <Checkbox
                        {...label}
                        icon={<CheckCircleIcon color="gray" />}
                        checkedIcon={<CancelIcon color="error" />}
                        checked={claimProduct?.some((product) => product.productId === val?.id)}
                        onChange={(e) => selectProduct(e, val)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
      <AlertDialog
        btnName={t('yes')}
        title={t('remove_all_the_products_connected_to_this_claim')}
        description={t('are_you_sure_you_want_to_remove_all_products')}
        open={openAlertChainDialog}
        handleClick={handleRemoveSelectedProduct}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
        }}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
