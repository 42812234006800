export const location = [
  'Albania',
  'Argentina',
  'Austria',
  'Australia',
  'Bosnia and Herzegovina',
  'Bangladesh',
  'Belgium',
  'Bulgaria',
  'Brazil',
  'Canada',
  'China',
  'Colombia',
  'Cambodia',
  'Czech Republic',
  'Denmark',
  'Dominican Republic',
  'Estonia',
  'Egypt',
  'Europe',
  'El Salvador',
  'Finland',
  'Greece',
  'Guatemala',
  'Germany',
  'Hong Kong',
  'Indonesia',
  'Ireland',
  'Israel',
  'India',
  'Iran',
  'Italy',
  'Japan',
  'Korea, Republic of',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Morocco',
  'Moldova, Republic of',
  'Malta',
  'Mexico',
  'Malaysia',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Nepal',
  'New Zealand',
  'Peru',
  'Philippines',
  'Pakistan',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'Serbia',
  'Saudi Arabia',
  'Sweden',
  'Singapore',
  'Slovenia',
  'Slovakia',
  'Sri Lanka',
  'Switzerland',
  'South Africa',
  'Thailand',
  'Tunisia',
  'Turkey',
  'United Arab Emirates',
  'United States of America',
  'Uzbekistan',
  'Viet Nam',
  'World',
];

export const weightUnit = ['gm', 'kg', 'oz'];

export const transportationType = ['Transportation by Road', 'Transportation by Sea', 'Transportation by Air', 'Transportation by Train'];

export const packaginType = ['Plastic', 'Paper', 'Board', 'Recycled Plastic', 'Recycled Paper', 'Bioplastic'];

export const tempChipData = [
  { label: 'Box', selected: true },
  { label: 'Plastic Bag', selected: false },
  { label: 'Polyester Thread', selected: false },
  { label: 'Cotton Fabric', selected: false },
];

export const fabricDyeingMethod = ['Light', 'Medium', 'Dark'];

export const fabricMethod = ['knitting', 'Weaving', 'Non woven production'];

export const yarnMenufacturingMethod = [
  'Spinning of recycled nylon (Econyl)',
  'Spinning of wool',
  'Spinning of jute',
  'Spinning of kenaf',
  'Spinning of linen',
  'Spinning of silk, long fibers',
  'Spinning of silk, short fibers',
  'Melt spinning of Nylon 6',
  'Melt spinning of Nylon 6-6',
  'Melt spinning of Polyester',
  'Dry spinning',
  'Wet spinning, viscosa process',
  'Wet spinning, acrylic fibers',
  'Dry jet-Wet spinning (Lyocell process)',
  'Filament yarn production',
  'Spinning of cotton',
  'Spinning of hemp',
];

export const materialFamilyData = ['Virgin fibers', 'Recycled fibers', 'Other materials'];

export const materialTypeData = [
  'Cotton (BD)',
  'Recycled polyester',
  'Chrome-tanned bovine leather',
  'Organic cotton (BD)',
  'Recycled Nylon',
  'Vegetable-tanned bovine leather',
  'Cotton (IN)',
  'Recycled wool',
  'PU leather',
  'Organic cotton (IN)',
  'Recycled cashmere',
  'PVC leather',
  'Cotton (GLO)',
  'Piñatex ®',
  'Organic cotton {GLO}',
  'Desserto®',
  'Jute (BD)',
  'Aluminium',
  'Jute (IN)',
  'Recycled Aluminium',
  'Jute (GLO)',
  'Recycled Gold',
  'Kenaf (IN)',
  'Silver',
  'Kenaf (GLO)',
  'Recycled Silver',
  'Linen (IN)',
  'Natural Rubber',
  'Linen (GLO)',
  'Hemp',
  'Silk (IN)',
  'Silk (GLO)',
  'Polypropylene',
  'Polyester',
  'Polyamide 6 ',
  'Polyamide 6-6',
  'PLA',
  'Viscose',
  'Elastane',
  'Wool, fleece',
  'Wool, belly wool',
  'Cashmere',
  'Acrylic',
  'Lyocell',
];

export const elements_card = [
  {
    indicator: 'Impact',
    kg_number: '17,831',
    unit: 'kg',
    title: 'CO2 eq',
  },
  {
    indicator: 'Impact',
    kg_number: '235,529',
    unit: 'MJ',
    title: '',
  },

  {
    indicator: 'Inventory',
    kg_number: '0,153',
    unit: 'liters',
    title: '',
  },
  {
    indicator: 'Inventory',
    kg_number: '0,806',
    unit: 'm2yr',
    title: 'crop eq',
  },
];

export const product_result_data = [
  {
    title: 'Acidification',
    kg_number: '0,061',
    unit: 'mol H+ eq',
    indicator: 'Impact',
  },
  {
    title: 'Climate change',
    kg_number: '17,976',
    unit: 'kg CO2 eq',
    indicator: 'Impact',
  },
  {
    title: 'Climate change - Biogenic',
    kg_number: '0,009',
    unit: 'kg CO2 eq',
    indicator: 'Impact',
  },
  {
    title: 'Climate change - Fossil',
    kg_number: '17,831',
    unit: 'kg CO2 eq',
    indicator: 'Impact',
  },
  {
    title: 'Climate change - Land use and LU change',
    kg_number: '0,136',
    unit: 'kg CO2 eq',
    indicator: 'Impact',
  },
  {
    title: 'Ecotoxicity, freshwater - inorganics',
    kg_number: '110,967',
    unit: 'CTUe',
    indicator: 'Impact',
  },
  {
    title: 'Eutrophication, freshwater',
    kg_number: '0,001',
    unit: 'kg P eq',
    indicator: 'Impact',
  },
  {
    title: 'Eutrophication, marine',
    kg_number: '0,037',
    unit: 'kg N eq',
    indicator: 'Impact',
  },
  {
    title: 'Eutrophication, terrestrial',
    kg_number: '0,344',
    unit: 'mol N eq',
    indicator: 'Impact',
  },
  {
    title: 'Human toxicity, cancer',
    kg_number: '0,000000003',
    unit: 'CTUh',
    indicator: 'Impact',
  },
  {
    title: 'Ionising radiation',
    kg_number: '0,374',
    unit: 'kBq U-235 eq',
    indicator: 'Impact',
  },
  {
    title: 'Land use',
    kg_number: '52,710',
    unit: 'Pt',
    indicator: 'Impact',
  },
  {
    title: 'Land use',
    kg_number: '0,806',
    unit: 'm2yr crop eq',
    indicator: 'Inventory',
  },
  {
    title: 'Ozone depletion',
    kg_number: '0,000003',
    unit: 'kg CFC11 eq',
    indicator: 'Impact',
  },
  {
    title: 'Particulate matter',
    kg_number: '0,0000002',
    unit: 'p inc.',
    indicator: 'Impact',
  },
  {
    title: 'Photochemical ozone formation',
    kg_number: '0,098',
    unit: 'kg NMVOC eq',
    indicator: 'Impact',
  },
  {
    title: 'Resource use, fossils',
    kg_number: '235,529',
    unit: 'MJ',
    indicator: 'Impact',
  },
  {
    title: 'Water',
    kg_number: '0,153',
    unit: 'm3',
    indicator: 'Inventory',
  },
  {
    title: 'Water use',
    kg_number: '6,641',
    unit: 'm3',
    indicator: 'Impact',
  },
];

export const locationRenewableElectricity_option = ['Europe', 'Non-European countries'];

export const lca_indicators_data = [
  {
    type: 'Water Used',
    unit: ['m3', 'lt'],
    indicators: 'Impact',
  },
  {
    type: 'Water Saved',
    unit: ['m3', 'lt'],
    indicators: 'Impact',
  },
  {
    type: 'Water Eq. Used',
    unit: 'Days',
    indicators: 'Impact',
  },
  {
    type: 'Water Eq. Saved',
    unit: 'Days',
    indicators: 'Impact',
  },
  {
    type: 'Climate change Used',
    unit: 'kg CO2 eq',
    indicators: 'Impact',
  },
  {
    type: 'Climate change Saved',
    unit: 'kg CO2 eq',
    indicators: 'Impact',
  },
  {
    type: 'Climate change Eq. Used',
    unit: 'Km',
    indicators: 'Impact',
  },
  {
    type: 'Climate change Eq. Saved',
    unit: 'Km',
    indicators: 'Impact',
  },
  {
    type: 'Fossil fuel Used',
    unit: 'MJ',
    indicators: 'Impact',
  },
  {
    type: 'Fossil fuel Saved',
    unit: 'MJ',
    indicators: 'Impact',
  },
  {
    type: 'Fossil fuel Eq. Used',
    unit: 'Hours',
    indicators: 'Impact',
  },
  {
    type: 'Fossil fuel Eq. Saved',
    unit: 'Hours',
    indicators: 'Impact',
  },
  {
    type: 'Mineral & metals Used',
    unit: 'kg Sb eq',
    indicators: 'Impact',
  },
  {
    type: 'Mineral & metals Saved',
    unit: 'kg Sb eq',
    indicators: 'Impact',
  },
  {
    type: 'Electricity Used',
    unit: 'KWh',
    indicators: 'Impact',
  },
  {
    type: 'Electricity Saved',
    unit: 'KWh',
    indicators: 'Impact',
  },
  {
    type: 'Electricity Eq. Used',
    unit: 'Hours',
    indicators: 'Impact',
  },
  {
    type: 'Electricity Eq. Saved',
    unit: 'Hours',
    indicators: 'Impact',
  },
  {
    type: 'Land Used',
    unit: 'm2',
    indicators: 'Impact',
  },
  {
    type: 'Land Saved',
    unit: 'm2',
    indicators: 'Impact',
  },
  {
    type: 'Land Eq. Used',
    unit: 'Ping pong tables',
    indicators: 'Impact',
  },
  {
    type: 'Land Eq. Saved',
    unit: 'Ping pong tables',
    indicators: 'Impact',
  },
  {
    type: 'HumanToxicity Used',
    unit: ['KGs of Db', 'CTUh'],
    indicators: 'Impact',
  },
  {
    type: 'HumanToxicity Saved',
    unit: ['KGs of Db', 'CTUh'],
    indicators: 'Impact',
  },
  {
    type: 'Eutrophication Used',
    unit: ' kgs di PO4 eq',
    indicators: 'Impact',
  },
  {
    type: 'Eutrophication Saved',
    unit: 'kgs di PO4 eq',
    indicators: 'Impact',
  },
  {
    type: 'Chemical Used',
    unit: 'Toxic units',
    indicators: 'Impact',
  },
  {
    type: 'Chemical Saved',
    unit: 'Toxic units',
    indicators: 'Impact',
  },
  {
    type: 'Water Used',
    unit: ['m3', 'lt'],
    indicators: 'Inventory',
  },
  {
    type: 'Water Saved',
    unit: ['m3', 'lt'],
    indicators: 'Inventory',
  },
  {
    type: 'Water Eq. Used,',
    unit: 'Days',
    indicators: 'Inventory',
  },
  {
    type: 'Water Eq. Saved',
    unit: 'Days',
    indicators: 'Inventory',
  },
  {
    type: 'Land Used',
    unit: 'm3',
    indicators: 'Inventory',
  },
  {
    type: 'Land Saved',
    unit: 'm3',
    indicators: 'Inventory',
  },
  {
    type: 'Land Eq. Used',
    unit: 'Ping pong tables',
    indicators: 'Inventory',
  },
  {
    type: 'Land Eq. Saved',
    unit: 'Ping pong tables',
    indicators: 'Inventory',
  },
];

export const lca_steps = ['Garment', 'Similar Products', 'Distribution', 'Packaging', 'Production', 'Dyeing', 'Fabric', 'Yarn', 'Raw Materials'];

export const five_digit_validation_regex = /^\d{1,5}(\.\d{0,5})?$/;

export const lca_weight_field_validation_regex = /^\d{1,12}(\.\d{0,5})?$/
