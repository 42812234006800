import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { fetchSupplyChainDataFilter } from '../../features/chainSlice';

export default function FilterSupplyChain({ currentPage, searchSupplyChain, onChangeFilter, onClearFilter }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState({
    hasProduct: '',
    hasSupplier: '',
    sortBy: '',
  });
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleHasSupplierChange = (event) => {
  //     setHasSupplier(event.target.value);
  //     const hasSuppliers = event.target.value;
  //     dispatch(fetchSupplyChainDataWithSupplier({ hasSuppliers, currentPage }));
  //     setAnchorEl(null);
  //   };

  //   const () => handleFilterChange("hasProduct") = (event) => {
  //     setHasProduct(event.target.value);
  //     let hasProducts = event.target.value;
  //     dispatch(fetchSupplyChainDataWithProduct({ hasProducts, currentPage }));
  //     setAnchorEl(null);
  //   };

  //   const handleSortChange = (event) => {
  //     setSort(event.target.value);
  //     let sort = event.target.value;
  //     dispatch(fetchSupplyChainDataWithSort({ sort, currentPage }));() => handleFilterChange("hasProduct")     setAnchorEl(null);
  //   };

  const handleFilterChange = (filterName, event) => {
    const newFilterValue = {
      ...filterValue,
      [filterName === 'hasSuppliers' ? 'hasSupplier' : filterName]: event.target.value,
    };
    setFilterValue(newFilterValue);
    dispatch(
      fetchSupplyChainDataFilter({
        filterValue: { ...newFilterValue, term: searchSupplyChain },
        currentPage,
      })
    );
    onChangeFilter(newFilterValue);
    setAnchorEl(null);
  };

  const handleClearAll = () => {
    let obj = {
      hasProduct: '',
      hasSupplier: '',
      sort: '',
    };
    setFilterValue(obj);
    onClearFilter();
    dispatch(
      fetchSupplyChainDataFilter({
        filterValue: { ...obj, term: searchSupplyChain },
        currentPage,
      })
    );
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>
          <InputLabel sx={{ fontSize: '14px' }}>{t('has_products')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValue.hasProduct} onChange={(e) => handleFilterChange('hasProduct', e)}>
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel sx={{ fontSize: '14px' }}>{t('has_suppliers')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValue.hasSupplier} onChange={(e) => handleFilterChange('hasSuppliers', e)}>
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: '12px' }}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            {t('sort_by')} <SortIcon />
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValue.sort} onChange={(e) => handleFilterChange('sortBy', e)}>
              {[t('Name'), t('Date')].map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
