import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { Input, InputAdornment, Chip } from '@mui/material';
import CommonSelect from '../../utils/CommonSelect';
import AssesmentFooter from '../../utils/AssesmentFooter';
import { five_digit_validation_regex, location, transportationType } from '../../../data';
import CommonInput from '../../utils/CommonInput';
import { CommonInfoWrapper } from './AssessmentStep4';
import InfoIcon from '@mui/icons-material/Info';

// Create MUI styled components
export const StyledButton = styled(Button)(({ isBgColor }) => ({
  backgroundColor: isBgColor,
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: isBgColor },
}));

const AssessmentStep3 = ({ handleSubmit, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [chipsData, setChipsData] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [newChipName, setNewChipName] = useState('');

  useEffect(() => {
    if (data && data?.transport?.length > 0) {
      const lastChipIndex = data.transport.length - 1; 
      const updatedChips = data?.transport?.map((item, index) => ({
        [item.name]: {
          method: item.method || '',
          distance: item.distance || '',
          location: item.location || '',
          selected: index === lastChipIndex, 
        },
      }));
      setChipsData(updatedChips);
      setSelectedChipIndex(lastChipIndex); 
    }
  }, [data]);

  const onNext = (isDraft = false) => {
    if (getCompletedChips().length > 0) {
      handleSubmit({ transport: getCompletedChips() }, isDraft);
      setSelectedChipIndex(null);
    } else {
      setSnackBar({ open: true, message: 'Please select atleast one transport name' });
    }
  };

  const handleClick = (index) => {
    const selectedChip = chipsData[index];
    if (selectedChip) {
      const updatedChips = chipsData.map((chip, i) => {
        const chipLabel = Object.keys(chip)[0];
        return {
          [chipLabel]: { ...chip[chipLabel], selected: i === index },
        };
      });

      setChipsData(updatedChips);
      setSelectedChipIndex(index);
    }
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const onAddChip = () => {
    if (!newChipName.trim()) {
      setSnackBar({ open: true, message: 'Transport name cannot be empty.' });
      return;
    }
    if (chipsData.some((chip) => chip[newChipName.trim()])) {
      setSnackBar({ open: true, message: 'Same transport name already exists.' });
      return;
    }
    if (chipsData.length >= 4) {
      setSnackBar({ open: true, message: 'You can only add up to four transport names.' });
      return;
    }
    const newChip = {
      [newChipName.trim()]: {
        method: '',
        distance: '',
        location: '',
        selected: true,
      },
    };

    setChipsData((prevChips) => [
      ...prevChips.map((chip) => {
        const chipLabel = Object.keys(chip)[0];
        return { [chipLabel]: { ...chip[chipLabel], selected: false } };
      }),
      newChip,
    ]);

    setNewChipName('');
    setSelectedChipIndex(chipsData.length);
  };

  const handleDelete = (index) => {
    const updatedChips = chipsData.filter((_, i) => i !== index);
    let newSelectedChipIndex = null;

    if (updatedChips.length > 0) {
      if (index === chipsData.length - 1) {
        newSelectedChipIndex = updatedChips.length - 1;
      } else {
        newSelectedChipIndex = index;
      }
      const chipLabel = Object.keys(updatedChips[newSelectedChipIndex])[0];
      updatedChips[newSelectedChipIndex] = {
        [chipLabel]: { ...updatedChips[newSelectedChipIndex][chipLabel], selected: true },
      };
    }

    setChipsData(updatedChips);
    setSelectedChipIndex(newSelectedChipIndex);
  };

  const isChipCompleted = (chipIndex, label) => {
    let status;
    chipsData.forEach((item, index) => {
      if (index === chipIndex) {
        const { method, location, distance } = item[label];
        if (method && location && distance) {
          status = true;
        } else status = false;
      }
    });
    return status;
  };

  const getFieldValues = () => {
    if (selectedChipIndex !== null && chipsData[selectedChipIndex]) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      return selectedChipObj[chipLabel];
    }
    return {};
  };

  const getCompletedChips = () => {
    const result = chipsData
      .filter((item, index) => {
        const chipLabel = Object.keys(item)[0];
        return isChipCompleted(index, chipLabel);
      })
      .map((item) => {
        const chipLabel = Object.keys(item)[0];
        const { selected, ...chipData } = item[chipLabel];
        return {
          name: chipLabel,
          ...chipData,
        };
      });
    return result;
  };

  const updateFieldValues = (fieldName, newValue) => {
    if (selectedChipIndex != null) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      setChipsData((prevData) => {
        const updatedData = [...prevData];

        if (updatedData[selectedChipIndex] && updatedData[selectedChipIndex][chipLabel]) {
          updatedData[selectedChipIndex] = {
            ...updatedData[selectedChipIndex],
            [chipLabel]: {
              ...updatedData[selectedChipIndex][chipLabel],
              [fieldName]: newValue,
            },
          };
        } else {
          console.log(`Invalid selectedChipIndex, chipLabel, or field name: selectedChipIndex ${selectedChipIndex}, chipLabel ${chipLabel}, Field ${fieldName}`);
        }

        return updatedData;
      });
    } else {
      setSnackBar({ open: true, message: 'Please add atleast one transport name' });
    }
  };

  const isDisabled = useMemo(() => {
    const completedChips = getCompletedChips();
    const areAllChipsComplete = completedChips.length === chipsData.length;
    const currentFieldValues = getFieldValues();
    const areCurrentFieldsValid = currentFieldValues?.method && currentFieldValues?.distance && currentFieldValues?.location;
    return !areAllChipsComplete || !areCurrentFieldsValid;
  }, [getCompletedChips, chipsData.length, getFieldValues]);

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <Box my={3}>
            <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
              {t('distribution')}
            </Typography>

            <Box mt={3}>
              <Typography sx={{ cursor: 'pointer' }} fontWeight="700" pb={2}>
                {t('transport')} <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Input
                id="input-with-icon-textfield"
                placeholder="Enter Transport Name"
                sx={{ width: '100%', paddingBottom: 2 }}
                variant="standard"
                value={newChipName}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = /^[a-zA-Z\s]*$/.test(value);
                  if (isValid && value.length <= 250) {
                    setNewChipName(value);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <StyledButton variant="contained" isBgColor="#0E1090" onClick={onAddChip}>
                      {t('ADD')}
                    </StyledButton>
                  </InputAdornment>
                }
              />
            </Box>

            {chipsData.length > 0 && (
              <Box mt={2}>
                {chipsData.map((chip, index) => {
                  const label = Object.keys(chip)[0];
                  return (
                    <Chip
                      key={index}
                      label={label}
                      onDelete={() => handleDelete(index)}
                      onClick={() => handleClick(index)}
                      color={chip[label].selected ? 'primary' : 'default'}
                      sx={{ marginRight: 1, marginBottom: 1, cursor: 'pointer' }}
                    />
                  );
                })}
              </Box>
            )}

            <Box>
              <Stack>
                <CommonSelect
                  label={
                    <span style={{ cursor: 'pointer' }}>
                      {t('Method')} <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  options={transportationType}
                  value={getFieldValues()?.method || ''}
                  handleChange={(e) => updateFieldValues('method', e.target.value)}
                />

                <CommonInput
                  label={
                    <span>
                      {t('distance')}
                      <span style={{ color: 'red' }}>*</span>
                      <Tooltip
                        title={
                          'Please indicate the average distance (km) for transport of the garment from the production site to the point of distribution. In case the transport is made by several modes of transport, please insert the km per each of the modes used (they will be summed up to account for the total transport of 1 piece of garment)'
                        }
                        placement="top-start"
                      >
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  value={getFieldValues()?.distance || ''}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (value !== '0' && five_digit_validation_regex.test(value))) {
                      updateFieldValues('distance', value);
                    }
                  }}
                  type="text"
                  isSpacing
                  variant="standard"
                  autoComplete={false}
                />

                <CommonSelect
                  label={
                    <span style={{ cursor: 'pointer' }}>
                      {t('Location')} <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'Please indicate the arrival location (intermediate if more than 1 transportation mode)'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  options={location}
                  value={getFieldValues()?.location || ''}
                  handleChange={(e) => updateFieldValues('location', e.target.value)}
                />
              </Stack>
            </Box>
          </Box>
        </Card>
      </Box>

      <AssesmentFooter isNextBtnDisabled={isDisabled} isDraftDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentStep3;
