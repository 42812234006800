import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledButton } from '../life_cycle_assessment/start_assessment/AssessmentStep3';
import { t } from 'i18next';

const AssesmentFooter = ({ onNext, Typography1, Typography2, isDraftDisabled, isNextBtnDisabled }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box mt={3}>
      <Card sx={{ px: isMobile ? 3 : 5, py: 4, borderRadius: '20px' }}>
        <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent="space-between" spacing={2}>
          <Box>
            <Typography fontWeight="700">{Typography1}</Typography>
            <Typography color="#808080" fontSize={12} mt={1}>
              {Typography2}
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <StyledButton disabled={isDraftDisabled} onClick={() => onNext(true)} variant="contained" isBgColor="#101010">
              Save as Draft
            </StyledButton>
            <StyledButton disabled={isNextBtnDisabled} onClick={() => onNext()} variant="contained" isBgColor="#0E1090">
              {t('next')}
              <NavigateNextIcon />
            </StyledButton>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default AssesmentFooter;
