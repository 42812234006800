import { Autocomplete, Box, Button, Card, CardContent, Divider, InputLabel, Snackbar, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import CommonInput from '../utils/CommonInput';
import LanguageSelector from '../utils/LanguageSelector';
import { useEffect, useRef, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { CountryList } from '../utils/CommonList';
import CloseIcon from '@mui/icons-material/Close';
import CommonOption from '../utils/commonOption';
import axios from 'axios';
import { fetchSupplier } from '../../features/supplierSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PATH from '../utils/path';
import { decryptId } from '../utils/encryptDecrypt';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

const AddSupplierForm = () => {
  const { t } = useTranslation();
  const itemsPerPage = 11;
  const navigate = useNavigate();
  const [lang, setLang] = useState('en');
  const fileInputRef = useRef(null);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [errors, setErrors] = useState({ name: '', contactEmail: '', country: '' });
  const [imageUrl, setImageUrl] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();
  let page = params?.page;
  const supplierId = decryptId(params?.supplierId);
  const [supplierFormData, setSupplierFormData] = useState({
    name: '',
    vanityName: '',
    webSiteURL: '',
    vat: '',
    state: '',
    country: '',
    city: '',
    street: '',
    description: '',
    images: [],
    contactEmail: '',
    contactName: '',
    employee: '',
    factories: '',
  });
  const [imageError, setImageError] = useState('');

  useEffect(() => {
    dispatch(fetchSupplier({ page, itemsPerPage }));
  }, [dispatch, page]);

  useEffect(() => {
    const fetchInitialProductData = async () => {
      try {
        const response = await httpService.get(`${RestUrlsConstants.supplier}/${supplierId}`, {
          headers: { Authorization: localStorage.getItem('Authorization') },
        });

        if (response?.data?.status) {
          const data = response?.data?.data;

          setImageUrl(data?.urls || []);

          setSupplierFormData((prevData) => ({
            ...prevData,
            name: data?.name || '',
            webSiteURL: data?.websiteUrl || '',
            vat: data?.vat || '',
            state: data?.address?.state || '',
            country: data?.address?.country || '',
            city: data?.address?.city || '',
            street: data?.address?.street || '',
            contactEmail: data?.contact?.email || '',
            contactName: data?.contact?.name || '',
            employee: data?.numberOfEmployees || '',
            factories: data?.numberOfFactories || '',
            vanityName: data?.vanityName?.[lang] || data?.vanityName?.value || '',
            description: data?.description?.[lang] || data?.description?.value || '',
          }));
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (supplierId && (lang || lang === undefined)) {
      fetchInitialProductData();
    }
  }, [supplierId, lang]);

  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    const allFiles = [...newFiles];
    const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    let validFiles = [];
    let invalidFiles = [];

    Array.from(allFiles).forEach((file) => {
      if (supportedFormats.includes(file.type)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });
    if (invalidFiles.length > 0) {
      setImageError('Some files are not supported. Please upload jpg, png, or jpeg files only.');
    } else {
      setImageError('');
    }
    if (validFiles.length > 0) {
      setSupplierFormData((prevFormData) => ({
        ...prevFormData,
        images: allFiles,
      }));
      const imageUrlUpdate = newFiles?.map((file) => URL.createObjectURL(file));
      if (params?.supplierId) {
        setImageUrl([...imageUrl, ...imageUrlUpdate]);
      } else {
        // setImageUrl([...imageUrlUpdate]);
        setImageUrl((prevImageUrl) => [...prevImageUrl, ...imageUrlUpdate]);
      }
    }
  };

  const removeImage = (index, isUploaded, id) => {
    if (isUploaded) {
      handleRemoveImage(index, id);
    } else {
      const updatedFiles = imageUrl.filter((_, i) => i !== index);
      setImageUrl(updatedFiles);
    }
  };
  const handleRemoveImage = async (index, id) => {
    if (!checkOnlineStatus()) return;
    try {
      const payload = {
        image: id,
      };
      const response = await axios.delete(`${RestUrlsConstants.supplier}/${decryptId(params?.supplierId)}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        const updatedFiles = imageUrl.filter((_, i) => i !== index);
        setImageUrl(updatedFiles);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', country: '', contactEmail: '' };
    if (!supplierFormData?.name?.trim()) {
      newErrors.name = t('name_is_required');
      valid = false;
    }

    if (!supplierFormData?.country?.trim()) {
      newErrors.country = t('country_is_required');
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const createSupplier = async () => {
    if (!checkOnlineStatus()) return;
    if (!validateForm()) return;
    if (!supplierId) {
      try {
        const formData = new FormData();
        formData.append('name', supplierFormData.name);
        if (supplierFormData.vanityName) formData.append('vanityName', supplierFormData.vanityName);
        if (supplierFormData.webSiteURL) formData.append('websiteUrl', supplierFormData.webSiteURL);
        if (supplierFormData.vat) formData.append('vat', supplierFormData.vat);
        if (supplierFormData.factories) formData.append('numberOfFactories', supplierFormData.factories);
        if (supplierFormData.employee) formData.append('numberOfEmployees', supplierFormData.employee);
        if (supplierFormData.description) formData.append('description', supplierFormData.description);
        formData.append('lang', lang);
        if (supplierFormData.contactName) formData.append('contact[name]', supplierFormData.contactName);
        if (supplierFormData.contactEmail) formData.append('contact[email]', supplierFormData.contactEmail);
        if (supplierFormData.state) formData.append('address[state]', supplierFormData.state);
        if (supplierFormData.street) formData.append('address[street]', supplierFormData.street);
        formData.append('address[country]', supplierFormData.country);
        if (supplierFormData.city) formData.append('address[city]', supplierFormData.city);
        if (supplierFormData?.images?.length) {
          for (let i = 0; i < supplierFormData?.images?.length; i++) {
            formData.append('images', supplierFormData.images[i]);
          }
        }
        const response = await httpService.post(`${RestUrlsConstants.supplier}`, formData, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data?.status) {
          setSnackBar({ open: true, message: response?.data?.message });
          setSupplierFormData({
            name: '',
            vanityName: '',
            webSiteURL: '',
            vat: '',
            state: '',
            country: '',
            city: '',
            street: '',
            description: '',
            images: [],
            contactEmail: '',
            contactName: '',
            employee: 0,
            factories: 0,
          });
          dispatch(fetchSupplier({ page, itemsPerPage }));
          navigate(`${PATH.SUPPLYCHAIN}/1/1?from=addSupp`);
        }
      } catch (error) {
        console.log(error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('name', supplierFormData.name);
        formData.append('vanityName', supplierFormData.vanityName);
        formData.append('websiteUrl', supplierFormData.webSiteURL);
        formData.append('vat', supplierFormData.vat);
        if (supplierFormData.factories) {
          formData.append('numberOfFactories', supplierFormData.factories);
        }
        if (supplierFormData.employee) {
          formData.append('numberOfEmployees', supplierFormData.employee);
        }
        formData.append('description', supplierFormData.description);
        formData.append('lang', lang);
        formData.append('contact[name]', supplierFormData.contactName);
        formData.append('contact[email]', supplierFormData.contactEmail);
        formData.append('address[state]', supplierFormData.state);
        formData.append('address[street]', supplierFormData.street);
        formData.append('address[country]', supplierFormData.country);
        formData.append('address[city]', supplierFormData.city);
        if (supplierFormData?.images?.length) {
          for (let i = 0; i < supplierFormData?.images?.length; i++) {
            formData.append('images', supplierFormData.images[i]);
          }
        }
        const response = await httpService.patch(`${RestUrlsConstants.supplier}/${decryptId(params?.supplierId)}`, formData, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data?.status) {
          setSnackBar({ open: true, message: response?.data?.message });
          setSupplierFormData({
            name: '',
            vanityName: '',
            webSiteURL: '',
            vat: '',
            state: '',
            country: '',
            city: '',
            street: '',
            description: '',
            images: [],
            contactEmail: '',
            contactName: '',
            employee: 0,
            factories: 0,
          });
          dispatch(fetchSupplier({ page, itemsPerPage }));
          navigate(`${PATH.SUPPLYCHAIN}/${params?.page}/${params?.chainPage}?from=addSupp`);
        }
      } catch (error) {
        console.log(error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    }
  };

  const handleAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const handleCloseForm = () => {
    navigate(`${PATH.SUPPLYCHAIN}/${page}/${params?.chainPage}?from=back`);
  };
  const options = [t('Less_than_9'), t('ten_to_49'), t('fifty_to_249'), t('More_than_250')];
  return (
    <>
      <Button
        startIcon={<IoMdArrowBack />}
        onClick={handleCloseForm}
        sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2, mb: 2 }}
      >
        {t('back')}
      </Button>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ color: '#0e1090', fontWeight: 'bold' }}>
              {!supplierId ? t('add_supplier') : t('edit_supplier')}
            </Typography>
            <LanguageSelector selectLang={lang} setLang={setLang} />
          </Box>
          <Divider sx={{ width: '100%', background: 'black', mt: 2 }} />
          <CommonInput
            label={t('name') + '*'}
            type="text"
            value={supplierFormData.name}
            handleChange={(e) => setSupplierFormData({ ...supplierFormData, name: e.target.value })}
            placeholder={t('name')}
            variant={'standard'}
            error={!!errors.name}
            helperText={errors.name}
          />
          <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('vanity_name')}</InputLabel>
          <TextField
            fullWidth
            value={supplierFormData.vanityName}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, vanityName: e.target.value })}
            type="text"
            placeholder={t('vanity_name')}
            variant={'standard'}
          />
          <CommonInput
            label={t('website_url')}
            value={supplierFormData.webSiteURL}
            handleChange={(e) => setSupplierFormData({ ...supplierFormData, webSiteURL: e.target.value })}
            placeholder={t('website_url')}
            variant={'standard'}
          />
          <CommonInput
            label={t('vat_code')}
            value={supplierFormData.vat}
            handleChange={(e) => setSupplierFormData({ ...supplierFormData, vat: e.target.value })}
            type="text"
            placeholder={t('vat')}
            variant={'standard'}
          />
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 4 }}>{t('address')}</InputLabel>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={supplierFormData.state}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, state: e.target.value })}
            type="text"
            placeholder={t('state')}
            variant={'standard'}
          />

          <Autocomplete
            sx={{ mt: 2 }}
            fullWidth
            size="small"
            disablePortal
            id="combo-box-demo"
            options={CountryList}
            value={supplierFormData.country}
            onChange={(event, newValue) => {
              setSupplierFormData({ ...supplierFormData, country: newValue });
            }}
            renderInput={(params) => <TextField {...params} placeholder={t('region_country') + '*'} variant="standard" error={!!errors.country} helperText={errors.country} />}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={supplierFormData.city}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, city: e.target.value })}
            type="text"
            placeholder={t('city')}
            variant={'standard'}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={supplierFormData.street}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, street: e.target.value })}
            type="text"
            placeholder={t('street')}
            variant={'standard'}
          />
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 4 }}>{t('contact')}</InputLabel>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={supplierFormData.contactName}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, contactName: e.target.value })}
            type="text"
            placeholder={t('name')}
            variant={'standard'}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={supplierFormData.contactEmail}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, contactEmail: e.target.value })}
            type="email"
            placeholder={t('email')}
            variant={'standard'}
          />
          <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('description')}</InputLabel>
          <TextField
            fullWidth
            value={supplierFormData.description}
            onChange={(e) => setSupplierFormData({ ...supplierFormData, description: e.target.value })}
            type="text"
            placeholder={t('description')}
            variant={'standard'}
          />
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 4 }}>{t('upload_images')}</InputLabel>

          <input ref={fileInputRef} required multiple accept="image/jpg , image/png , image/jpeg" type="file" hidden onChange={handleFileInput} />
          <Button
            sx={{
              mt: 2,
              height: '150px',
              width: '100%',
              color: 'black',
              boxShadow: 'none',
              border: '2px dashed gray',
              background: `#eeeeee`,
              textTransform: 'none',
              fontWeight: 'bold',
              ':hover': { background: '#eeeeee', boxShadow: 'none' },
            }}
            variant="contained"
            onClick={() => fileInputRef.current.click()}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <CloudUploadIcon sx={{ color: 'gray', fontSize: '40px' }} />
                {t('choose_file')}
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{t('image_type')} </span>
              </Box>
            </Typography>
          </Button>
          {imageError && <p style={{ color: 'red', fontSize: '12px' }}>{imageError}</p>}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
            {imageUrl?.map((url, index) => (
              <Box
                key={index}
                sx={{
                  height: '80px',
                  width: '80px',
                  borderRadius: '8px',
                  mt: 2,
                  position: 'relative',
                }}
              >
                {!url?.url && <img src={url} alt="" height="100px" width="80px" style={{ borderRadius: '8px', border: '1px solid gray' }} />}
                {url?.url && <img src={url?.url} alt="" height="100px" width="80px" style={{ borderRadius: '8px', border: '1px solid gray' }} />}

                <CloseIcon
                  onClick={(e) => {
                    removeImage(index, url?.url?.startsWith('https'), url?.id);
                  }}
                  sx={{
                    cursor: 'pointer',
                    fontSize: '16px',
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    color: 'red',
                    zIndex: 200,
                    background: '#fff',
                    borderRadius: '5px',
                    border: '.5px solid gray',
                  }}
                />
              </Box>
            ))}
          </Box>
          <Typography onClick={() => handleAdditionalInfo()} mt={4} sx={{ color: '#0e1090', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', width: '150px' }}>
            {t('additional_settings')} {showAdditionalInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Typography>
          {showAdditionalInfo && (
            <Box>
              <CommonOption
                label={t('no_of_employee')}
                options={options}
                value={supplierFormData.employee}
                handleChange={(e) => setSupplierFormData({ ...supplierFormData, employee: e.target.value })}
              />
              <CommonOption
                label={t('no_of_factories')}
                options={options}
                value={supplierFormData.factories}
                handleChange={(e) => setSupplierFormData({ ...supplierFormData, factories: e.target.value })}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 'none', mt: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ color: '#0e1090', fontWeight: 'bold' }}>
            {t('add_a_new_supplier_by_submitting_this_details')}
          </Typography>
          <Typography sx={{ color: 'gray', fontSize: '12px' }}>{t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}</Typography>
          <Button onClick={createSupplier} sx={{ bgcolor: '#0e1090', color: '#fff', ':hover': { bgcolor: '#0e1090' }, borderRadius: 6, textTransform: 'capitalize', px: 2, mt: 2 }}>
            {t('submit')}
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};
export default AddSupplierForm;
